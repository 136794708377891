module.exports = /*@ngInject*/ function ($mdDialog, conference) {
  var self = this;

  self.conference = APIToUI(conference);

  self.sections = require('./confMetadata.json').sections;

  function UItoAPI(c) {
    var apiData = { id: c.id, name: c.name, entryPoints: [], notes: c.notes };
    if (c.phoneEntryPoints && c.phoneEntryPoints.length) { // !!! metadata now conatins extra array properties, for x in a does not work
      for (var p = 0; p < c.phoneEntryPoints.length; p++) {
        var phoneEntry = c.phoneEntryPoints[p];
        apiData.entryPoints.push({ entryPointType: 'phone', entryPointUri: phoneEntry.tel, entryPointPin: phoneEntry.pin });
      }
    }
    if (c.videoEntryPoints && c.videoEntryPoints.length) {
      for (var v = 0; v < c.videoEntryPoints.length; v++) {
        var videoEntry = c.videoEntryPoints[v];
        apiData.entryPoints.push({ entryPointType: 'video', entryPointUri: videoEntry.uri, entryPointPin: videoEntry.pin });
      }
    }
    if (c.sipEntryPoints && c.sipEntryPoints.length) {
      for (var s = 0; s < c.sipEntryPoints.length; s++) {
        var sipEntry = c.sipEntryPoints[s];
        apiData.entryPoints.push({ entryPointType: 'sip', entryPointUri: sipEntry.uri, entryPointPin: sipEntry.pin });
      }
    }
    if (c.moreEntryPoints && c.moreEntryPoints.length) {
      for (var m = 0; m < c.moreEntryPoints.length; m++) {
        var moreEntry = c.moreEntryPoints[m];
        apiData.entryPoints.push({ entryPointType: 'more', entryPointUri: moreEntry.uri });
      }
    }
    return apiData;
  }

  function APIToUI(c) {
    var uiData = { id: c.id, name: c.name, phoneEntryPoints: [], videoEntryPoints: [], sipEntryPoints: [], moreEntryPoints: [], notes: c.notes };
    for (var i in c.entryPoints) {
      var entry = c.entryPoints[i];
      switch ((entry.entryPointType || '').toLowerCase()) {
        case 'phone': {
          uiData.phoneEntryPoints.push({ tel: entry.entryPointUri, pin: entry.entryPointPin });
          break;
        }
        case 'video': {
          uiData.videoEntryPoints.push({ uri: entry.entryPointUri, pin: entry.entryPointPin });
          break;
        }
        case 'sip': {
          uiData.sipEntryPoints.push({ uri: entry.entryPointUri, pin: entry.entryPointPin });
          break;
        }
        case 'more': {
          uiData.moreEntryPoints.push({ uri: entry.entryPointUri });
          break;
        }
      }
    }
    return uiData;
  }

  self.destroy = function() {
    conference.$delete(function() {
      $mdDialog.hide();
    }, function(err) {
      self.formErrors = err.message || "Error deleting conference";
    });
  };

  self.save = function() {
    if (self.conferenceForm.$valid) {
      conference.constructor[self.conference.id ? 'put' : 'save'](UItoAPI(self.conference), function() {
        $mdDialog.hide();
      }, function(err) {
        self.formErrors = err.message || "Error saving conference";
      });
    } else {
      self.conferenceForm.$setDirty(true);
      var errorNames = [];
      angular.forEach(self.conferenceForm.$error, function (type) {
        angular.forEach(type, function (field) {
          field.$touched = true;
          field.$setDirty();
          errorNames.push(field.$name);
        });
      });
      self.formErrors = "Please fill out the required fields: " + errorNames.join(", ") + ".";
    }
  };

  self.cancel = function() {
    $mdDialog.cancel();
  };
};