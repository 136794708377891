require('./conferences');

angular.module('sc.portal.users.settings', [
  'ui.router',
  'sc.portal.users.settings.conferences'
]).config(function ($stateProvider) {
  $stateProvider.state('sc.portal.users.item.settings', {
    abstract: true,
    url: '/settings'
  });
});