angular.module('sc.portal.dashboard', [
  'ui.router'
]).config(function ($stateProvider) {
  $stateProvider.state('sc.portal.dashboard', {
    url: '/dashboard',
    template: require('./dashboard.html'),
    onEnter: function ($portalHead) {
      $portalHead.title = 'Dashboard';
      if (!history.state && typeof (history.replaceState) === "function") {
        history.replaceState({ page: history.length, href: location.origin + '/dashboard' }, "dashboard");
      }
    },
    controller: function (/* $rootScope, $scope */) {
      // this.session =  $rootScope.session;
    },
    controllerAs: 'dashboardCtrl'
  });
});