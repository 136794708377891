// for use with md-virtual-repeat
var VirtualRepeatLoader = function (resource, options) {
  if (!options.filters) {
    throw 'VirtualRepeatLoader require action';
  }
  this.resource = resource;
  this.options = options;
  this.loadedPages = {};
  this.numItems = undefined;
  this.PAGE_SIZE = 30;
  this._fetchNumItems();
  this.calls = [];
  this.loaded = false;
  this.pagesForUpdate = {};
  this.callback = undefined;
};

VirtualRepeatLoader.prototype.getItemAtIndex = function (index) {
  var pageNumber = Math.floor(index / this.PAGE_SIZE);
  var page = this.loadedPages[pageNumber];
  this.visiblePage = pageNumber;
  if (page) {
    return page[index % this.PAGE_SIZE];
  } else if (!this.pagesForUpdate[pageNumber]) {
    this._fetchPage(pageNumber);
    return null;
  }
};
VirtualRepeatLoader.prototype.getLength = function () {
  return typeof (this.numItems) === 'undefined' ? 1 : this.numItems;
};
VirtualRepeatLoader.prototype.getError = function () {
  return this.error;
};
VirtualRepeatLoader.prototype._fetchPage = function (pageNumber) {
  var that = this;
  var existing = that.loadedPages[pageNumber];
  that.pagesForUpdate[pageNumber] = true;
  var query = {
    offset: pageNumber,
    size: that.PAGE_SIZE,
    pageSize: that.PAGE_SIZE,
    page: pageNumber,
    pageIndex: pageNumber
  };
  if (typeof (this.options.filters) == 'function') {
    var filters = this.options.filters();
    for (var f in filters) {
      if (!query[f]) {
        query[f] = filters[f];
      }
    }
  }
  delete that.error;
  that.resource.query(query, function (calls) {
    that.loadedPages[pageNumber] = existing || [];
    for (var i = 0; i < calls.length; i++) {
      that.loadedPages[pageNumber].splice(i, 1, calls[i]);
    }
    if (that.numItems === 1) {
      that.numItems = calls.length;
    }
    delete that.pagesForUpdate[pageNumber];
  }, function () {
    that.error = 'Unable to fetch collection';
  }).$promise.finally(function () {
    if (typeof that.callback === 'function') {
      that.callback();
    }
  });
};
VirtualRepeatLoader.prototype._getVisiblePageNums = function () {
  var pageNums = [];
  if (typeof (this.topIndex) !== 'undefined' && this.options.idKey) {
    var visiblePageNum = Math.floor(this.topIndex / this.PAGE_SIZE);
    pageNums.push(visiblePageNum);
    if (this.PAGE_SIZE - (this.topIndex % this.PAGE_SIZE) < 12) {
      pageNums.push(visiblePageNum + 1);
    }
  }
  return pageNums;
};
VirtualRepeatLoader.prototype.getVisibleItems = function () {
  var i;
  var visibleItems = [];
  var visiblePageNums = this._getVisiblePageNums();
  var visiblePage = this.loadedPages[visiblePageNums[0]];
  if (visiblePage) {
    for (i = this.topIndex; i < visiblePage.length; i++) {
      visibleItems.push(visiblePage[i]);
    }
  }
  if (visiblePageNums.length > 1) {
    visiblePage = this.loadedPages[visiblePageNums[1]];
    if (visiblePage) {
      for (i = 0; i < visiblePage.length; i++) {
        visibleItems.push(visiblePage[i]);
      }
    }
  }
  return visibleItems;
};
VirtualRepeatLoader.prototype.updateVisible = function () {
  var visiblePageNums = this._getVisiblePageNums();
  if (visiblePageNums.length) {
    for (var pageNum in visiblePageNums) {
      if (pageNum) {
        this._fetchPage(pageNum);
      }
    }
    for (var p in this.loadedPages) {
      if (!this.pagesForUpdate[p]) {
        delete this.loadedPages[p];
      }
    }
  } else this.loadedPages = {}; //fallback if topIndex and idKey not provided (but screen flashing)
};
VirtualRepeatLoader.prototype.refresh = function () {
  this.loadedPages = {};
  this.numItems = 1;
  this.calls = [];
  this.loaded = false;
  this._fetchNumItems();
};
VirtualRepeatLoader.prototype._fetchNumItems = function () {
  var that = this;
  var query = {};
  if (typeof (this.options.filters) == 'function') {
    var filters = this.options.filters();
    for (var f in filters) {
      if (!query[f]) {
        query[f] = filters[f];
      }
    }
  }
  delete that.error;
  that.resource.count(query, function (response) {
    that.numItems = parseInt(response.count);
    that.calls = [];
    that.loadedPages = {};
    that.loaded = true;
  }, function () {
    that.error = "Unable to get collection size.";
    that.numItems = 0;
  });
};

VirtualRepeatLoader.prototype.inject = function (item) {
  var itemPos, existingItem;
  for (itemPos = 0; itemPos < 20; itemPos++) { //TODO do not search unloaded pages
    var currItem = this.getItemAtIndex(itemPos);
    if (currItem && currItem.eventId && currItem.eventId === item.eventId) {
      existingItem = currItem;
      break;
    }
  }
  if (typeof (existingItem) !== 'undefined') { //update
    for (var property in item) {
      existingItem[property] = item[property];
    }
  } else { //insert
    if (!this.loadedPages[0]) {
      this.loadedPages[0] = [];
    }
    this.loadedPages[0].splice(0, 0, item);
    this.numItems = (this.numItems || 0) + 1;
  }
};

VirtualRepeatLoader.prototype.removeItem = function (item) {
  var itemPos, existingItem;
  for (itemPos = 0; itemPos < this.numItems; itemPos++) { //TODO do not search unloaded pages
    var currItem = this.getItemAtIndex(itemPos);
    if (currItem && currItem.eventId === item.eventId) {
      existingItem = currItem;
      break;
    }
  }
  if (typeof (existingItem) !== 'undefined') {
    var pageNumber = Math.floor(itemPos / this.PAGE_SIZE);
    this.loadedPages[pageNumber].splice(itemPos % this.PAGE_SIZE, 1);
    this.numItems = this.numItems - 1;
  }
};

module.exports = VirtualRepeatLoader;