module.exports = /*@ngInject*/ function($rootScope, $mdDialog, gUserId, gUserService, VgisAdmin, SystemRoleFactory) {
  var self = this;
  self.gUserId = gUserId;

  if (!$rootScope.scPermissions.has('USER_VIEW', 'SYSTEM', gUserId)) {
    self.forbidden = true;
    self.error = 'Missing permission to list system users';
    return;
  }

  function getUser() {
    gUserService.$get(function (user) {
      self.gUser = user;
    });
  }
  getUser();

  self.rolesDialog = function(ev) {
    $mdDialog.show({
      controller: require('../../users/roles/rolesDlgCtrl'),
      controllerAs: 'rolesDlgCtrl',
      template: require('../../users/roles/rolesDlg.html'),
      parent: angular.element(document.body),
      targetEvent: ev,
      preserveScope: true,
      locals: {
        gUser: self.gUser,
        RoleFactory: SystemRoleFactory
      }
    }).then(getUser, getUser);
  };

  self.identityDialog = function(ev, identity) {
    $mdDialog.show({
      controller: require('./identity/identityDlgCtrl'),
      controllerAs: 'identityDlgCtrl',
      template: require('./identity/identityDlg.html'),
      parent: angular.element(document.body),
      targetEvent: ev,
      preserveScope: true,
      locals: {
        gUser: self.gUser,
        gIdentityId: identity.id,
        gIdentity: identity
      }
    }).then(getUser, getUser);
  };

  self.enableUser = function (ev) {
    var confirm = $mdDialog.confirm()
      .title('Are you sure?')
      .textContent('Enable user ' + self.gUser.firstName + ' ' + self.gUser.lastName)
      .ariaLabel('Change User Status')
      .targetEvent(ev)
      .ok('Yes')
      .cancel('No');
    $mdDialog.show(confirm).then(function () {
      self.gUser.$setEnabled(function () {
        // success/error can be handled
      }, function () {
        // success/error can be handled 
      });
    }, function () {
      console.info('Enable user not confirmed');
    });
  };

  self.onUpdate = function() {
    getUser();
  };

  self.calcDeleteDate = function() {
    if (!self.gUser.deletedDate) {
      return 'N/A';
    }
    var deletedDate = new Date(self.gUser.deletedDate);
    deletedDate.setDate(deletedDate.getDate()+4);
    return deletedDate;
  };

};