require('./integrations');
require('./ucps');
require('./admins');
require('../common/directives/scp');

var UserPermissions = require('../common/utils/permissions');

angular.module('sc.system', [
  'ui.router',
  'sc',
  'sc.services',
  'sc.common.filters',
  'sc.common.sections',
  // 'sc.portal.mocks',
  'sc.system.integrations',
  'sc.system.ucps',
  'sc.system.admins',
  'sc.common.scp'
]).config(function ($stateProvider) {
  $stateProvider.state('sc.system', {
    url: '/system',
    template: require('./system.html'),
    controller: require('./systemCtrl'),
    controllerAs: "systemCtrl",
    onEnter: /*@ngInject*/ function ($portalHead) {
      $portalHead.title = 'Admin Zone';
    },
    resolve: {
      portalSystem: /*@ngInject*/ function($scSystem) {
        $scSystem.portalUrl = '/portal/v1'; //= $scSystem.baseUrl.replace('/extensions/', '/portal/');
        $scSystem.portalUrlV2 = '/portal/v2'; 
        $scSystem.extensionsUrl = '/extensions/v2';
        $scSystem.baseUrl = '/portal/v1';
        return $scSystem;
      },
      authorizedUser: /*@ngInject*/ function ($rootScope, UserFactory, $flash, $state) {
        var VgisUser = new UserFactory();
        $rootScope.gUser = localStorage.gUser || sessionStorage.gUser;
        return VgisUser.me(function (r) {
          $rootScope.gUser = r;
          return r;
        }, function () {
          setTimeout(function () { 
            $state.go('sc.portalLogin'); 
            $flash.error = "Session lost";
          }, 0);
        }).$promise;
      },
      scPermissions: /*@ngInject*/ function (authorizedUser, $rootScope, $flash) {
        return authorizedUser.$getPermissions().$promise.then(function (permissions) {
          $rootScope.scPermissions = new UserPermissions(Object.keys(permissions));
          return $rootScope.scPermissions;
        }, function () {
          $flash.error = "Unable to get permissions.";
          return [];
        });
      },
      alert: function($mdDialog) {
        if (sessionStorage.getItem('systemAlertSeen')) {
          return true;
        }
        return $mdDialog.show({
          parent: angular.element(document.querySelector('#popupContainer')),
          template:
            '<md-dialog aria-label="List dialog">' +
            '  <md-dialog-content>'+
            '  <div class="md-dialog-content">'+
            '    <h3 class="md-subhead">You are entering Admin Zone</h3>'+
            '    <p>The changes you make may disrupt the system.<p>'+
            '  </div>'+
            '  </md-dialog-content>' +
            '  <md-dialog-actions>' +
            '    <md-button ng-click="closeDialog()" class="md-primary">' +
            '      Got it!' +
            '    </md-button>' +
            '  </md-dialog-actions>' +
            '</md-dialog>',
            controller: /*@ngInject*/ function($scope, $mdDialog) {
              $scope.closeDialog = function() {
                sessionStorage.setItem('systemAlertSeen', true);
                $mdDialog.hide();
              };
            }
          }
        );
      }
    }
  });
});