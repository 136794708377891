require('../../utils/angularSortableView');
require('../../../../gcf/sc/common/directives/scField');
require('./scSectionsBuilder.scss');

angular.module("sc.common.sectionsBuilder", [
  'sc',
  'sc.common.field',
  'angular-sortable-view'
]).directive('scSectionsBuilder', function () {
  return {
    restrict: "E",
    scope: {
      scSections: '=',
      scOwner: '=',
      scConnectorCode: '@',
      showAll: '=',
      scOnFieldSelect: '=?',
    },
    template: require('./scSectionsBuilder.html'),
    controller: /*@ngInject*/ function ($scope) {
      $scope.toggleOptionalFields = function (showAll) {
        var self = this;
        $scope.toggleOptionalView = showAll;
        if (typeof (self.optionalCount) === 'undefined') {
          self.optionalCount = $scope.scSections.$optionalCount;
        } else {
          $scope.scSections.$optionalCount = self.optionalCount;
        }
      };
      $scope.scEditField = function(f, sIndex, fIndex) {
        if (typeof($scope.scOnFieldSelect) == 'function') {
          $scope.scOnFieldSelect(f, sIndex, fIndex);
        } else {
          console.error('scOnFieldSelect is not a function');
        }
      };
      $scope.SUPPORTED_DEFAULT_VALUE_TYPES = [
        'string', 'text', 'integer', 'int', 'number', 'textarea', 'boolean', 'datetime', 'reference', 'multireference',
        'radio', 'combobox', 'picklist', 'multipicklist', 'double', 'currency', 'date', 'email'
      ];
      $scope.onFieldRefChange = function(newValue, field) {
        if (field.reference && field.reference.length) {
          for (var r in field.reference) {
            var ref = field.reference[r];
            if (ref.name === newValue) {
              ref.defaultValue = true;
            } else {
              delete ref.defaultValue;
            }
          }
        }
      };
      $scope.onFieldValueChange = function(newValue, field) {
        switch(field.type) {
          case 'string':
          case 'text':
          case 'integer':
          case 'int':
          case 'number':
          case 'textarea':
          case 'boolean':
          case 'date':
          case 'datetime':
            {
              if (typeof(newValue) === 'undefined' || newValue === null) {
                delete field.defaultValue;
              } else {
                if (!field.defaultValue) {
                  field.defaultValue = {};
                }
                if (newValue && typeof(newValue.getTime) === 'function') {
                  field.defaultValue.value = newValue.getTime();
                } else {
                  field.defaultValue.value = newValue;
                }
              }
              break;
            }
          case 'radio':
          case 'combobox':
          case 'picklist':
          case 'multipicklist': {
            if (newValue instanceof Array) {
              if (newValue.length) {
                field.defaultValue = newValue;
              } else {
                delete field.defaultValue;
              }
            } else {
              if (typeof(newValue) === 'undefined') { //allow removing selected value
                delete field.defaultValue;
              }
              if (field.multiple) {
                field.defaultValue = newValue;
              } else {
                (field.picklist || []).forEach(function(i) {
                  i.defaultValue = false;
                  if (newValue && (newValue.id || newValue.name || newValue) === (i.id || i.name || i)) {
                    i.defaultValue = true;
                    field.defaultValue = newValue;
                  }
                });
              }
            }
            break;
          }
          case 'reference':
          case 'multireference':
          {
            if (newValue instanceof Array) {
              if (newValue.length) {
                field.defaultValue = newValue;
              } else {
                delete field.defaultValue;
              }
            } else if (newValue && (newValue.resourceType || newValue.type)) {
              newValue.reference = newValue.resourceType || newValue.type; //add reference for BE compatible
              field.defaultValue = newValue;
            } else if (typeof(newValue) === 'undefined') {
              delete field.defaultValue;
            }
            break;
          }
          case 'list':
          {
            if (newValue instanceof Array) {
              if (newValue.length) {
                field.defaultValue = newValue;
              } else {
                delete field.defaultValue;
              }
            }
            break;
          }
        }
      };
    }
  };
});