var formErrorParser = require('../../common/utils/formErrorParser');
var userDescribe = require('../../mocks/accountUserDescribe.json');

module.exports = /*@ngInject*/ function($scope, $rootScope, $mdDialog, $mdToast, $state, $location, gUserId, gUserPromises, SystemRoleFactory) {
  var self = this;
  self.gUserId = gUserId;
  self.busyMask = true;
  self.authorizedUser = $rootScope.gUser;

  gUserPromises.item.then(function(user) {
    self.gUser = user;
    self.busyMask = false;
  });
  //gUserPromises.describe.then(function(describe) {
    self.describe = userDescribe;
    self.niceFields = {};
    for (var f=0; f < self.describe.sections[0].fields.length; f++) {
      var field = self.describe.sections[0].fields[f];
      self.niceFields[field.name] = self.describe.sections[0].fields[f];
    }
    self.niceFields['identity.username'] = { label: 'Vonage username' };
    self.niceFields['identity.provider'] = { label: 'Identity provider' };
  //});

  self.niceUcps = {};
  new SystemRoleFactory().query(function(roles) {
    self.roles = roles.map(function(role) {
      return { id: role.code, label: role.name+' ('+role.code+')' };
    });
  });

  self.cancel = function() {
    $mdDialog.cancel('cancel');
  };

  self.deleteAdmin = function(ev) {
    var confirm = $mdDialog.confirm()
    .title('Delete admin user '+self.gUser.fullName)
    .textContent('Are you sure?')
    .ariaLabel('Delete')
    .targetEvent(ev)
    .ok('Yes')
    .cancel('No');
    $mdDialog.show(confirm).then(function() {
      self.gUser.$delete({ force: true }, function() {
        var requestedPath = $location.path();
        if (!/error$/.test(requestedPath)) {
          sessionStorage.setItem('requestedPath', requestedPath);
        }
        $state.go('sc.system.admins.list');
      }, function() {
        $mdToast.show(
          $mdToast.simple()
            .textContent('Error deleting the user.')
            .hideDelay(5000)
        );
      });
    }, function() {
      if(window.scDebug) {
        console.info('Disable admin not confirmed');
      }
    });
  };

  self.save = function() {
    delete self.formErrors;
    if (self.adminForm.$valid) {
      self.busyMask = true;
      if (!self.gUserId) {
        if (self.gUser.identity && !self.gUser.identity.provider) {
          self.gUser.identity.provider = 'LDAP';
        }
      }
      self.gUser.$save(function(user) {
        $mdDialog.hide(user);
      }, function(errorResponse) {
        self.formErrors = formErrorParser(errorResponse, self.adminForm, self.niceFields) || errorResponse.data.message || errorResponse.data;
        self.busyMask = false;
      });
    } else {
      self.adminForm.$setDirty(true);
      var errorNames = [];
      angular.forEach(self.adminForm.$error, function(type) {
        angular.forEach(type, function(field) {
          field.$touched = true;
          field.$setDirty();
          errorNames.push(field.$name);
        });
      });
      self.formErrors = "Please fill out the required fields: "+errorNames.join(", ")+".";
    }
  };
};