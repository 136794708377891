var VirtualRepeatLoader = require('../../gcf/sc/common/virtualRepeatRestfulLoader');

require('ng-cache-loader?module=sc.portal,prefix=src:**!./_accountsFilters.htm');

module.exports = /*@ngInject*/ function ($stateParams, $rootScope, $window, $state, VgisAccount, VgisUcp, VgisIntegration) {
  var self = this;
  self.filter = {};
  if (!$stateParams.clearSearch && location.hash) {
    var params = location.hash.substr(1).split('&');
    params.forEach(function(pair) {
      var kv = pair.split('=');
      var key = kv[0];
      var value = decodeURIComponent(kv[1]);
      if (['integrationCodes', 'ucpIds'].indexOf(key) === -1) {
        self.filter[key] = value;
      } else {
        self.filter[key] = value.split(',');
      }
    });
  }

  if (!$rootScope.scPermissions.has('_LIST', 'ACCOUNT')) {
    self.forbidden = true;
    self.error = 'Missing permission to list all accounts';
  }

  VgisUcp.query(function(ucps) {
    self.ucps = ucps;
  }, function(err) {
    console.error(err);
  });

  VgisIntegration.query(function(integrations) {
    self.integrations = integrations.map(function(i) {
      return { id: i.code, label: i.name };
    });
  }, function(err) {
    console.error(err);
  });

  self.dynamicFetch = new VirtualRepeatLoader(VgisAccount, {
    filters: function () {
      var query = {};
      if (self.filter.q) {
        query.q = self.filter.q;
      }
      if (self.filter.integrationCodes) {
        query.integrationCodes = self.filter.integrationCodes.join(',');
      }
      if (self.filter.ucpIds) {
        query.ucpIds = self.filter.ucpIds.join(',');
      }
      if (self.filter.isDemo) {
        query.isExpiringDate = self.filter.isDemo;
      }
      return query;
    }
  });

  self.submit = function() {
    if (self.dynamicFetch && self.dynamicFetch.loaded) {
      if (!self.prevFilter) {
        self.prevFilter = {};
      }
      if (!angular.equals(self.prevFilter, self.filter)) {
        self.prevFilter = angular.copy(self.filter);
        self.dynamicFetch.refresh();
        if (history && typeof (history.pushState) === "function") {
          setTimeout(function() {
            var hash = '';
            if (self.filter.q) {
              hash += "#q="+encodeURIComponent(self.filter.q);
            }
            if (self.filter.integrationCodes) {
              hash += (hash.length ? '&' : '#')+'integrationCodes='+encodeURIComponent(self.filter.integrationCodes.join(','));
            }
            if (self.filter.ucpIds) {
              hash += (hash.length ? '&' : '#')+'ucpIds='+encodeURIComponent(self.filter.ucpIds.join(','));
            }
            history.replaceState({},'', location.origin+location.pathname+hash);
          }, 0);
        }
      }
    }
  };

  setTimeout(function() {
    angular.element($window).triggerHandler('resize');
  });

  self.openAccount = function($event, account) {
    if (account) $state.go('sc.portal.accounts.item', { id: account.id });
  };

  self.onAccountCreated = self.openAccount;
};