module.exports = /*@ngInject*/ function ($scope, $rootScope, IntegrationFactory) {
  var self = this;
  self.importedUsers = [];
  self.selectedUsers = [];

  self.ucpId = $rootScope.rightBarSet.ucpId;

  self.clear = function() {
    self.importedUsers.length = 0;
    self.selectedUsers.length = 0;
  };
  $rootScope.clearImport = self.clear;

  new IntegrationFactory().query(function(integrations) {
    self.integrations = integrations;
  });

  function decorateUser(u) {
    if (!u.uci.ucpId) u.uci.ucpId = self.ucpId;
    if (!u.uci.ucpAuthLevel) u.uci.ucpAuthLevel = 'INHERIT';
    if (self.integrationCodes && self.integrationCodes.length) {
      u.integrations = angular.copy(self.integrationCodes);
    }
    return u;
  }

  $scope.dragUser = function (ev) {
    var user = self.importedUsers[ev.target.getAttribute('name')];
    ev.dataTransfer.setData("text", JSON.stringify(decorateUser(user)));
  };

  self.toggleUserSelection = function(iUser) {
    if (iUser.$selected) {
      self.selectedUsers.push(iUser);
    } else {
      self.selectedUsers.splice(self.selectedUsers.indexOf(iUser), 1);
    }
  };

  self.toggleSelectVisible = function() { //ev is the unused parameter
    for (var i = 0; i < self.importedUsers.length; i++) {
      var iUser = self.importedUsers[i];
      if (!iUser.$hide) {
        iUser.$selected = !iUser.$selected;
        self.toggleUserSelection(iUser);
      }
    }
  };

  self.filterItems = function() { //ev is the unused parameter
    var filterRegex = new RegExp(this.filterText.toLowerCase());
    var items = document.getElementsByName("sc-import-user-item");
    for (var i = 0; i < items.length; i++) {
      var match = filterRegex.test(items[i].textContent.toLowerCase());
      var uIndex = items[i].querySelector('.md-list-item-text').getAttribute('name');
      self.importedUsers[uIndex].$hide = !match;
    }
  };

  $scope.handleUserImportCsvFiles = function (files) {
    self.importedUsers.length = 0;
    self.selectedUsers.length = 0;
    if (!files.length) {
      return;
    }
    if (window.File && window.FileReader && window.FileList && window.Blob) {
      var reader = new FileReader();
      try {
        reader.onload = (function () { //theFile is the unused parameter 
          return function (e) {
            var lines = e.target.result.split("\n");
            var headers = ['firstName', 'lastName', 'emailAddress', 'contactNumber'];
            for (var i = 0; i < lines.length; i++) {
              var obj = {};
              if (lines[i]) {
                var currentline = lines[i].split(",");
                for (var j = 0; j < headers.length; j++) {
                  obj[headers[j]] = currentline[j];
                }
                obj.uci = {
                  credentials: {
                    username: (typeof currentline[4] === 'string') ? currentline[4].trim() : currentline[4]
                  }
                };
                self.importedUsers.push(obj);
              }
            }
            $scope.$apply();
          };
        })(files[0]);
        reader.readAsText(files[0]);
      } catch (e) {
        self.error = e.message;
      }
    } else {
      self.error = 'The File APIs are not fully supported in this browser.';
    }
  };

  self.importUsersFromSource = function() {
    self.importedUsers.length = 0;
    self.selectedUsers.length = 0;
    if (self.source.type == 'csv') {
      return;
    }
    if (self.source.type == 'ucp') {
      self.busyMask = true;
      var page = 0;
      var getPage = function(pageNum, cb) {
        self.source.uci.$importUsers({ page: page, pageSize: 30 }, function(users) {
          // self.importedUsers = users;
          // self.busyMask = false;
          cb(users);
        }, function(error) {
          self.error = error.data;
          self.busyMask = false;
          cb([]);
        });
      };
      var loopUsers = function() {
        getPage(page, function(users) {
          if (!self.importedUsers) {
            self.importedUsers = [];
          }
          for (var i = 0; i < users.length; i++) {
            var existingUser = self.importedUsers.find(function(u) { 
              return (u.ucpUserId && u.ucpUserId === users[i].ucpUserId) ||
                (u.ucis && u.uci[0].credentials.username === users[i].ucis[0].credentials.username)
            });
            if (!existingUser) {
              self.importedUsers.push(users[i]);
            }
          }
          if (users.length === 30) {
            page += 1;
            loopUsers();
          } else {
            self.busyMask = false;
          }
        });
      }
      loopUsers();
    }
  };

  self.importSelectedUsers = function() {
    $rootScope.rightBarSet.importUsersCallback(self.selectedUsers.map(function(u) {
      return decorateUser(u);
    }));
  };
};