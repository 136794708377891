angular.module('sc.system.ucps', [
  'ui.router'
]).config(function ($stateProvider) {
  $stateProvider.state('sc.system.ucps', {
    url: '/ucps',
    template: require('./ucps.html'),
    controller: require('./ucpsCtrl'),
    controllerAs: "ucpsCtrl",
    onEnter: /*@ngInject*/ function ($portalHead) {
      $portalHead.title = 'System Service Providers';
    }
  });
});