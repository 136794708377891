var VirtualRepeatLoader = require('../../gcf/sc/common/virtualRepeatRestfulLoader');


module.exports = /*@ngInject*/ function ($scope, $rootScope, $window, $state, gAccountId, VgisAccountUsers, VgisUcp, VgisIntegration, VgisRoles) {
  var self = this;
  self.filter = {};

  self.gAccount = { id: gAccountId };
  if (!$rootScope.scPermissions.has('_LIST', 'ACCOUNT_USERS', gAccountId)) {
    self.forbidden = true;
    self.error = 'Missing permission list users of account #' + gAccountId;
    return;
  }

  VgisRoles.query(function (roles) {
    self.roles = roles.map(function (r) {
      return { name: r.code, label: r.name + ' (' + r.code +')' };
    });
  }, function (err) {
    console.error(err);
  });

  VgisUcp.query(function (ucps) {
    self.ucps = ucps;
  }, function (err) {
    console.error(err);
  });

  VgisIntegration.query(function (integrations) {
    self.integrations = integrations.map(function (i) {
      return { id: i.code, label: i.name };
    });
  }, function (err) {
    console.error(err);
  });

  self.dynamicFetch = new VirtualRepeatLoader(VgisAccountUsers, {
    filters: function () {
      var query = {};
      if (self.filter.q) {
        query.q = self.filter.q;
      }
      if (self.filter.integrationCodes) {
        query.integrationCodes = self.filter.integrationCodes.join(',');
      }
      if (self.filter.ucpIds) {
        query.ucpIds = self.filter.ucpIds.join(',');
      }
      if (self.filter.roles) {
        query.roles = self.filter.roles.join(',');
      }
      return query;
    },
    onUpdate: function () {
      setTimeout(function() {
        $scope.$apply();
      });
    }
  });
  setTimeout(function () {
    self.ready = true;
    angular.element($window).triggerHandler('resize');
  });

  self.onUserCreate = function (err, user) {
    if (user) {
      $state.go('sc.portal.users.item', { accountId: user.accountId, id: user.id });
    }
  };

  self.submit = function () {
    self.dynamicFetch.refresh();
    self.clearSelection();
  };

  self.toggleSelection = function(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    setTimeout(function() {
      self.selectionCount = 0;
      for (var s in self.selection) {
        if (self.selection[s]) self.selectionCount++;
      }
      if (!$scope.$$phase) {
        $scope.$apply();
      }
    }, 0);
    return false;
  };

  self.clearSelection = function() {
    self.selection = {};
    self.selectionCount = 0;
  };
};

require('ng-cache-loader?module=sc.portal,prefix=src:**!./_usersFilters.htm');