angular.module("sc.common.fieldPreview", [
  'sc'
]).directive('scFieldPreview', function ($rootScope) {
  return {
    restrict: "E",
    scope: {
      scDescribe: '=',
      scModel: '=',
      scConnectorCode: '@',
      scEndpointUrl: '@',
      scLiveLinkTemplate: '@',
      scShowSection: '=?',
    },
    template: require('./scFieldPreview.html'),
    link: function (scope) {
      if (['date', 'time'].indexOf(scope.scDescribe.type) !== -1) {
        if (scope.scModel && !scope.scModel.patchTimezone) {
          var value;
          if (typeof(scope.scModel) === 'string') {
            value = new Date(scope.scModel);
          } else {
            if (typeof(scope.scModel) === 'number') {
              value = new Date(scope.scModel);
            } else if (scope.scModel instanceof Date) {
              value = scope.scModel;
            }
            scope.scModel = new Date(value.getTime() + (value.getTimezoneOffset() * 60000));
            scope.scModel.patchTimezone = true;
          }
        }
      }
      scope.isShown = function() {
        if (scope.scModel !== null && typeof scope.scModel !== 'undefined') {
          switch (typeof(scope.scModel)) {
            case 'boolean':
              return true;
            case 'object':
              return (scope.scModel instanceof Array) ? scope.scModel.length : true;
            case 'number':
              return true;
            default:
              return !!scope.scModel;
          }
        }
        return false;
      };
      scope.valueItems = function() {
        if (!scope.scModel.data) {
          if (scope.scDescribe.type === "list" && scope.scDescribe.reference && scope.scDescribe.reference.fields && scope.scDescribe.reference.fields.length && scope.scModel instanceof(Array)) {
            return  [scope.scModel.length];
          } else if (scope.scDescribe.type === "password") {
            return ["**********"];
          } else {
            return scope.scModel instanceof(Array) ? scope.scModel : [scope.scModel];
          }
        } else {
          return scope.scModel.data;
        }
      };

      scope.openExternalLink = function(ev, item) {
        // if (!scope.scLiveLinkTemplate) return;
        // var link = scope.scLiveLinkTemplate.replace(/\$\{(\w+)\}/g, function(match, p) {
        //   return item[p];
        // });
        // if (typeof(link) === "string" && !/^https?:\/\//.test(link)) {
        //   link = 'https://'+link;
        // }
        // if(/#.*$/.test(link)) {
        //   $rootScope.clickOpenPage(ev, link, true);
        // } else $rootScope.clickOpenPage(ev, link);
        $rootScope.openInCRM(ev, {
          _vgis: {
            connector: scope.scConnectorCode,
            externalId: item.id,
            resourceType: item.type
          }
        });
      };

      scope.scShowSection = scope.isShown();
    }
  };
}).directive('scSectionsPreview', function() {
  return {
    restrict: "E",
    scope: {
      scSections: '=',
      scOwner: '=',
      scConnectorCode: '@',
      scEndpointUrl: '@',
      scLiveLinkTemplate: '@'
    },
    template: require('./scSectionsPreview.html'),
    link: function (scope) {
      scope.showHeader = function() {
        var element = document.getElementById('scFieldPreview');
        if (element !== null) {
          return true;
        }
      };
    }
  };
});