module.exports = /*@ngInject*/ function ($mdDialog, ConferencingSettings) {
  var self = this;

  function getConferences() {
    ConferencingSettings.get(function(conferences) {
      self.conferences = conferences;
      if (!self.conferences.length) {
        if (!sessionStorage.getItem('newConferenceSeen')) {
          self.conferenceDialog();
          sessionStorage.setItem('newConferenceSeen', 1);
        }
      }
    }, function() {
      self.formErrors = 'Error getting conferences data.';
    });
  }
  getConferences();

  self.conferenceDialog = function(c) {
    $mdDialog.show({
      multiple: true,
      template: require('./conference.html'),
      controller: require('./conferenceCtrl'),
      controllerAs: 'conferenceCtrl',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      locals: {
        conference: c || new ConferencingSettings()
      }
    }).then(function () {
      getConferences();
    }, getConferences);
  };

  self.cancel = function() {
    $mdDialog.cancel();
  };
};