var formErrorParser = require('../../common/utils/formErrorParser');

module.exports = /*@ngInject*/ function ($scope, $mdDialog, $mdToast, gAccountService, gAccount, logoForIntegration, IntegrationFactory) {
  var self = this;
  self.gAccount = gAccount;
  self.integrationsMap = {};
  self.logoForIntegration = logoForIntegration;
  self.fullSync = false;

  var IntegrationModel = new IntegrationFactory({accountId: gAccount.id});
  self.busyMask = true;
  IntegrationModel.getIntegrationsList(function (integrations) {
    if (integrations && integrations.length) {
      integrations.sort(function(a , b){ //Integrations Order Filter.
        if(a.order > b.order) return 1;
        if(a.order < b.order) return -1;
        return 0;
      });
      self.integrations = integrations;  
    }
    gAccountService.sync.get({ resources: 'contacts' }, function(syncResponse) {
      for (var i in syncResponse.integrations) {
        var lastSync = undefined;
        var objectTypes = [];
        var iSyncStatus = syncResponse.integrations[i];
        var integration = self.integrations.find(function(itg) { return itg.code == iSyncStatus.integrationCode });
        if (integration && iSyncStatus.objectTypes) {
          for (var o in iSyncStatus.objectTypes) {
            var createdAt =  iSyncStatus.objectTypes[o].createdDate;
            if (createdAt && iSyncStatus.objectTypes[o].status !== 'NEVER' && (!lastSync || lastSync.getTime() < createdAt)) {
              lastSync = new Date(createdAt);
            }
            switch(iSyncStatus.objectTypes[o].status) {
              case 'COMPLETED':
                iSyncStatus.objectTypes[o].name += " ✓";
                break;
              case 'FAILED':
                iSyncStatus.objectTypes[o].name += " ⚠";
                break;
            }
            objectTypes.push(iSyncStatus.objectTypes[o].name);
            // objectTypes.push(objectTypes[o]);
          }
          integration.syncStatus = {
            status: iSyncStatus.status,
            lastSync: lastSync,
            objectTypes: objectTypes
          };
        }
      }
      self.busyMask = false;
    }, function(syncError) {
      self.syncError = syncError.data ? syncError.data.message || 'Unable to get sync status' : 'Unable to get sync status';
      self.busyMask = false;
    });
  });

  self.cancel = function () {
    $mdDialog.cancel('cancel');
  };

  self.submit = function () {
    delete self.formErrors;
    if (self.syncContactsForm.$valid) {
      self.busyMask = true;
      gAccountService.sync.save({ resources: 'contacts' }, { 
        full: self.fullSync,
        integrations: Object.keys(self.integrationsMap).filter(function(o) { return self.integrationsMap[o];})
      }, function (syncResponse) {
        $mdDialog.hide(syncResponse);
        $mdToast.showSimple("Contacts synchronization started");
      }, function (errorResponse) {
        self.formErrors =  formErrorParser(errorResponse, self.syncContactsForm);
        self.busyMask = false;
      });
    } else {
      self.settingsForm.$setDirty(true);
      var errorNames = [];
      angular.forEach(self.settingsForm.$error, function (type) {
        angular.forEach(type, function (field) {
          field.$touched = true;
          field.$setDirty();
          errorNames.push(field.$name);
        });
      });
      self.formErrors = "Please fill out the required fields: " + errorNames.join(", ") + ".";
    }
  };
};