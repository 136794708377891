angular.module('sc.portal.reports.summary', [
  'ui.router'
]).config(/*@ngInject*/ function ($stateProvider) {
  $stateProvider.state('sc.portal.reports.summary', {
    url: '/summary',
    template: require('./summary.html'),
    controller: require('./summaryCtrl'),
    controllerAs: "summaryCtrl",
    resolve: {
      summaryReportService: /*@ngInject*/ function (portalSystem, $scSystem, $resource) {
        return $resource($scSystem.portalUrl + '/reports/summary', null, {
          query: {
            method: 'POST'
          }
        });
      }
    }
  });
});