require('./settingsDlg.scss');
var vccSettingsLayout = require('./vccSettingsLayout.json');

var formErrorParser = require('../../common/utils/formErrorParser');
var settingsHelper = require('../../../gcf/sc/common/helpers/settingsHelper');

var uiSettingsMetaSections = require('../../users/settings/metaUserFeatures.json');
var uiSettingsCTISections = require('./metaCtiSettings.json');
var uiSettingsAutoPop = require('../../../gcf/sc/settings/autoPopup/metaSms.json');
var uiSettingsAutoPopNoSms = require('../../../gcf/sc/settings/autoPopup/metaNoSms.json');
var uiSettingsAutoPopDefault = require('../../../gcf/sc/settings/autoPopup/defaultRules.json');
var uiSettingsFeaturesDefault = require('../../users/settings/defaultFeatures.json');

module.exports = /*@ngInject*/ function ($rootScope, $mdDialog, $mdToast, gAccount, logoForIntegration, connector, gAccountSettings, settingsGroup, onUpdateCallback) {
  var self = this;
  self.gAccount = gAccount;
  self.logoForIntegration = logoForIntegration;
  self.connector = connector;
  self.locks = {};
  self.shadowPopupSettings = [];
  self.settingKeys = [];

  if (scDevEnv) {
    var vccEnvField = vccSettingsLayout.sections[0].fields[0].reference.fields[0];
    if (vccEnvField.name === 'vccEnv') {
      var itgExists = vccEnvField.picklist.find(function(o) { return o.id === 'ITG' });
      if (!itgExists) {
        vccEnvField.picklist.push({ id: 'ITG', label: 'ITG' });
      }
    }
  }

  if ($rootScope.scPermissions.has('SETTINGS_UPDATE', 'ACCOUNT', gAccount.id)) {
    self.locks.$level = 'account'; // leaving this bank will hide the lock icon
  }

  self.cancel = function () {
    $mdDialog.cancel('cancel');
  };

  if (!self.connector) { // not integration settings
    if (settingsGroup === 'ui.*') {
      self.title = 'Account Users Features';
      self.uiSettingsMetaSections = uiSettingsMetaSections;
      if (!hasSms() && self.uiSettingsMetaSections[0].fields[2].name === 'smsEnabled') {
        self.uiSettingsMetaSections[0].fields.splice(2,3); //hide SMS, callRecordings, voicemail feature
      }
      uiSettingsMetaSections[0].fields.forEach(function(field) {
        self.settingKeys.push('ui.'+field.name);
      });
    } else if (settingsGroup === 'ui.popup.*,ui.autopop') {
      self.title = 'Users Auto Pop Manager';
      self.uiSettingsAutoPop = hasSms() ? uiSettingsAutoPop : uiSettingsAutoPopNoSms;
    }  else if (settingsGroup === 'cti.*') {
      self.title = 'ContactPad CTI';
      if (!scDevEnv) {
        delete uiSettingsCTISections[0].fields[0].picklist[4]; // hide WebRTC option in production
      }
      self.uiSettingsCti = uiSettingsCTISections; 
    }
  }

  self.resetDefaults = function (ev) {
    self.busyMask = true;
    var confirm = $mdDialog.confirm()
      .title('Are you sure?')
      .textContent('Reset to defaults')
      .ariaLabel('Reset to defaults')
      .targetEvent(ev)
      .ok('Yes')
      .cancel('Cancel')
      .multiple(true);
    $mdDialog.show(confirm).then(function () {
      if (settingsGroup === 'ui.popup.*,ui.autopop') {
        self.uiSettings.autopop = uiSettingsAutoPopDefault;
        self.gAccountSettings['ui.autopop'] = uiSettingsAutoPopDefault;
        return self.gAccountSettings.$update(function (/* accountSettings */) {
          if (typeof(onUpdateCallback) == 'function') {
            onUpdateCallback();
          }
          delete self.busyMask;
        }, function (errorResponse) {
          self.formErrors =  formErrorParser(errorResponse, self.settingsForm, self.niceFields);
          self.busyMask = false;
          delete self.busyMask;
        });
      } else if (settingsGroup === 'ui.*') {
        self.uiSettings = settingsHelper.keyValsToSettngs(uiSettingsFeaturesDefault);
        return gAccountSettings.update(uiSettingsFeaturesDefault, function (/* accountSettings */) {
          $mdDialog.hide();
          $mdToast.showSimple("Settings made to default");
          if (typeof(onUpdateCallback) == 'function') {
            onUpdateCallback();
          }
          delete self.busyMask;
        }, function (errorResponse) {
          self.formErrors =  formErrorParser(errorResponse, self.settingsForm, self.niceFields);
          self.busyMask = false;
          delete self.busyMask;
        });
      }
      var deleteParams = {};
      if (!self.connector) {
        deleteParams.keys = settingsGroup === 'ui.popup.*,ui.autopop' ? settingsGroup : Object.keys(self.uiSettings).map(function(s) { return 'ui.' + s; }).join(',');
      }
      gAccountSettings.delete(deleteParams, function () {
        $mdDialog.hide();
        $mdToast.showSimple("Settings made to default");
        if (typeof(onUpdateCallback) == 'function') {
          onUpdateCallback();
        }
        delete self.busyMask;
      }, function (err) {
        $mdToast.showSimple(err.data.message + '-' + (err.data.code));
        console.error("error in accounts integration(delete Method)", err);
        delete self.busyMask;
      });
    }, function () {
      console.info("Not confirmed");
      delete self.busyMask;
    });
  };

  function getDescribe() {
    gAccountSettings.describe(function (describe) {
      if (self.connector &&  self.connector.code === 'VCC') {
        self.describe = vccSettingsLayout;
      } else {
        self.describe = describe.layout;
      }
      self.niceFields = {};
      self.sections = [];
      self.describe.sections.forEach(function(section) {
        if(!section.fields) {
          return;
        }
        var s = { label: section.label, fields: [] };
        for (var index = section.fields.length - 1; index >= 0; index--) {
          var field = section.fields[index];
          if (field.type === 'composite' && field.fields && field.fields.length) {
            for (var fi = 0; fi < field.fields.length; fi++) {
              var f = field.fields[fi];
              if (f.type === 'picklist' && !f.defaultValue) {
                if (f.picklist && f.picklist.length) {
                  if (!self.gAccountSettings[f.name]) {
                    f.defaultValue = f.picklist[0];
                  }
                }
              }
              if (f.type === 'composite' && f.fields && f.fields.length) {
                for (var fi2 = 0; fi2 < f.fields.length; fi2++) {
                  var f2 = f.fields[fi2];
                  self.niceFields[f2.name] = f2;
                }
              } else {
                self.niceFields[f.name] = f;
              }
            }
          } else {
            self.niceFields[field.name] = field;
          }
          if (field.name == 'default.comments') { //temp workaround metadata missmatch (BE fix required)
            field.name = 'default.description';
          }
          if (field.name == 'organization.name' || field.name == 'organization.id' || field.name == 'organization.domain') {
            field.createable = true;         //temp workaround until BE sends in metadata
            field.disabled = true;
          }
          // if (field.name === 'nominated.userId') {
          //   console.log('nominated.userId', field);
          //   field.type = 'reference';
          //   field.reference = {
          //     name: 'user',
          //     creatable: false,
          //     updatable: false
          //   };
          // }
          s.fields.push(field);
        }
        s.fields = s.fields.reverse();
        self.sections.push(s);
      });
      // Temporary njected sample auto log settings
      // self.sections.unshift(newAutoLogSettings);
      self.busyMask = false;
    }, function () { });
  }

  gAccountSettings.get({ keys: self.settingKeys.length ? self.settingKeys.join(',') : settingsGroup }, function (accountSettings) {
    var settings = {};
    var uiSettings = {};
    function checkExistingOption(r) {
      return r.id == rule.popup;
    }
    for (var s in accountSettings.toJSON()) {
      var setting = accountSettings[s];
      if (!setting.key && typeof(s) === 'string') {
        setting.key = s;
      }
      if (setting) {
        if (/^ui\./.test(setting.key)) {
          uiSettings[setting.key] = setting;
          if (setting.locked) {
            self.locks[setting.key.substring(3)] = true;
          }
          if (/^ui\.popup\./.test(setting.key)) {
            self.shadowPopupSettings.push(setting.key);
          }
        } else {
          settings[setting.key] = setting.value;
          if (setting.locked) {
            self.locks[setting.key] = true;
          }
        }
        if (setting.key === 'ui.scCallAppearance' && typeof(setting.value) === 'string') { //Dirty fix to transform sting to boolean
          setting.value = setting.value === 'none' ? true : false; 
        }
        if (setting.key === 'ui.autopop' && setting.value) {
          for (var ruleGroup in setting.value) {
            for (var i in setting.value[ruleGroup]) {
              var rule = setting.value[ruleGroup][i];
              if (/http(s)?:/.test(rule.popup)) {
                for (var p = 0; p < self.uiSettingsAutoPop.length; p++) {
                  var section = self.uiSettingsAutoPop[p];
                  var popupField = section.fields[0].reference.fields.find(function(f) { return f.name === 'popup'; });
                  if (popupField && popupField.picklist && popupField.picklist.length) {
                    if (!popupField.picklist.find(checkExistingOption)) {
                      popupField.picklist.push({
                        id: rule.popup,
                        label: '🌐 '+rule.popup
                      });
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    self.settings = settings;
    self.gAccountSettings = new gAccountSettings();
    self.uiSettings = settingsHelper.keyValsToSettngs(uiSettings);
    if (settingsGroup === 'ui.popup.*,ui.autopop') {
      if (!self.uiSettings.autopop) {
        if (!self.uiSettings.popup) {
          self.uiSettings.autopop = {};
        } else {
          self.uiSettings.autopop = self.uiSettings.popup;
        }
      }
      delete self.uiSettings.popup; // forget about old popup settings
    }
    getDescribe();
  }, function (errorResponse) {
    self.formErrors =  formErrorParser(errorResponse, self.settingsForm, self.niceFields);
  });

  
  self.save = function () {
    delete self.formErrors;
    if (self.settingsForm.$valid) {
      self.busyMask = true;
      var confirm = $mdDialog.confirm()
        .title('Are you sure?')
        .textContent('This action will overwrite the settings for the users under this account.')
        .ariaLabel('Are you sure?')
        .ok('Yes')
        .cancel('Cancel')
        .multiple(true);
      $mdDialog.show(confirm).then(function () {
        if (settingsGroup === 'ui.popup.*, ui.autopop') {
          for (var i = 0; i < self.shadowPopupSettings.length; i++) {
            self.gAccountSettings[self.shadowPopupSettings[i]] = {
              value: null
            };
          }
        }
        var uiKeyVals = settingsHelper.settingsToKeyVals(self.uiSettings);
        for (var k in uiKeyVals) {
          if (uiKeyVals[k] && (typeof(uiKeyVals[k].value) !== 'undefined')) {
            if (k === 'ui.scCallAppearance' && typeof(uiKeyVals[k].value === 'boolean')) { //Dirty fix to transform boolean to string
              uiKeyVals[k].value = uiKeyVals[k].value ? 'none' : 'notification';
            }
            self.gAccountSettings[k] = {
              value: uiKeyVals[k].value,
              locked: self.locks[k.substring(3)]
            };
          }
        }
        for (var s in self.settings) {
          if (!self.niceFields[s] && !/^cti\./.test(s)) {
            continue;
          }
          if (/^organization/.test(s)) {
            continue;
          }
          self.gAccountSettings[s] = {
            value: self.settings[s],
            locked: self.locks[s]
          };
        }
        for (var key in self.gAccountSettings) {
          if (key === 'auto.activity.sms.enabled' && self.gAccountSettings[key].value) {
            self.smsDisclamer = true;
          }
        }
        if (self.smsDisclamer) {
          var disclaimerConfirm = $mdDialog.confirm()
              .title('SMS Auto-activity disclaimer')
              .htmlContent("<p>SMS Auto logging can result in sensitive inbound and outbound messages logging to your CRM. </br> Click 'I Agree' to confirm that you still would like to turn on auto SMS logging.</p>")
              .ariaLabel('Are you sure?')
              .ok('I Agree')
              .cancel('Cancel')
              .multiple(true);
            $mdDialog.show(disclaimerConfirm).then(function () {  
              self.gAccountSettings.$update(function (account) {
                $mdDialog.hide(account);
                $mdToast.showSimple("Settings saved successfully");
                delete self.busyMask;
                return;
              }, function (errorResponse) {
                self.formErrors =  formErrorParser(errorResponse, self.settingsForm, self.niceFields);
                delete self.busyMask;
              });  
            }, 
            function () {
              console.info("Not confirmed");
              delete self.busyMask;
            });
        }
        if (!self.smsDisclamer) {
          self.gAccountSettings.$update(function (account) {
            $mdDialog.hide(account);
            $mdToast.showSimple("Settings saved successfully");
            delete self.busyMask;
          }, function (errorResponse) {
            self.formErrors =  formErrorParser(errorResponse, self.settingsForm, self.niceFields);
            delete self.busyMask;
          });
        }
      }, function () {
        console.info("Not confirmed");
        delete self.busyMask;
      });
    } else {
      self.settingsForm.$setDirty(true);
      var errorNames = [];
      angular.forEach(self.settingsForm.$error, function (type) {
        angular.forEach(type, function (field) {
          field.$touched = true;
          field.$setDirty();
          errorNames.push(field.$name);
        });
      });
      self.formErrors = "Please fill out the required fields: " + errorNames.join(", ") + ".";
    }
  };

  function hasSms() {
    for (var uci in gAccount.ucis) {
      if (gAccount.ucis[uci].ucpType === 'VBS' || /Vonage Business|VBC/.test(gAccount.ucis[uci].ucpLabel)) {
        return true;
      } 
    }
  }
};