angular.module('sc.model.resource', [
    'sc',
    'ngResource'
  ])
  .factory('ResourceFactory', /*@ngInject*/ function($injector, $resource, $scSystem) {
    var ResourceModel = function (attributes) {
      var Resource = $resource($scSystem.portalUrl+'/accounts/:accountId/integrations/:integrationCode/resources/:id', {
        accountId: function (that) { return (that ? (that.accountId || attributes.accountId) : attributes.accountId) || attributes.accountId; },
        integrationCode: function (that) { return (that ? (that.integrationCode || attributes.integrationCode) : attributes.integrationCode) || attributes.integrationCode; },
        id: function(that) { return (that ? (that.name || that.resourceType) : attributes.resourceType) || attributes.resourceType; }
      }, {
        put: {
          method: 'PUT'
        },
        update: {
          method: 'PUT',
          isArray: true
        },
        reset: {
          method: 'DELETE',
          isArray: true
        }
      });
  
      return Resource;
    };
  
    return ResourceModel;
  });