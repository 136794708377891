angular.module('sc.model.admin', [
  'sc',
  'ngResource'
]).factory('AdminFactory', /*@ngInject*/ function ($injector, $resource, $scSystem) {
  function decorateUser(user) {
    user.fullName = user.firstName + ' ' + user.lastName;
    if (user.identities) {
      user.identityNames = user.identities.map(function (u) { return u.username; }).join(', ');
    }
    if (user.roles && user.roles.map) {
      user.roleNames = user.roles.map(function (r) { return r.name; }).join(', ');
    }
  }

  function transformUserResponse(data, headersGetter, status) {
    var jsonData = angular.fromJson(data);
    if ([200, 201].indexOf(status) !== -1) {
      decorateUser(jsonData);
    }
    return jsonData;
  }

  var UserResource = function (attributes) {
    if (!attributes) {
      attributes = {};
    }
    var User = $resource($scSystem.portalUrl + '/system/admins/:id', {
      id: '@id'
    }, {
        count: {
          method: 'GET',
          url: $scSystem.portalUrl + '/system/admins/count'
        },
        query: {
          method: 'GET',
          isArray: true,
          transformResponse: function (data, headersGetter, status) {
            var response = angular.fromJson(data);
            if (status === 200) {
              for (var i = 0; i < response.length; i++) {
                decorateUser(response[i]);
              }
            }
            return response;
          }
        },
        get: {
          transformResponse: transformUserResponse
        },
        update: {
          method: 'PUT',
          transformResponse: transformUserResponse
        },
        delete: {
          method: 'DELETE',
          url: $scSystem.portalUrl + '/accounts/-2/users/:id'
        },
        create: {
          method: 'POST',
          transformResponse: transformUserResponse
        },
        describe: {
          method: 'GET',
          url: $scSystem.portalUrl + '/system/users/:id/describe'
        }
      });

    User.prototype.$save = function (success, error) {
      return (this.id) ? this.$update(success, error) : this.$create(success, error);
    };

    User.prototype.$setRoles = function (data, successCallback, errorCallback) {
      var that = this;
      $resource($scSystem.portalUrl + '/system/admins/' + this.id + '/roles', {}, {
        patch: {
          method: 'PATCH'
        }
      }).patch(data, function (roles) {
        that.roles = roles;
        decorateUser(that);
        successCallback(that);
      }, errorCallback);
    };

    User.prototype.$updateIdentity = function (data, successCallback, errorCallback) {
      var that = this;
      $resource($scSystem.portalUrl + '/system/admins/' + this.id + '/identities/:id', {
        id: function(that) {
          return that.id || data.id;
        }
      }, {
        save: {
          method: 'PUT'
        }
      }).save(data, function () {
        successCallback(that);
      }, errorCallback);
    };

    User.prototype.$setEnabled = function (isEnabled, successCallback, errorCallback) {
      var that = this;
      $resource($scSystem.portalUrl + '/system/admins/' + this.id + '/enable', {}, {
        patch: {
          method: 'PATCH'
        }
      }).patch({}, function (u) {
        that.status = u.status;
        successCallback(that);
      }, errorCallback);
    };

    Object.defineProperty(User.prototype, 'isDisabled', {
      get: function () {
        return this.status === 'INACTIVE';
      }
    });

    Object.defineProperty(User.prototype, 'isInternal', {
      get: function () {
        return parseInt(this.accountId) === -2;
      }
    });

    return User;
  };

  return UserResource;
});