/*
 * @Author: Alexander.Vangelov@vonage.com 
 * @Date: 2022-05-10 14:48:39 
 * @Last Modified by: Alexander.Vangelov@vonage.com
 * @Last Modified time: 2022-05-10 14:56:13
 */

var wordToMilliseconds = {
  now: 0,
  seconds: 1000,
  minutes: 1000 * 60,
  hours: 1000 * 60 * 60,
  days: 1000 * 60 * 60 * 24
};

function protoTime(text) { // protocol is: 30.minutes, -4.hours, 3.days, etc...
  var protoMatch = (text || '').match(/^(-?)((\d+).)?(\S+)$/);
  if (protoMatch) {
    var negative = protoMatch[1] === '-';
    var units = parseInt(protoMatch[3], 10) * (wordToMilliseconds[protoMatch[4]] || 0);
    if (negative) {
      return -units;
    } else {
      return units;
    }
  }
}

module.exports = function(value, base) {
  var timestamp = base || new Date().getTime();
  var offset = protoTime(value);
  if (offset) {
    timestamp = timestamp + offset;
  }
  return timestamp;
};
