require('../../models/admin');
require('../../models/systemRole');

angular.module('sc.system.admins', [
  'ui.router',
  'sc.model.admin',
  'sc.model.systemRole'
]).config(function ($stateProvider) {
  $stateProvider.state('sc.system.admins', {
    abstract: true,
    template: "<ui-view layout='column' flex></ui-view>",
    onEnter: /*@ngInject*/ function ($portalHead) {
      $portalHead.title = 'Internal users';
    },
    resolve: {
      VgisAdmin: /*@ngInject*/ function (AdminFactory) {
        return new AdminFactory();
      },
      VgisSystemRole: /*@ngInject*/ function (SystemRoleFactory) {
        return new SystemRoleFactory();
      }
    }
  }).state('sc.system.admins.list', {
    url: '/admins',
    template: require('./admins.html'),
    controller: require('./adminsCtrl'),
    controllerAs: "adminsCtrl",
  }).state('sc.system.admins.item', {
    url: '/admins/:id',
    template: require('./admin.html'),
    controller: require('./adminCtrl'),
    controllerAs: 'adminCtrl',
    resolve: {
      gUserId: /*@ngInject*/ function($stateParams) {
        return $stateParams.id;
      },
      gAccountId: /*@ngInject*/ function($stateParams) {
        return $stateParams.accountId;
      },
      gUserService: /*@ngInject*/ function ($stateParams, VgisAdmin) {
        return new VgisAdmin({ id: $stateParams.id });
      },
    }
  });
});