require('./settingsDlg.scss');

var formErrorParser = require('../../common/utils/formErrorParser');
var settingsHelper = require('../../../gcf/sc/common/helpers/settingsHelper');


var uiSettingsMetaSections = require('./metaUserFeatures.json');
var uiSettingsCTISections = require('./metaCtiSettings.json');
var uiSettingsAutoPop = require('../../../gcf/sc/settings/autoPopup/metaSms.json');
var uiSettingsAutoPopNoSms = require('../../../gcf/sc/settings/autoPopup/metaNoSms.json');
var uiSettingsAutoPopDefault = require('../../../gcf/sc/settings/autoPopup/defaultRules.json');
var uiSettingsFeaturesDefault = require('./defaultFeatures.json');

module.exports = /*@ngInject*/ function ($rootScope, $mdDialog, $mdToast, gUser, logoForIntegration, connector, gUserSettings, settingsGroup, onUpdateCallback, UserNotificationsFactory) {
  var self = this;
  self.gUser = gUser;
  self.logoForIntegration = logoForIntegration;
  self.connector = connector;
  self.locks = {};
  self.shadowPopupSettings = [];
  self.settingKeys = [];
  self.smsTriggerChange = false;

  self.cancel = function () {
    $mdDialog.cancel('cancel');
  };

  if (gUser.accountId.toString() !== '-1') {
    if ($rootScope.scPermissions.has('SETTINGS_UPDATE', 'ACCOUNT', gUser.accountId)) {
      self.locks.$level = 'account'; // can change locking, field always enabled
    } else if ($rootScope.scPermissions.has('SETTINGS_UPDATE', 'ACCOUNT_USERS', gUser.id)) {
      self.locks.$level = 'user'; // can change locking, field shown disabled when locked
    } else {
      self.locks.$level = 'none'; // can't change locking, can't change value
    } // else no locking visualization
  }

  if (!self.connector) { // not integration settings
    if (settingsGroup === 'ui.*') {
      self.title = 'User Features';
      self.uiSettingsMetaSections = uiSettingsMetaSections;
      if (!hasSms() && self.uiSettingsMetaSections[0].fields[2].name === 'smsEnabled') {
        self.uiSettingsMetaSections[0].fields.splice(2,3); //hide SMS, callRecordings, voicemail feature
      }
      uiSettingsMetaSections[0].fields.forEach(function(field) {
        self.settingKeys.push('ui.'+field.name);
      });
    } else if (settingsGroup === 'ui.popup.*,ui.autopop') {
      self.title = 'Auto Pop Manager';
      self.uiSettingsAutoPop = hasSms() ? uiSettingsAutoPop : uiSettingsAutoPopNoSms;
    } else if (settingsGroup === 'cti.*') {
      self.title = 'ContactPad CTI';
      if (!scDevEnv) {
        delete uiSettingsCTISections[0].fields[0].picklist[4]; // hide WebRTC option in production
      }
      self.uiSettingsCti = uiSettingsCTISections; 
    }
    self.gNotificationService = new UserNotificationsFactory({ accountId: self.gUser.accountId, usersPath: 'users', userId: self.gUser.id, type: 'card'});
  }

  self.resetDefaults = function (ev) {
    self.busyMask = true;
    var confirm = $mdDialog.confirm()
      .title('Are you sure?')
      .textContent('This will reset all your Settings')
      .ariaLabel('Reset to defaults')
      .targetEvent(ev)
      .ok('Yes')
      .cancel('No');
    $mdDialog.show(confirm).then(function () {
      if (settingsGroup === 'ui.popup.*,ui.autopop') {
        self.uiSettings.autopop = uiSettingsAutoPopDefault;
        self.gUserSettings['ui.autopop'] = uiSettingsAutoPopDefault;
        return self.gUserSettings.$update(function (/* accountSettings */) {
          if (typeof(onUpdateCallback) == 'function') {
            onUpdateCallback();
          }
          delete self.busyMask;
        }, function (errorResponse) {
          self.formErrors =  formErrorParser(errorResponse, self.settingsForm, self.niceFields);
          self.busyMask = false;
          delete self.busyMask;
        });
      } else if (settingsGroup === 'ui.*') {
        self.uiSettings = settingsHelper.keyValsToSettngs(uiSettingsFeaturesDefault);
        return gUserSettings.update(uiSettingsFeaturesDefault, function (/* accountSettings */) {
          if (typeof(onUpdateCallback) == 'function') {
            onUpdateCallback();
          }
          delete self.busyMask;
        }, function (errorResponse) {
          self.formErrors =  formErrorParser(errorResponse, self.settingsForm, self.niceFields);
          self.busyMask = false;
          delete self.busyMask;
        });
      }
      var deleteParams = {};
      if (!self.connector) {
        deleteParams.keys = settingsGroup === 'ui.popup.*,ui.autopop' ? settingsGroup : Object.keys(self.uiSettings).map(function(s) { return 'ui.' + s; }).join(',');
      }
      gUserSettings.delete(deleteParams, function() {
        $mdToast.showSimple("Settings made to default");
        if (typeof(onUpdateCallback) == 'function') {
          onUpdateCallback();
        }
        delete self.busyMask;
      }, function(err) {
        console.error("error in user integration settings(DELETE method)", err);
        delete self.busyMask;
      });
    }, function () {
      console.info("Not confirmed");
      delete self.busyMask;
    });
  };
  
  function getDescribe() {
    gUserSettings.describe(function(describe) {
      if (self.connector &&  self.connector.code === 'VCC') {
        describe = { layout: { sections: [] }};
      }
      self.niceFields = {};
      describe.layout.sections.forEach(function(section) {
        if(!section.fields) {
          return;
        }
        if (section.label === 'User Integration Settings') {
          delete section.label;
        }
        for (var index = section.fields.length - 1; index >= 0; index--) {
          var field = section.fields[index];
          if (field.type === 'composite' && field.fields && field.fields.length) {
            for (var fi = 0; fi < field.fields.length; fi++) {
              var f = field.fields[fi];
              self.niceFields[f.name] = f;
            }
          } else {
            self.niceFields[field.name] = field || {};
          }
        }
      });
      self.describe = describe.layout;
    }, function(errorResponse){
      self.error = errorResponse.data.message;
    });
  }
  
  gUserSettings.get({ keys: self.settingKeys.length ? self.settingKeys.join(',') : settingsGroup }, function(userSettings) {
    var settings = {};
    var uiSettings = {};
    function checkExistingOption(r) {
      return r.id == rule.popup;
    }
    for (var s in userSettings.toJSON()) {
      var setting = userSettings[s];
      if (!setting.key && typeof(s) === 'string') {
        setting.key = s;
      }
      if (setting) {
        if (s === 'auto.activity.sms.enabled' && !setting.value) {
          self.smsTriggerChange = true;
        }
        if (/^ui\./.test(setting.key)) {
          uiSettings[setting.key] = setting;
          if (setting.locked) {
            self.locks[setting.key.substring(3)] = true;
          }
          if (/^ui\.popup\./.test(setting.key)) {
            self.shadowPopupSettings.push(setting.key);
          }
        } else {
          settings[setting.key] = setting.value;
          if (setting.locked) {
            self.locks[setting.key] = true;
          }
        }
        if (setting.key === 'ui.scCallAppearance' && typeof(setting.value) === 'string') { //Dirty fix to transform sting to boolean
          setting.value = setting.value === 'none' ? true : false; 
        }
        if (setting.key === 'ui.autopop' && setting.value) {
          for (var ruleGroup in setting.value) {
            for (var i in setting.value[ruleGroup]) {
              var rule = setting.value[ruleGroup][i];
              if (/http(s)?:/.test(rule.popup)) {
                for (var p = 0; p < self.uiSettingsAutoPop.length; p++) {
                  var section = self.uiSettingsAutoPop[p];
                  var popupField = section.fields[0].reference.fields.find(function(f) { return f.name === 'popup'; });
                  if (popupField && popupField.picklist && popupField.picklist.length) {
                    if (!popupField.picklist.find(checkExistingOption)) {
                      popupField.picklist.push({
                        id: rule.popup,
                        label: '🌐 '+rule.popup
                      });
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    self.settings = settings;
    self.gUserSettings = new gUserSettings();
    self.uiSettings = settingsHelper.keyValsToSettngs(uiSettings);
    if (settingsGroup === 'ui.popup.*,ui.autopop') {
      if (!self.uiSettings.autopop) {
        if (!self.uiSettings.popup) {
          self.uiSettings.autopop = {};
        } else {
          self.uiSettings.autopop = self.uiSettings.popup;
        }
      }
      delete self.uiSettings.popup; // forget about old popup settings
      self.describe = {};
    } else if (settingsGroup === 'cti.*') {
      self.describe = {};
    } else {
      getDescribe();
    }
  }, function(errorResponse){
    self.formErrors = errorResponse.data.message || errorResponse.data;
  });

  self.save = function() {
    delete self.formErrors;
    if (self.settingsForm.$valid) {
      self.busyMask = true;
      if (settingsGroup === 'ui.popup.*, ui.autopop') {
        for (var i = 0; i < self.shadowPopupSettings.length; i++) {
          self.gUserSettings[self.shadowPopupSettings[i]] = {
            value: null
          };
        }
      }
      var uiKeyVals = settingsHelper.settingsToKeyVals(self.uiSettings);
      for (var k in uiKeyVals) {
        if (uiKeyVals[k] && (typeof(uiKeyVals[k].value) !== 'undefined')) {
          if (k === 'ui.scCallAppearance' && typeof(uiKeyVals[k].value === 'boolean')) { //Dirty fix to transform boolean to string
            uiKeyVals[k].value = uiKeyVals[k].value ? 'none' : 'notification';
          }
          self.gUserSettings[k] = {
            value: uiKeyVals[k].value,
            locked: self.locks[k.substring(3)]
          };
        }
      }
      for (var s in self.settings) {
        self.gUserSettings[s] = {
          value: self.settings[s],
          locked: self.locks[s]
        };
      }
      for (var key in self.gUserSettings) {
        if (key === 'auto.activity.sms.enabled' && self.gUserSettings[key].value && self.smsTriggerChange) {
          self.smsDisclaimer = true;
        }
      }
      if (self.smsDisclaimer) {
        var disclaimerConfirm = $mdDialog.confirm()
            .title('SMS Auto-activity disclaimer')
            .htmlContent("<p>SMS Auto logging can result in sensitive inbound and outbound messages logging to your CRM. </br> Click 'I Agree' to confirm that you still would like to turn on auto SMS logging.</p>")
            .ariaLabel('Are you sure?')
            .ok('I Agree')
            .cancel('Cancel')
            .multiple(true);
          $mdDialog.show(disclaimerConfirm).then(function () {  
            self.gUserSettings.$update(function () {
              $mdDialog.hide();
              $mdToast.showSimple("Settings saved successfully");
              if (!self.connector) { // send SYNC_SETTINGS card (update extension settings)
                self.gNotificationService.create({ title: "Vonage Integration Suite", message: "SYNC_SETTINGS" });
              }
              delete self.busyMask;
            }, function (errorResponse) {
              self.formErrors = formErrorParser(errorResponse, self.settingsForm, self.niceFields);   
              delete self.busyMask;
            });
          }, 
          function () {
            console.info("Not confirmed");
            delete self.busyMask;
          });
      }
      if (!self.smsDisclaimer) {
        self.gUserSettings.$update(function () {
          $mdDialog.hide();
          $mdToast.showSimple("Settings saved successfully");
          if (!self.connector) { // send SYNC_SETTINGS card (update extension settings)
            self.gNotificationService.create({ title: "Vonage Integration Suite", message: "SYNC_SETTINGS" });
          }
          delete self.busyMask;
        }, function (errorResponse) {
          self.formErrors = formErrorParser(errorResponse, self.settingsForm, self.niceFields);   
          delete self.busyMask;
        });
      }
    } else {
      self.settingsForm.$setDirty(true);
      var errorNames = [];
      angular.forEach(self.settingsForm.$error, function (type) {
        angular.forEach(type, function (field) {
          field.$touched = true;
          field.$setDirty();
          errorNames.push(field.$name);
        });
      });
      self.formErrors = "Please fill out the required fields: " + errorNames.join(", ") + ".";
    }
  };


  function hasSms() {
    for (var uci in gUser.ucis) {
      if (gUser.ucis[uci].ucpType === 'VBS' || /Vonage Business|VBC/.test(gUser.ucis[uci].ucpLabel)) {
        return true;
      }
    }
  }
};