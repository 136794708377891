var formErrorParser = require('../common/utils/formErrorParser');
var newUserDescribe = require('../mocks/userDescribeNew.json');
var accountUserDescribe = require('../mocks/accountUserDescribe.json');

module.exports = /*@ngInject*/ function ($scope, $rootScope, $state, $mdDialog, $mdToast, $q, gUserId, gUserPromises, UcpFactory, IntegrationFactory, AccountFactory, RoleFactory) {
  var self = this;
  self.gUserId = gUserId;
  self.busyMask = true;
  self.authorizedUser = $rootScope.gUser;

  function getAccountUcps(account) {
    self.busyMask = true;
    account.$get(function (account) {
      self.gAccountUcis = account.ucis.map(function (uci) {
        return { id: uci.ucpId, label: uci.ucpId+', '+uci.ucpLabel+' ('+uci.ucpType+')' };
      });
      self.ucps = self.gAccountUcis;
      var ucpIds = self.ucps.map(function(ucp) { return ucp.id; });
      if (ucpIds.indexOf(self.gUser.uci.ucpId) === -1) {
        self.gUser.uci.ucpId = self.ucps[0].id;
      }
      self.levelChanged();
      self.busyMask = false;
    }, function(errorResponse) {
      self.formErrors = formErrorParser(errorResponse, self.userForm, self.niceFields);
      self.busyMask = false;
    });
  }

  new IntegrationFactory().query(function (integrations) {
    self.integrations = integrations.map(function (i) {
      return { id: i.code, label: i.name };
    });
  });

  var rolesPromise = new RoleFactory().query(function (roles) {
    self.roles = roles.map(function (r) {
      return { id: r.code, label: r.name };
    });
    return self.roles;
  }).$promise;

  gUserPromises.item.then(function (user) {
    self.gUser = user;
    resolveDescribe();
    self.gAccountId = user.accountId;
    if (!self.gUserId && self.gUser.accountId && self.gUser.accountId != -1) {
      getAccountUcps(self.gUser.account);
    }
    self.busyMask = false;
    if (!(self.gUser.accountId || (self.gUser.account && self.gUser.account.id))) {
      $scope.$watch(function () {
        return self.gUser.accountId;
      }, function (account) {
        if (account) {
          new AccountFactory().get({ id: account.id }, function (account) {
            getAccountUcps(account);
          });
        } else {
          if (self.gUser.uci) {
            self.gUser.uci.ucpAuthLevel = 'OWN';
          }
          self.levelChanged();
        }
      });
    }
  });

  $q.all([rolesPromise, gUserPromises.item]).then(function () {
    self.gUserRoles = [];
    if (self.gUser.roles && self.gUser.roles.length) {
      var userRoleCodes = self.gUser.roles.map(function (ur) {
        return ur.code;
      });
      for (var r = 0; r < self.roles.length; r++) {
        if (userRoleCodes.indexOf(self.roles[r].id) !== -1) {
          self.gUserRoles.push(self.roles[r].id);
        }
      }
    }
    self.origRoles = angular.copy(self.gUserRoles);
  });

  //gUserPromises.describe.then(function (describe) {
  function resolveDescribe() {
    self.describe = self.gUser.accountId ? accountUserDescribe : newUserDescribe;
    self.niceFields = {};
    for (var f = 0; f < self.describe.sections[0].fields.length; f++) {
      var field = self.describe.sections[0].fields[f];
      self.niceFields[field.name] = self.describe.sections[0].fields[f];
    }
    self.niceFields['uci.ucpId'] = { label: 'Service Provider' };
    self.niceFields['uci.ucpUserId'] = { label: 'UCP User ID' };
    self.niceFields['uci.username'] = { label: 'UCP Username' };
    self.niceFields['uci.password'] = { label: 'UCP Password' };
  }
  //});

  if (!self.gUserId) {
    self.niceUcps = {};
    new UcpFactory().query(function (ucps) {
      self.allUcps = ucps.map(function(u) {
        return { id: u.id, label: u.id+', '+u.label+' ('+u.ucpType+')', ucpType: u.ucpType };
      });
      if (!self.gAccountId || self.gAccountId == -1) {
        self.ucps = self.allUcps;
      }
      if (self.ucps) {
        for (var i = 0; i < self.ucps.length; i++) {
          self.niceUcps[self.ucps[i].id] = self.ucps[i];
        }
      }
    });
  }

  self.ucpChanged = function (ucpId) {
    self.isVBS = self.niceUcps[ucpId] && self.niceUcps[ucpId].ucpType === 'VBS';
    self.isVCC = self.niceUcps[ucpId] && self.niceUcps[ucpId].ucpType === 'VCC';
  };

  self.levelChanged = function () {
    if (self.gUser.uci && self.gUser.uci.ucpAuthLevel === 'OWN') {
      self.ucps = self.allUcps;
    } else {
      self.ucps = self.gAccountUcis;
    }
    if (self.ucps && self.gUser.uci) {
      for (var u = 0; u < self.ucps.length; u++) {
        if (self.ucps[u].id == self.gUser.uci.ucpId) {
          return;
        }
      }
      self.gUser.uci.ucpId = self.ucps[0].id;
      self.ucpChanged(self.gUser.uci.ucpId);
    }
  };

  self.cancel = function () {
    $mdDialog.cancel('cancel');
  };

  self.disableUser = function (ev) { //enabled is the unused parameter
    var confirm = $mdDialog.confirm()
      .title('Are you sure you want to disable user ' + self.gUser.firstName + ' ' + self.gUser.lastName + '?')
      .textContent('The user will be archived after 2 days.')
      .ariaLabel('Change User Status')
      .targetEvent(ev)
      .ok('Yes')
      .cancel('No');
    $mdDialog.show(confirm).then(function () {
      self.gUser.$delete(function () {
        $state.go('sc.portal.users.item', { id: self.gUserId }, { reload: true });
      }, function () {
        //error
      });
    }, function () {
      console.info('Disable user not confirmed');
    });
  };

  self.archiveUser = function (ev) {
    var confirm = $mdDialog.confirm()
      .title('Are you sure you want to archive "' + self.gUser.firstName + ' ' + self.gUser.lastName + '"?')
      .textContent('All identities, calls and activities for this user will be deleted forever! (Do not archive users auto provisioned from 3rd party systems)')
      .ariaLabel('Archive user')
      .targetEvent(ev)
      .ok('Yes')
      .cancel('No');
    $mdDialog.show(confirm).then(function () {
      self.gUser.$delete({ force: true }, function () {
        $state.go('sc.portal.users.list', {}, { reload: true });
        $mdToast.show(
          $mdToast.simple()
            .textContent('User "' + self.gUser.firstName + ' ' + self.gUser.lastName + '" is removed from the system completely!')
            .hideDelay(5000)
        );
      }, function () {
        $mdToast.show(
          $mdToast.simple()
            .textContent('Error archiving user.')
            .hideDelay(5000)
        );
      });
    }, function () {
      console.info('Disable user not confirmed');
    });
  };

  self.save = function () {
    delete self.formErrors;
    if (self.userForm.$valid) {
      self.busyMask = true;
      if(self.gUser && self.gUser.accountId) {
        self.gUser.accountId = self.gUser.accountId.id;
      }
      if (self.gUser.uci && !self.gUser.uci.ucpId) {
        delete self.gUser.uci;
      }
      if (!self.gUserId) {
        var i;
        if (self.gUserRoles && self.gUserRoles.length) {
          self.gUser.roles = [];
          for (i = 0; i < self.gUserRoles.length; i++) {
            self.gUser.roles.push({ code: self.gUserRoles[i] });
          }
        }
        if (self.gUserIntegrations && self.gUserIntegrations.length) {
          self.gUser.integrations = [];
          for (i = 0; i < self.gUserIntegrations.length; i++) {
            self.gUser.integrations.push({ code: self.gUserIntegrations[i] });
          }
        }
      }
      self.gUser.$save(function (user) {
        if (!user.accountId) {
          user.accountId = self.gUser.accountId;
        }
        user.$get();
        $mdDialog.hide(user);
      }, function (errorResponse) {
        self.formErrors = formErrorParser(errorResponse, self.userForm, self.niceFields);
        self.busyMask = false;
      });
    } else {
      self.userForm.$setDirty(true);
      var errorNames = [];
      angular.forEach(self.userForm.$error, function (type) {
        angular.forEach(type, function (field) {
          field.$touched = true;
          field.$setDirty();
          errorNames.push(field.$name);
        });
      });
      self.formErrors = "Please fill out the required fields: " + errorNames.join(", ") + ".";
    }
  };
};