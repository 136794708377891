require('../portal.scss');
require('./layouts.scss');
require('../models/integration');
require('../models/layout');
require('../common/directives/scSectionsBuilder');

angular.module('sc.layouts', [
  'ui.router',
  'sc.model.integration',
  'sc.model.layout',
  'sc.common.sectionsBuilder'
]).config(function ($stateProvider) {
  $stateProvider.state('sc.layouts', {
    abstract: true,
    template: "<ui-view layout='column' flex></ui-view>",
    resolve: {
      portalSystem: /*@ngInject*/ function($scSystem) {
        $scSystem.portalUrl = '/portal/v1'; //= $scSystem.baseUrl.replace('/extensions/', '/portal/');
        $scSystem.portalUrlV2 = '/portal/v2'; 
        $scSystem.extensionsUrl = '/extensions/v2';
        $scSystem.baseUrl = '/portal/v1';
        return $scSystem;
      },
      VgisAccount: /*@ngInject*/ function (portalSystem, AccountFactory) {
        return new AccountFactory();
      },
    }
  }).state('sc.layouts.account', {
    url: '/accounts/:accountId/layouts/:integrationCode',
    onEnter: /*@ngInject*/ function ($portalHead, $stateParams) {
      $portalHead.title = 'Account #' + $stateParams.id;
    },
    template: require('./layouts.html'),
    controller: require('./layoutsCtrl'),
    controllerAs: 'layoutsCtrl',
    resolve: {
      gAccountId: /*@ngInject*/ function ($stateParams) {
        return $stateParams.id;
      },
      // gAccountIntegrationService: /*@ngInject*/ function ($stateParams, VgisIntegration) {
      //   return new VgisAccount({ id: $stateParams.id });
      // }
      VgisIntegration: /*@ngInject*/ function (portalSystem, IntegrationFactory) {
        return new IntegrationFactory();
      },
      VgisLayout: /*@ngInject*/ function (portalSystem, LayoutFactory) {
        return new LayoutFactory();
      },
      alert: function($mdDialog) {
        if (sessionStorage.getItem('builderAlertSeen')) {
          return true;
        }
        return $mdDialog.show({
          parent: angular.element(document.querySelector('#popupContainer')),
          template:
            '<md-dialog aria-label="List dialog" style="max-width: 45%;">' +
            '  <md-dialog-content>'+
            '  <div class="md-dialog-content">'+
            '    <h3 class="md-subhead">You are entering Custom Layouts Builder</h3>'+
            '    <p style="color: #FF8C00">&#9888; WARNING: If the Layout Builder is not set up correctly, it can result in an error when a user attempts to save their work activity in the Vonage Integration Suite (VGIS). For example, if your layout has a field that a user does not have permission to edit in the CRM, they may be unable to save their work for that activity in VGIS. We advise that you work with your CRM Administrator if you are not familiar with the roles/permissions of your users.<p>'+
            '  </div>'+
            '  </md-dialog-content>' +
            '  <md-dialog-actions layout-align="center center">' +
            '    <md-button ng-click="closeDialog()" class="md-primary">' +
            '      Got it!' +
            '    </md-button>' +
            '  </md-dialog-actions>' +
            '</md-dialog>',
            controller: /*@ngInject*/ function($scope, $mdDialog) {
              $scope.closeDialog = function() {
                sessionStorage.setItem('builderAlertSeen', true);
                $mdDialog.hide();
              };
            }
          }
        );
      }
    },
    onExit: /*@ngInject*/ function ($mdDialog) { 
      $mdDialog.cancel();
    }
  });
});