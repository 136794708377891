angular.module('sc.common.filters.timeFilter', [
  'sc'
]).filter('time', function () {
  function padTime(t) {
    return t < 10 ? "0" + t : t;
  }

  return function (_seconds) {
    if (_seconds === "--:--:--") return "--:--:--";
    if (_seconds === "--:--") return "--:--";
    if (typeof _seconds !== "number" || _seconds < 0)
      return "00:00";

    var hours = Math.floor(_seconds / 3600),
      minutes = Math.floor((_seconds % 3600) / 60),
      seconds = Math.floor(_seconds % 60);

    var timeString = hours ? padTime(hours) + ':' : '';

    return timeString + padTime(minutes) + ":" + padTime(seconds);
  };
});
