module.exports = /*@ngInject*/ function($mdDialog, field) {
  var self = this;

  self.field = field;

  self.cancel = function() {
    $mdDialog.cancel();
  };

  self.save = function() {
    $mdDialog.hide(field);
  };

  self.exclude = function() {
    $mdDialog.cancel(field);
  };
};