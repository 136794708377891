require('./scCallIcon.scss');

require('../../../icons/ic_call_received_24px.svg');
require('../../../icons/ic_call_made_24px.svg');
require('../../../icons/ic_ring_volume_24px.svg');
require('../../../icons/ic_call_received_24px.svg');
require('../../../icons/ic_pause_circle_outline_24px.svg');
require('../../../icons/ic_call_missed_outgoing_24px.svg');
require('../../../icons/ic_not_interested_24px.svg');
require('../../../icons/ic_call_missed_24px.svg');
require('../../../icons/ic_voicemail_24px.svg');
require('../../../icons/ic_sms_24px.svg');
require('../../../icons/ic_event_note_24px.svg');

var ICONS = {
  //calls answered
  'inbound_answered':   'icons/ic_call_received_24px.svg',
  'outbound_answered':  'icons/ic_call_made_24px.svg',
  //calls not yet active 
  'inbound_ringing':    'icons/ic_ring_volume_24px.svg',
  'outbound_ringing':   'icons/ic_call_made_24px.svg',
  'outbound_initializing': 'icons/ic_call_made_24px.svg',
  //calls active
  'inbound_active':     'icons/ic_call_received_24px.svg',
  'outbound_active':    'icons/ic_call_made_24px.svg',
  'outbound_held':   'icons/ic_pause_circle_outline_24px.svg',
  'inbound_held':   'icons/ic_pause_circle_outline_24px.svg',
  'outbound_remote_held':   'icons/ic_call_made_24px.svg',
  'inbound_remote_held':   'icons/ic_call_received_24px.svg',
  //calls missed
  'outbound_cancelled':  'icons/ic_call_missed_outgoing_24px.svg',
  'inbound_rejected':  'icons/ic_not_interested_24px.svg',
  'inbound_missed':     'icons/ic_call_missed_24px.svg',
  'outbound_missed':  'icons/ic_call_made_24px.svg',
  'outbound_rejected':  'icons/ic_not_interested_24px.svg',

  // sms
  'outbound_sent':      'icons/ic_pause_circle_outline_24px.svg',
  'outbound_failed':    'icons/ic_not_interested_24px.svg',
  'outbound_delivered': 'icons/ic_call_made_24px.svg',
  'inbound_delivered':  'icons/ic_call_received_24px.svg'
};

angular.module("sc.common.callIcon", [
])
.directive("scCallIcon", function() {
  return {
    restrict: "E",
    scope: {
      call: '='
    },
    template: require('./scCallIcon.html'),
    link: function(scope) {
      scope.imageUrl = function() {
        if (scope.call) {
          if (scope.call.type == 'CALL') {
            if (scope.call.state) scope.call.disposition = scope.call.state.toLowerCase();
            return ICONS[(scope.call.direction || 'outbound').toLowerCase()+'_'+scope.call.disposition];
          } else if (scope.call.type == 'VOICEMAIL') {
            return 'icons/ic_voicemail_24px.svg';
          } else if (scope.call.type == 'SMS') {
            // var smsDisposition = scope.call.state === 'FAILED' ? 'rejected' : 'missed';
            return ICONS[(scope.call.direction || 'outbound').toLowerCase()+'_'+(scope.call.state || 'answered').toLowerCase()];
          } else if (scope.call.type == 'MEETING') {
            return 'icons/ic_event_note_24px.svg';
          }
        }
      };
    }
  };
});