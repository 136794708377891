require('./account');

angular.module('sc.model.accountSettings', [
  'sc',
  'ngResource',
  'sc.model.account'
])
.factory('AccountSettingsFactory', /*@ngInject*/ function($injector, $resource, $scSystem) {
  var AccountSettingsResource = function (attributes) {
    var self = this;
    if (!attributes) {
      attributes = {};
    }
    if (attributes.account) {
      self.account = attributes.account;
      attributes.accountId = attributes.account.accountId;
      attributes.accountId = attributes.account.id;
    }
    var AccountSettings = $resource($scSystem.portalUrl+'/accounts/:accountId/:integrationsPath/:connector/settings', {
      accountId: function(that) {
        return that ? that.accountId || attributes.accountId || -1 : attributes.accountId || -1;
      },
      connector: function(that) {
        return that ? that.connector || attributes.connector : attributes.connector;
      },
      integrationsPath: function(that) {
        return ((that && that.connector) || (attributes && attributes.connector)) ? 'integrations' : null;
      }
    }, {
      update: {
        method: 'PATCH',
        transformRequest: function (data) {
          var trData = {};
          for (var property in data) {
            if (!/^\$/.test(property) && property !== 'toJSON' && typeof(data[property]) !== 'undefined') {
              if (data[property] && typeof(data[property].value) !== 'undefined') {
                trData[property] = data[property];
              } else {
                trData[property] = { value: data[property] };
              }
            }
          }
          return angular.toJson(trData);
        }
      },
      get: {
        method: 'GET',
        transformResponse: function(data) {
          var settings = angular.fromJson(data);
          var trData = {};
          for (var property in settings) {
            if (!/^\$/.test(property) && property !== 'toJSON') {
              trData[property] = settings[property];
            }
          }
          return trData;
        }
      },
      delete: {
        method: 'DELETE',
        // isArray: true         //BE sending response which is wrong.   
      },
      describe: {
        method: 'GET',
        url: $scSystem.portalUrl + '/accounts/:accountId/:integrationsPath/:connector/settings/metadata' 
      }
    });

    // Object.defineProperty(AccountSettings.prototype, '_getId', { 
    //   value: 'settings',
    //   enumerable: false
    // });

    return AccountSettings;
  };

  return AccountSettingsResource;
});