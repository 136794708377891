require('./user');

angular.module('sc.model.userNotifications', [
  'sc',
  'ngResource',
  'sc.model.user'
])
.factory('UserNotificationsFactory', /*@ngInject*/ function($injector, $resource, $scSystem) {
  var UserNotificationsResource = function (attributes) {
    var self = this;
    if (!attributes) {
      attributes = {};
    }
    if (attributes.user) {
      self.user = attributes.user;
      attributes.accountId = attributes.user.accountId;
      attributes.userId = attributes.user.id;
    }
    var baseRoute = $scSystem.portalUrl+'/accounts/:accountId/:usersPath/:userId/notify';
    
    var UserNotifications = $resource(baseRoute + '/:type', {
      accountId: function(that) {
        return that ? that.accountId || attributes.accountId || -1 : attributes.accountId || -1;
      },
      usersPath: function (that) {
        return ((that && that.userId) || attributes.userId) ? 'users' : null;
      },
      userId: function(that) {
        return that ? that.userId || attributes.userId : attributes.userId;
      },
      type: function(that) {
        return that ? that.type || attributes.type || '' : attributes.type;
      }
    }, {
      create: {
        method: 'POST'
      },
      getHealth: {
        method: 'GET',
        url: baseRoute + '/health'
      }
    });

    return UserNotifications;
  };

  return UserNotificationsResource;
});