require('./account.scss');

var VirtualRepeatLoader = require('../../gcf/sc/common/virtualRepeatRestfulLoader');

module.exports = /*@ngInject*/ function ($scope, $rootScope, $window, $state, $mdDialog, $mdToast, gAccountId, gAccountService, IntegrationFactory, VgisRoles) {
  var self = this;
  self.userSelection = {};
  self.gAccountService = gAccountService;

  if (!$rootScope.scPermissions.has('_VIEW', 'ACCOUNT', gAccountId)) {
    self.forbidden = true;
    self.error = 'Missing permission to see account #'+gAccountId;
    return;
  }

  var IntegrationModel = new IntegrationFactory({accountId: gAccountId});

  function getAccountIntegrations() {
    IntegrationModel.getIntegrationsList(function (integrations) {
      self.integrations = integrations;
      self.busyMask = false;
    });  
  }
  
  function getSyncStatus(resources) {
    gAccountService.sync.get({ resources: resources }, function(syncResponse) {
      var statuses = {};
      var lastSync;
      for (var i in syncResponse.integrations) {
        var integration = syncResponse.integrations[i];
        if (!statuses[syncResponse.integrations[i].status]) {
          statuses[syncResponse.integrations[i].status] = 1;
        } else {
          statuses[syncResponse.integrations[i].status] += 1;
        }
        for (var o in integration.objectTypes) {
          var createdAt =  integration.objectTypes[o].createdDate;
          if (createdAt && integration.objectTypes[o].status !== 'NEVER' && (!lastSync || lastSync.getTime() < createdAt)) {
            lastSync = new Date(createdAt);
          }
        }
      }
      var aggStatuses = [];
      for (var s in statuses) {
        aggStatuses.push(s+':'+statuses[s]);
      }
      self.syncStatus = {
        status: aggStatuses.join(', '),
        lastSync: lastSync
      };
    }, function(syncError) {
      self.syncError = syncError.data ? syncError.data.message || 'Unable to get sync status' : 'Unable to get sync status';
    });
  }

  self.usersFilters = {
    statuses: ['ACTIVE', 'PENDING']
  };
  self.refreshUsers = function() {
    if (self.dynamicUsers && self.dynamicUsers.loaded) {
      if (!self.prevUserFilters) {
        self.prevUserFilters = {};
      }
      if (!angular.equals(self.prevUserFilters, self.usersFilters)) {
        self.prevUserFilters = angular.copy(self.usersFilters);
        self.dynamicUsers.refresh();
      }
    }
  };
  function onUsersLoaded() {
    var visibleUserIds = self.dynamicUsers.getVisibleItems().map(function(u) { return u.id; });
    setTimeout(function() {
      var selectionCount = 0;
      var selectionKeys = Object.keys(self.userSelection);
      for (var s = selectionKeys.length - 1; s >= 0; s--) {
        if (visibleUserIds.indexOf(selectionKeys[s]) !== -1) {
          selectionCount ++;
        } else {
          delete self.userSelection[selectionKeys[s]];
        }
      }
      self.userSelectionCount = selectionCount;
      if (!$scope.$$phase) {
        $scope.$apply();
      }
    }, 0);
  }

  function getAccount() {
    gAccountService.$get(function (account) {
      self.gAccount = account;
      (account.integrations || []).forEach(function(i) {
        i.id = i.code;
        i.label = i.name;
      });
      VgisRoles.query(function(roles) {
        self.roles = roles.map(function (r) {
          return { name: r.code, label: r.name + ' (' + r.code +')' };
        });
      });
      self.dynamicUsers = new VirtualRepeatLoader(account.users, {
        idKey: 'id',
        filters: function () {
          var query = {};
          if (self.usersFilters.q) {
            query.q = self.usersFilters.q;
          }
          if (self.usersFilters.integrationCodes) {
            query.integrationCodes = self.usersFilters.integrationCodes.join(',');
          }
          if (self.usersFilters.statuses) {
            query.statuses = self.usersFilters.statuses.join(',');
          }
          if (self.usersFilters.roles) {
            query.roles = self.usersFilters.roles.join(',');
          }
          return query;
        },
        callback: onUsersLoaded
      });
      self.dynamicUsers.callback = onUsersLoaded;
      setTimeout(function () {
        angular.element($window).triggerHandler('resize');
      });
    }, function(err) {
      self.error = err.data.message || err.data.exception || err.data;
    });
    getAccountIntegrations();
    getSyncStatus('contacts');
  }
  getAccount();

  self.onUpdate = function (error, account) {
    if (!error && account) {
      self.gAccount = account;
    }
  };

  self.onUciUpdate = function () {
    getAccount();
  };

  self.onUserCreate = function (err, user) {
    if (user) {
      $state.go('sc.portal.users.item', { accountId: user.accountId, id: user.id });
    }
  };

  $scope.allowDropUser = function(ev) {
    ev.preventDefault();
  };

  self.calcDeleteDate = function() {
    if (!self.gAccount.deletedDate) {
      return 'N/A';
    }
    var deletedDate = new Date(self.gAccount.deletedDate);
    // deletedDate.setDate(deletedDate.getDate()+4);
    return deletedDate;
  };

  self.enableAccount = function(ev) {
    var confirm = $mdDialog.confirm()
    .title('Are you sure?')
    .textContent('Enable account '+self.gAccount.name)
    .ariaLabel('Change Account Status')
    .targetEvent(ev)
    .ok('Yes')
    .cancel('No');
    $mdDialog.show(confirm).then(function() {
      self.gAccount.$setEnabled(function() {
        //error can be handled here
      }, function() {
        self.dynamicUsers.refresh();
      });
    }, function() {
      if(window.scDebug) {
        console.info('Enable account not confirmed');
      }
    });
  };

  $scope.dropUser = function(ev) {
    ev.preventDefault();
    self.onBulkImport([JSON.parse(ev.dataTransfer.getData("text"))]);
  };

  self.onBulkImport = function(users) {
    $mdDialog.show({
      controller: require('../import/importUsersDlgCtrl'),
      controllerAs: 'importUsersDlgCtrl',
      template: require('../import/importUsersDlg.html'),
      parent: angular.element(document.querySelector('#account_view')),
      preserveScope: true,
      locals: {
        gAccount: self.gAccount,
        gUserPromises: {
          // describe: self.gAccount.users.describe().$promise
        },
        importUsers: users,
        onUserImported: function(importedUser) {
          self.gAccount.users.get({ id: importedUser.id }, function(user) {
            self.dynamicUsers.inject(user);
          }, angular.noop);
        }
      }
    }).then(function () {
      //result can be shown
    }, function() {
      //error can be handled here
    });
  };

  // self.syncContacts = function() {
  //   $mdDialog.show({
  //     controller: require('./syncContacts/syncContactsCtrl'),
  //     controllerAs: 'syncContactsCtrl',
  //     template: require('./syncContacts/syncContacts.html'),
  //     parent: angular.element(document.querySelector('#account_view')),
  //     preserveScope: true,
  //     locals: {
  //       gAccount: self.gAccount,
  //       gAccountService: gAccountService
  //     }
  //   }).then(function (/* importSuccess */) {
  //     // getSyncStatus('contacts');
  //   }, function() {
  //     //error can be handled here
  //   });
  // };

  self.toggleUserSelection = function(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    setTimeout(function() {
      self.userSelectionCount = 0;
      for (var s in self.userSelection) {
        if (self.userSelection[s]) self.userSelectionCount++;
      }
      if (!$scope.$$phase) {
        $scope.$apply();
      }
    }, 0);
    return false;
  };

  self.clearUserSelection = function() {
    self.userSelection = {};
    self.userSelectionCount = 0;
  };

  self.allUserSelection = function() {
    self.clearUserSelection();
    var visibleUserIds = self.dynamicUsers.getVisibleItems().map(function(u) { return u.id; });
    for (var k in visibleUserIds) {
      self.userSelection[visibleUserIds[k]] = true;
      self.userSelectionCount++;
    }
  };

  self.inverseUserSelection = function() {
    var selectionCount = 0;
    var visibleUserIds = self.dynamicUsers.getVisibleItems().map(function(u) { return u.id; });
    for (var k = 0; k < visibleUserIds.length; k++) {
      self.userSelection[visibleUserIds[k]] = !self.userSelection[visibleUserIds[k]];
      if (self.userSelection[visibleUserIds[k]]) {
        selectionCount++;
      }
    }
    self.userSelectionCount = selectionCount;
  };

  self.invokeAuthorization = function(ev, integration) {
    self.gAccount.$getAuthorizeUrl(integration.code, function(authorizeUrl, userId) {
      var confirm = $mdDialog.confirm()
      .title(integration.code +' API User - ' + userId)
      .textContent('API user successfully created for '+ integration.name+  ' integration, but setup is not complete. Go to user '+ userId+ ' and Link the ' + integration.name + ' identity to the user.')
      .targetEvent(ev)
      .ok('Link Now')
      .cancel('Link later');
      $mdDialog.show(confirm).then(function() {
        authorizeUrl += ((authorizeUrl.indexOf('?') === -1 ? '?' : '&')) + "apiUser=true";
        var authWindow = window.open(
          authorizeUrl, 'vgis3_auth', 
          'width=680,height=680,left=' + ((screen.width / 2) - 340) + ',top=' + ((screen.height / 2) - 340)
        );
        var authWatchdog;
        if (authWindow) {
          authWindow.moveTo((screen.width / 2) - 340, (screen.height / 2) - 340);
          authWatchdog = window.setInterval(function(){
            if (authWindow.closed) {
              window.clearInterval(authWatchdog);
            }
          }, 1000);
          self.dynamicUsers.refresh();
          $mdToast.showSimple('API user (# '+userId+') has been created. Please find in your account users. You can now link a API profile.'); 
        }
      }, function() {
        self.dynamicUsers.refresh();
        $mdToast.showSimple('API user (# '+userId+') has been created. Please find in your account users.');
        if(window.scDebug) {
          console.info('Enable account not confirmed');
        }
      });
    }, function(err) {
      console.error('getAuthState error', err);
    });
  };
};