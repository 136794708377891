var formErrorParser = require('../../common/utils/formErrorParser');
module.exports = /*@ngInject*/ function ($scope, $portalHead, $mdDialog, gUser, logoForIntegration, IntegrationFactory) {
  var self = this;
  self.gUser = gUser;
  self.logoForIntegration = logoForIntegration;
  self.busyMask = true;
  self.integrationsMap = {};
  new IntegrationFactory().query(function (integrations) {
    var integrationCodes = (gUser.integrations || []).map(function(i) {
      return i.code;
    });
    self.integrations = integrations;
    for (var i = 0; i < integrations.length; i++) {
      var code = integrations[i].code;
      var userIntegration = (gUser.integrations || []).find(function(ui) { return ui.code === code; });
      self.integrationsMap[code] = { code: code, isEnabled: integrationCodes.indexOf(code) !== -1, isFullIntegration: userIntegration ? userIntegration.isFullIntegration : true };
    }
    self.busyMask = false;
  });

  self.cancel = function () {
    $mdDialog.cancel('cancel');
  };

  self.save = function() {
    self.busyMask = true;
    self.gUser.$setIntegrations(Object.values(self.integrationsMap), function(user) {
      $mdDialog.cancel(user);
    }, function (errorResponse) {
      self.formErrors = formErrorParser(errorResponse, self.integrationsForm);
      self.busyMask = false;
    });
  };

  self.isFullIntegrationClicked = function(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    return false;
  }
};