module.exports = function (report) {
  function generateData() {
    var inboundCount = 0;
    var inboundDuration = 0;
    var outboundCount = 0;
    var outboundDuration = 0;
    if (report && report.accountRecords && report.accountRecords.length) {
      for (var a = 0; a < report.accountRecords.length; a++) {
        var accountRecord = report.accountRecords[a];
        if (accountRecord.userRecords && accountRecord.userRecords.length) {
          for (var r = 0; r < accountRecord.userRecords.length; r++) {
            var u = accountRecord.userRecords[r];
            if (u.records && u.records.length) {
              for (var i = 0; i < u.records.length; i++) {
                var row = u.records[i];
                if (row.direction === 'INBOUND') {
                  inboundCount += row.totalCalls;
                  inboundDuration += row.totalDuration;
                } else {
                  outboundCount += row.totalCalls;
                  outboundDuration += row.totalDuration;
                }
              }
            }
          }
        }
      }
    }
    var d = {
      count: [
        { key: 'Inbound', y: inboundCount },
        { key: 'Outbound', y: outboundCount }
      ],
      duration: [
        { key: 'Inbound', y: inboundDuration },
        { key: 'Outbound', y: outboundDuration }
      ]
    };
    //console.log(d);
    return d;
  }

  var data = generateData();

  return {
    totalCount: data.count,
    totalDuration: data.duration
  };
};