var helpGuide = require('../../../helpGuide.json');
require('../../../icons/ic_help_outline_24px.svg');
require('./scHelpContent');
require('./scHelp.scss');

angular.module("sc.common.help", [
  'sc',
  'sc.common.help.content'
])
  .directive("scHelp", function ($mdDialog, Analytics) {

    return {
      restrict: "E",
      scope: {
        key: '@',
        integrationCode: '@',
        resourceTypeLabel: '@'
      },
      template: require('./scHelp.html'),
      link: function (scope) {
        scope.showHelp = function () {
          var helpContent = helpGuide[scope.key];
          if (helpContent) {
            $mdDialog.show({
              template: require('./help.html'),
              controller: /*@ngInject*/ function($mdDialog) {
                this.content = getContent();
                this.cancel = function() {
                  $mdDialog.hide();
                };
              },
              controllerAs: 'helpCtrl',
              parent: angular.element(document.body),
              clickOutsideToClose: true
            });
          }
          Analytics.trackEvent('help', 'click', scope.key);
        };
        function getContent() {
          if (scope.key === 'common_resource') {
            var tmpContent = angular.copy(helpGuide[scope.key]);
            tmpContent.head = tmpContent.head.replace(/RESOURCE_LABEL/g, capitalize(scope.integrationCode) + ' ' + scope.resourceTypeLabel);
            for (var idx in tmpContent.body) {
              tmpContent.body[idx] = tmpContent.body[idx].replace(/RESOURCE_LABEL/g, scope.resourceTypeLabel);
            }
            tmpContent.foot = tmpContent.foot.replace(/RESOURCE_LABEL/g, scope.resourceTypeLabel);
            return tmpContent;
          }
          return helpGuide[scope.key];
        }
        function capitalize(str) {
          if (str && str.length > 1) {
            return str.charAt(0).toUpperCase() + str.slice(1);
          } else if (str && str.length === 1) {
            return str.charAt(0).toUpperCase();
          } else {
            return str;
          }
        }
      }
    };
  });
