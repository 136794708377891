require('./user');
require('./accountUci');
require('./accountSettings');
require('./accountSyncResources');

angular.module('sc.model.account', [
  'sc',
  'ngResource',
  'sc.model.user',
  'sc.model.accountUci',
  'sc.model.accountSettings',
  'sc.model.accountSyncResources'
])
.factory('AccountFactory', /*@ngInject*/ function($injector, $resource, $scSystem, AccountUciFactory) {
  var AccountResource = function (/* attributes */) {

    function decorateAccount(account) {
      if (account.integrations) {
        account.integrationNames = account.integrations.map(function (i) { return i.name; }).join(', ');
      }
      if (account.ucis) {
        account.ucpNames = account.ucis.map(function (u) { return u.ucpLabel; }).join(', ');
      }
    }

    function transformAccountResponse(data, headersGetter, status) {
      var jsonData = angular.fromJson(data);
      if ([200, 201].indexOf(status) !== -1) {
        if (jsonData.ucis) {
          var AccountUciService = new AccountUciFactory({ account: jsonData });
          jsonData.ucis = jsonData.ucis.map(function(uci) {
            return new AccountUciService(uci);
          });
        }
        decorateAccount(jsonData);
      }
      return jsonData;
    }

    var Account = $resource($scSystem.portalUrl+'/accounts/:id', {
      id: '@id'
    }, {
      query: {
        method: 'GET',
        isArray: true,
        transformResponse: function (data, headersGetter, status) {
          var response = angular.fromJson(data);
          if (status === 200) {
            for (var i = 0; i < response.length; i++) {
              decorateAccount(response[i]);
            }
          }
          return response;
        }
      },
      get: {
        transformResponse: transformAccountResponse
      },
      count: {
        method: 'GET',
        url: $scSystem.portalUrl + '/accounts/count'
      },
      create: {
        method: 'POST',
        transformResponse: transformAccountResponse
      },
      update: {
        method: 'PUT',
        transformResponse: transformAccountResponse
      },
      describe: {
        method: 'GET',
        url: $scSystem.portalUrl + '/accounts/:id/describe'
      }
    });

    Account.prototype.$save = function(success, error) {
      return (this.id) ? this.$update(success, error) : this.$create(success, error);
    };

    Account.prototype.$setEnabled = function (isEnabled, successCallback, errorCallback) {
      var that = this;
      $resource($scSystem.portalUrl + '/accounts/' + this.id + '/enable', {}, {
        patch: {
          method: 'PATCH'
        }
      }).patch({}, function(a) {
        that.status = a.status;
        successCallback(that);
      }, errorCallback);
    };

    // Account.prototype.integrationNames = function() {
    //   return (this.integrations || []).map(function(i) { return i.name; }).join(', ');
    // };
    // Account.prototype.ucpNames = function() {
    //   return (this.ucis || []).map(function(u) { return u.ucpLabel; }).join(', ');
    // };
    Account.prototype.$getAuthorizeUrl = function (integrationCode, successCallback, errorCallback) {
      $resource(
        $scSystem.portalUrl + '/accounts/' + this.id +'/integrations/'+ integrationCode.toLowerCase() + '/state'
      ).save({}, function (authsState) {
        var appsDomain = scDevEnv ? 'https://apps.gunify-'+scVgisEnv+'.vocal-'+(scVgisEnv === 'dev' ? 'dev' : 'qa')+'.com' : 'https://apps.gunify.vonage.com';
        successCallback(appsDomain + '/integrations/' + integrationCode.toLowerCase() + '/authorize?state=' + encodeURIComponent(authsState.state), authsState.userId);
      }, errorCallback);
    };

    Account.prototype.$setIntegrations = function (data, successCallback, errorCallback) {
      $resource($scSystem.portalUrl + '/accounts/' + this.id + '/integrations', {}, {
        patch: {
          method: 'PUT',
          isArray: true
        },
      }).patch(data, successCallback, errorCallback);
    };

    Object.defineProperty(Account.prototype, 'users', { 
      get: function() {
        return new $injector.get('UserFactory')({ accountId: this.id, account: this });
      }
    });

    Object.defineProperty(Account.prototype, 'isDisabled', {
      get: function() {
        return this.status === 'INACTIVE';
      }
    });

    Object.defineProperty(Account.prototype, 'sync', { 
      get: function() {
        return new $injector.get('AccountSyncResourcesFactory')({ accountId: this.id, account: this });
      }
    });

    // Object.defineProperty(Account.prototype, 'ucis', { 
    //   get: function() {
    //     return new $injector.get('AccountUciFactory')({ accountId: this.id, account: this });
    //   },
    //   set: function(items) {}
    // });

    return Account;
  };

  return AccountResource;
});