require('./integrationResourceDlg.scss');
var formMeta = [
  {
    label: "Resource configuration",
    fields: [
      {
        name: 'resourceType',
        label: 'System Name',
        type: 'string',
        validations: {
          required: true,
          readonly: true
        },
        disabled: true,
        readonly: true
      },
      {
        name: 'label',
        label: 'Display Name',
        type: 'string',
        validations: {
          required: true
        }
      },
      {
        name: 'templateFields',
        label: 'Template fields',
        type: 'picklist',
        multiple: true,
        picklist: [],
        helpText: 'Nominated fields for variable interpolation'
      },
      {
        name: 'extraFields',
        label: 'Extra fields',
        type: 'picklist',
        multiple: true,
        picklist: [],
        helpText: 'Additional fields for sync & visualization'
      },
      {
        name: 'isContactable',
        label: 'Act as Contact',
        type: 'boolean'
      },
      {
        name: 'contactFieldMapping',
        visible: 'isContactable',
        type: 'composite',
        subtype: 'column',
        valueType: 'object',
        fields: [
          {
            name: 'nameFields',
            type: 'composite',
            label: 'Name fields map',
            fields: [
              {
                name: 'firstName',
                label: 'First Name field map',
                type: 'picklist',
                picklist: []
              },
              {
                name: 'middleName',
                label: 'Middle Name field map',
                type: 'picklist',
                picklist: []
              },
              {
                name: 'lastName',
                label: 'Last Name field map',
                type: 'picklist',
                picklist: []
              }
            ]
          },
          {
            name: 'name',
            label: 'Display Name field map',
            type: 'picklist',
            picklist: [],
            helpText: '* Either First and Last names or a single Display Name map is required',
          },
          {
            name: 'phoneFields',
            type: 'list',
            unique: true,
            label: 'Phone fields map',
            validations: {
              required: true
            },
            reference: {
              name: 'phones',
              type: 'composite',
              fields: [
                {
                  name: 'field',
                  label: 'Phone field',
                  type: 'picklist',
                  picklist: [],
                  validations: {
                    required: true
                  }
                },
                {
                  name: 'phoneType',
                  label: 'Type',
                  type: 'combobox',
                  picklist: [
                    { id: 'WORK', label: 'Work' },
                    { id: 'HOME', label: 'Home' },
                    { id: 'MOBILE', label: 'Mobile' },
                    { id: 'OTHER', label: 'Other' }
                  ]
                }
              ]
            }
          },
          {
            name: 'emailFields',
            type: 'list',
            label: 'Email fields map',
            unique: true,
            reference: {
              name: 'emails',
              type: 'composite',
              fields: [
                {
                  name: 'field',
                  label: 'Email field',
                  type: 'picklist',
                  picklist: [],
                  validations: {
                    required: true
                  }
                },
                {
                  name: 'emailType',
                  label: 'Type',
                  type: 'combobox',
                  picklist: [
                    { id: 'WORK', label: 'Work' },
                    { id: 'HOME', label: 'Home' },
                    { id: 'OTHER', label: 'Other' }
                  ]
                }
              ]
            }
          }
        ]
      }
    ]
  }
];

var formErrorParser = require('../../common/utils/formErrorParser');
module.exports = /*@ngInject*/ function ($mdDialog, gAccount, integrationCode, logoForIntegration, resource, LayoutFactory) {
  var self = this;
  self.gAccount = gAccount;
  self.integrationCode = integrationCode;
  self.busyMask = true;
  self.integrationsMap = {};
  self.integrationIcon = logoForIntegration(integrationCode);
  // self.resource = resource;
  self.sections = formMeta;

  // self.sections[0].fields[2].fields[0].picklist = [{ id: 'firstName', label: 'First name (firstName)'}];
  // self.sections[0].fields[2].fields[1].reference.picklist = [
  //   { id: 'phone', label: 'Phone' }, 
  //   { id: 'homePhone', label: 'Home Phone (homePhone)' }
  // ];
  resource.$get().then(function(result) {
    if (result.contactFieldMapping) {
      delete result.contactFieldMapping.fields;
      if (result.contactFieldMapping.phoneFields instanceof Array) {
        result.contactFieldMapping.phoneFields = result.contactFieldMapping.phoneFields.filter(function(f) { return !!f; }).map(function(pf) {
          return typeof(pf) === 'object' ? pf : { field: pf };
        });
      }
      if (result.contactFieldMapping.emailFields instanceof Array) {
        result.contactFieldMapping.emailFields = result.contactFieldMapping.emailFields.filter(function(f) { return !!f; }).map(function(ef) {
          return typeof(ef) === 'object' ? ef : { field: ef };
        });
      }
    }
    self.resource = angular.copy(result);
    self.busyMask = false;
  });

  self.resourceLayoutModel = new LayoutFactory();

  self.loadResourceMetadata = function() {
    delete self.resourcesMap;
    self.busyMask = true;
    self.resourceLayoutModel.get({ accountId: self.gAccount.id, integrationCode: self.integrationCode, resourceType: resource.resourceType, id: 'system' }, function (meta) {
      self.busyMask = false;
      // console.log('meta', meta);
      var stringFields = [];
      var phoneFields = [];
      var emailFields = [];
      var extraFields = [];
      function loopFields(fields) {
        if (!fields || !fields.length) {
          return;
        }
        fields.forEach(function(f) {
          if (f.type === 'composite') {
            loopFields(f.fields);
          } else {
            extraFields.push({ id: f.name, label: f.label + ' (' + f.name + ')' });
            if (f.type === 'phone') {
              phoneFields.push({ id: f.name, label: f.label + ' (' + f.name + ')' });
            } else if (['email', 'address'].indexOf(f.type) !== -1) {
              emailFields.push({ id: f.name, label: f.label + ' (' + f.name + ')' });
            } else if (f.type === 'string' || f.type === 'textarea' || f.type === 'combobox' || !f.type) {
              stringFields.push({ id: f.name, label: f.label + ' (' + f.name + ')' });
            } else if (
              f.type === 'list' && f.reference && f.reference.type === 'composite' && f.reference.fields.length
            ) { // office365 dirty hack (VGIS-7687)
              if (['string', 'textarea', undefined].indexOf(f.reference.fields[0].type) !== -1) {
                stringFields.push({ id: f.name, label: f.label + ' (' + f.name + ')' });
              } else if (f.reference.fields[0].type === 'phone') {
                phoneFields.push({ id: f.name, label: f.label + ' (' + f.name + ')' });
              } else if (['email', 'address'].indexOf(f.reference.fields[0].type) !== -1) {
                emailFields.push({ id: f.name, label: f.label + ' (' + f.name + ')' });
              }
            }
          }
        });
      }
      (meta.layout || meta).sections.forEach(function(s) {
        // console.log('s', s);
        loopFields(s.fields);
      });
      // console.log({ phones: phoneFields, emails: emailFields, strings: stringFields });
      formMeta[0].fields[2].picklist = stringFields // templateFields
      formMeta[0].fields[3].picklist = extraFields; // extraFields
      formMeta[0].fields[5].fields[0].fields[0].picklist = stringFields; // first name
      formMeta[0].fields[5].fields[0].fields[1].picklist = stringFields; // middle name
      formMeta[0].fields[5].fields[0].fields[2].picklist = stringFields; // last name
      formMeta[0].fields[5].fields[1].picklist = stringFields; // display name
      formMeta[0].fields[5].fields[2].reference.fields[0].picklist = phoneFields.concat(stringFields); // phones
      formMeta[0].fields[5].fields[3].reference.fields[0].picklist = emailFields.concat(stringFields); // emails
      formMeta[0].fields[5].fields[4].picklist = extraFields; // extraFields
    }, function (errorResponse) {
      self.formErrors = formErrorParser(errorResponse, self.integrationsForm);
      self.busyMask = false;
    });
  };
  self.loadResourceMetadata();

  self.save = function() {
    delete self.formErrors;
    if (self.resourceForm.$valid) {
      self.busyMask = true;
      self.resource.$put().then(function(savedResource) {
        $mdDialog.hide(savedResource);
      }, function(error) {
        console.error(error);
      }).finally(function() {
        self.busyMask = false;
      });
    } else {
      self.resourceForm.$setDirty(true);
      var errorNames = [];
      angular.forEach(self.resourceForm.$error, function (type) {
        angular.forEach(type, function (field) {
          field.$touched = true;
          field.$setDirty();
          errorNames.push(field.$name);
        });
      });
      self.formErrors = "Please fill out the required fields: " + errorNames.join(", ") + ".";
    }
  };

  self.cancel = function () {
    $mdDialog.cancel('cancel');
  };

};