module.exports = /*@ngInject*/ function($mdDialog, auth) {
  var self = this;
  self.authCode = auth.name;
  self.sections = auth.customAuth.metadata.sections;
  self.settings = auth.customAuth.get();

  self.isSet = function() {
    return auth.customAuth.isSet();
  };

  self.clear = function() {
    auth.customAuth.clear();
    $mdDialog.hide();
  };

  self.cancel = function() {
    $mdDialog.cancel();
  };

  self.save = function() {
    auth.customAuth.set(self.settings);
    $mdDialog.hide();
  };

};