module.exports = function (response, form, fields) {
  response = response.data || {};
  if (!response.errorMessage && !response.message && !response.errorMsg) {
    return;
  }
  try {
    var errors = {};
    var message = (response.errorMessage || response.message || response.errorMsg) + ' (' + (response.errorCode || response.code) + ')';
    if (response.errors) {
      if (!response.errors.length) {
        errors[''] = message;
      } else {
        response.errors.forEach(function (f) {
          var fName = fields[f.field] ? fields[f.field].label : f.field || f;
          errors[fName] = f.message;
          if (form[fName]) {
            if (!form[fName].$customError) {
              form[fName].$customError = '';
            }
            form[fName].$setValidity("custom", false);
            form[fName].$customError = form[fName].$customError + f.message + '. ';
            form[fName].$$element[0].focus();
          }
        });
      }
    }
    return { message: message, errors: errors };
  } catch (err) {
    return err; 
  }
};