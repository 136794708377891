module.exports = function (report, el) {
  var self = this;

  self.options = {
    chart: {
      type: 'multiChart',
      height: el ? el.innerHeight : 200,
      margin: {
        top: 50,
        right: 70,
        bottom: 40,
        left: 45
      },
      color: ['#FA7554', '#871FFF', '#FFA68C', '#DCB6E9'],
      showValues: true,
      transitionDuration: 500,
      xAxis: {
        tickFormat: function (d) {
          var date = new Date(d);
          return d3.time.format('%m/%d %Hh')(date);
        }
      },
      yAxis1: {
        tickFormat: function (d) {
          return d3.format(',f')(d);
        }
      },
      yAxis2: {
        tickFormat: function (d) {
          var s = d/1000;
          if (s < 60) {
            return d3.format(',f')(s) + ' sec';
          } else if (s < 3600) {
            return d3.format(',.1f')(s / 60) + ' min';
          } else return d3.format(',.1f')(s / 3600) + ' hrs';
        }
      }
    }
  };
  
  // function generateInOut(user) {
  //   var inbound = 0;
  //   var outbound = 0;
  //   for (var i = 0; i < user.records.length; i++) {
  //     var r = user.records[i];
  //     if (r.direction === 'INBOUND') {
  //       inbound += r.totalCalls;
  //     } else {
  //       outbound += r.totalCalls;
  //     }
  //   }
  //   return {
  //     inbound: inbound,
  //     outbound: outbound
  //   };
  // }

  // function compareIntervalAt(a,b) {
  //   if (a.intervalAt < b.intervalAt)
  //     return -1;
  //   if (a.intervalAt > b.intervalAt)
  //     return 1;
  //   return 0;
  // }

  function generateData() {
    var hData = { inC: {}, outC: {}, inD: {}, outD: {} };
    var data = [];

    if (report.query.fromDate && report.query.toDate) {
      var step = 1000 * 60 * 60;
      if (report.query.interval == 'DAILY') {
        step = 1000 * 60 * 60 * 24;
      }
      if (report.query.interval == 'WEEKLY') {
        step = 1000 * 60 * 60 * 24 * 7;
      }
      if (report.query.interval == 'MONTHLY') {
        step = 1000 * 60 * 60 * 24 * 7 * 30; //TODO
      }
      for (var i = report.query.fromDate; i < report.query.toDate; i += step) {
        hData.inC[i] = { x: i, y: 0 };
        hData.outC[i] = { x: i, y: 0 };
        hData.inD[i] = { x: i, y: 0 };
        hData.outD[i] = { x: i, y: 0 };
      }
    }
    if (report && report.accountRecords && report.accountRecords.length) {
      for (var a = 0; a < report.accountRecords.length; a++) {
        var accountRecord = report.accountRecords[a];
        if (accountRecord.userRecords && accountRecord.userRecords.length) {
          for (var r = 0; r < accountRecord.userRecords.length; r++) {
            var u = accountRecord.userRecords[r];
            //var sortedRecords = u.records.sort(compareIntervalAt);
            if (u.records && u.records.length) {
              for (var k = 0; k < u.records.length; k++) {
                var row = u.records[k];
                var d = new Date(row.intervalAt);
                var dh = d.getTime();
                if (row.direction === 'INBOUND') {
                  if (hData.inC[dh] && typeof hData.inC[dh].y !== 'undefined') {
                    hData.inC[dh].y += row.totalCalls;
                  } else {
                    hData.inC[dh] = { x: dh, y: row.totalCalls };
                  }
                  if (hData.inD[dh] && typeof hData.inD[dh].y !== 'undefined') {
                    hData.inD[dh].y += row.totalDuration;
                  } else {
                    hData.inD[dh] = { x: dh, y: row.totalDuration };
                  }
                } else {
                  if (hData.outC[dh] && typeof hData.outC[dh].y !== 'undefined') {
                    hData.outC[dh].y += row.totalCalls;
                  } else {
                    hData.outC[dh] = { x: dh, y: row.totalCalls };
                  }
                  if (hData.outD[dh] && typeof hData.outD[dh].y !== 'undefined') {
                    hData.outD[dh].y += row.totalDuration;
                  } else {
                    hData.outD[dh] = { x: dh, y: row.totalDuration };
                  }
                }
              }
            }
          }
        }
      }
    }

    function compareX(a,b) {
      if (a.x < b.x)
        return -1;
      if (a.x > b.x)
        return 1;
      return 0;
    }

    function getValues(hdata) {
      var values = [];
      for (var k in hdata) {
        values.push(hdata[k]);
      }
      return values.sort(compareX);
    }
    
    if (['OUTBOUND', "", undefined].indexOf(self.direction) !== -1) {
      data.push({ key: "Out (count)", type: 'bar', yAxis: 1, values: getValues(hData.outC) });
      data.push({ key: "Out (duration)", type: 'line', yAxis: 2, values: getValues(hData.outD) });
    }
    if (['INBOUND', "", undefined].indexOf(self.direction) !== -1) {
      data.splice(1, 0, { key: "In (count)", type: 'bar', yAxis: 1, values: getValues(hData.inC) });
      data.splice(3, 0, { key: "In (duration)", type: 'line', yAxis: 2, values: getValues(hData.inD) });
    }
    if (self.direction === 'INBOUND') {
      self.options.chart.color = ['#871FFF', '#FA7554'];
    } else {
      self.options.chart.color = ['#FA7554', '#871FFF', '#FFA68C', '#DCB6E9'];
    }
    return data;
  }

  return {
    data: generateData(),
    config: {
      visible: true, // default: true
      extended: false, // default: false
      disabled: false, // default: false
      refreshDataOnly: true, // default: true
      deepWatchOptions: true, // default: true
      deepWatchData: true, // default: true
      deepWatchDataDepth: 2, // default: 2
      debounce: 10 // default: 10
    },
    options: self.options
  };
};