var mockUser = {
  "id": 1,
  "accountId": 1,
  "accountLabel": "Mocked account (Org)",
  "firstName": "Test ",
  "lastName": "User",
  "emailAddress": "test@test.com",
  "contactNumber": "73212345678",
  "activationCode": "sdgsgdfkjshgfhjsgdfksdg",
  "status": "ACTIVE",
  "ucis": [
    {
      "id": 1,
      "ucpUserId": "7321231231@voip.tnltd.net",
      "label": "Telesphere",
      "type": "BS"
    }
  ],
  "vgis": [
    {
      "code": "SALESFORCE",
      "name": "Salesforce",
      "description": "string"
    }
  ]
};

angular.module('sc.portal.mocks', [
  'sc',
  'ngMockE2E'
])
  .run(function ($httpBackend) {
    if (scTesting) return;
    $httpBackend.whenRoute('GET', /portal\/v1\/accounts\/count/).respond(function (method, url) {
      console.warn('accounts/count', url);
      return [200, { count: 120456 }];
    });
    $httpBackend.whenRoute('GET', /portal\/v1\/accounts\/-?\d+\/ucis\/\d+/).respond(function (method, url) {
      console.warn('accounts/:accountId/ucis/:id', url);
      return [200, { id: 3453, accountId: 345, ucpId: 1 }];
    });
    $httpBackend.whenRoute('GET', /portal\/v1\/accounts\/-?\d+\/users\/count/).respond(function (method, url) {
      console.warn('account/:accountId/users/count', url);
      return [200, { count: 4 }];
    });
    $httpBackend.whenRoute('GET', /portal\/v1\/accounts\/-?\d+\/users\/\d+/).respond(function (method, url) {
      console.warn('accounts/:accountId/users/:id', url);
      return [200, mockUser];
    });
    $httpBackend.whenRoute('GET', /portal\/v1\/accounts\/-?\d+\/users/).respond(function (method, url, data, headers, params) {
      console.warn('account/:accountId/users', url);
      var response = [];
      var pageStart = params.page * params.pageSize;
      response.push({ id: pageStart + 1, name: "User Name 1", accountId: 1, ucps: 'Telesphere, iCore', integrations: [{code: 'SALESFORCE', name: 'Salesforce'}], usersCounts: '0 Active, 3 Pending', status: true });
      response.push({ id: pageStart + 2, name: "User Name 2", accountId: 2, ucps: 'Vonage Business', integrations: [{code: 'SALESFORCE', name: 'Salesforce'}], usersCounts: '3 Active, 1 Pending', status: true });
      response.push({ id: pageStart + 3, name: "User Name 3", accountId: 3, ucps: 'SimpleSignal', integrations: [{code: 'SALESFORCE', name: 'Salesforce'}], usersCounts: '0 Active, 0 Pending', status: false });
      response.push({ id: pageStart + 4, name: "User Name 4", accountId: 4, ucps: 'Vonage Business', integrations: [{code: 'SALESFORCE', name: 'Salesforce'}], usersCounts: '1 Active, 1 Pending', status: false });
      return [200, response];
    });
    $httpBackend.whenRoute('GET', /portal\/v1\/accounts\/\d+/).respond(function (method, url) {
      console.warn('accounts/:id', url);
      return [200, { id: 123123, accountName: 'Mock Data Account', ucis: [ { id: 23423, name: 'Telesphere' }], integrations: [{code: 'SALESFORCE', name: 'Salesforce'}] }];
    });
    $httpBackend.whenRoute('PUT', /portal\/v1\/accounts\/\d+/).respond(function (method, url) {
      console.warn('accounts/:id', url);
      return [200, { id: 123123, accountName: 'Mock Data Account', ucis: [ { id: 23423, name: 'Telesphere' }], integrations: [{code: 'SALESFORCE', name: 'Salesforce'}] }];
    });
    $httpBackend.whenRoute('GET', /portal\/v1\/accounts/).respond(function (method, url, data, headers, params) {
      console.warn('accounts', url);
      var response = [];
      var pageStart = params.page * params.pageSize;
      for (var i = 0; i < 5; i++) {
        response.push({ id: pageStart + 1 + (i*4), name: "Account Name",  ucps: 'Telesphere, iCore', integrations: [{code: 'SALESFORCE', name: 'Salesforce'}], usersCounts: '0 Active, 3 Pending', status: true });
        response.push({ id: pageStart + 2 + (i*4), name: "Account Name", ucps: 'Vonage Business', integrations: [{code: 'SALESFORCE', name: 'Salesforce'}], usersCounts: '3 Active, 1 Pending', status: true });
        response.push({ id: pageStart + 3 + (i*4), name: "Account Name", ucps: 'SimpleSignal', integrations: [{code: 'SALESFORCE', name: 'Salesforce'}], usersCounts: '0 Active, 0 Pending', status: false });
        response.push({ id: pageStart + 4 + (i*4), name: "Account Name", ucps: 'Vonage Business', integrations: [{code: 'SALESFORCE', name: 'Salesforce'}], usersCounts: '1 Active, 1 Pending', status: false });
      }
      return [200, response];
    });
    $httpBackend.whenRoute('GET', /portal\/v1\/users\/count/).respond(function (method, url) {
      console.warn('users/count', url);
      return [200, { count: 150999 }];
    });
    $httpBackend.whenRoute('GET', /portal\/v1\/users\/\d+/).respond(function (method, url) {
      console.warn('users/:id', url);
      return [200, mockUser];
    });
    $httpBackend.whenRoute('GET', /portal\/v1\/users/).respond(function (method, url, data, headers, params) {
      console.warn('users', url);
      var response = [];
      var pageStart = params.page * params.pageSize;
      for (var i = 0; i < 5; i++) {
        response.push({  id: pageStart + 1 + (i*4), accountId: 1, accountName: 'Telesphere account', userName: 'Alex V', ucps: 'Telesphere', integrations: [{code: 'SALESFORCE', name: 'Salesforce'}], telecomId: '1001001000@voip.tnltd.net', status: true });
        response.push({  id: pageStart + 2 + (i*4), accountId: 2, accountName: 'Vonage Business account', userName: 'Srini G', ucps: 'Vonage Business', integrations: [{code: 'SALESFORCE', name: 'Salesforce'}], telecomId: 'vaccount', status: true });
        response.push({  id: pageStart + 3 + (i*4), accountId: 3, accountName: 'SS gunify account', userName: 'Abhi S', ucps: 'SimpleSignal', integrations: [{code: 'SALESFORCE', name: 'Salesforce'}], telecomId: '1234567890@simplesignal.com', status: false });
        response.push({  id: pageStart + 4 + (i*4), accountId: 4, accountName: 'Bullhorn test', userName: 'Tanv M', ucps: 'Vonage Business', integrations: [{code: 'SALESFORCE', name: 'Salesforce'}], telecomId: 'bullhorntest', status: false });
      }
      return [200, response];
    });
    $httpBackend.whenRoute('GET', /portal\/v1\/reports\/summary/).respond(function (method, url) {
      console.warn('reports/summary', url);
      return [200, require('./summaryReport')];
    });
  });

  //angular.module('sc.portal').requires.push('sc.portal.mocks');
