require('./account');

angular.module('sc.model.accountUci', [
  'sc',
  'ngResource',
  'sc.model.account'
])
.factory('AccountUciFactory', /*@ngInject*/ function($injector, $resource, $scSystem) {
  var AccountUciResource = function (attributes) {
    var self = this;
    if (!attributes) {
      attributes = {};
    }
    if (attributes.account) {
      self.account = attributes.account;
      attributes.accountId = attributes.account.id;
    }
    var AccountUci = $resource($scSystem.portalUrl+'/accounts/:accountId/ucis/:id', {
      accountId: function(that) {
        return that ? that.accountId || attributes.accountId || -1 : attributes.accountId || -1;
      },
      id: '@id'
    }, {
      update: {
        method: 'PUT'
      },
      create: {
        method: 'POST'
      },
      describe: {
        method: 'GET',
        url: $scSystem.portalUrl + '/accounts/:accountId/ucis/:id/describe'
      }
    });

    AccountUci.prototype.$importUsers = function(data, successCallback, errorCallback) {
      $resource($scSystem.portalUrlV2 + '/accounts/'+attributes.accountId+'/ucis/'+this.id+'/import/users').query(data, successCallback, errorCallback);
    };

    AccountUci.prototype.$save = function(success, error) {
      return (this.id) ? this.$update(success, error) : this.$create(success, error);
    };

    Object.defineProperty(AccountUci.prototype, 'account', { 
      get: function() {
        if (self.account) {
          return self.account;
        } else {
          var Account = new $injector.get('AccountFactory')({ users: this });
          return new Account({ id: this.accountId });
        }
      }
    });

    return AccountUci;
  };

  return AccountUciResource;
});