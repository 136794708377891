require('../../../icons/ic_library_add_24px.svg');
require('../../../icons/ic_find_in_page_24px.svg');
require('../../../icons/ic_filter_none_24px.svg');
require('../../../icons/ic_sync_24px.svg');
require('../../../icons/ic_close_24px.svg');
require('../../../icons/ic_mic_24px.svg');
require('../../../icons/ic_mic_off_24px.svg');
require('../../../icons/ic_more_vert_24px.svg');
require('../../../icons/ic_delete_forever_24px.svg');
require('../../../icons/ic_mode_edit_24px.svg');

require('./scField.scss');
require('../scFormValidation');
var relativeTimeBuilder = require('../../helpers/relativeTimeBuilder');

angular.module("sc.common.field", [
  'sc',
  'sc.common.formValidation'
])
.value('$scTransformers', {})
.value('$scIntegrationErrorParsers', {})
.value('$scExternalLinkBuilders', {})
.directive('scField', function ($compile, $mdPanel, $mdToast, $scSystem, UnifiedService, $scTransformers, $timeout, $window, $rootScope) {
    /*
    var speechRecognitionEnabled;
    speechRecognition.supported(function(enabled) {
      speechRecognitionEnabled = enabled;

    });
    */

    return {
      restrict: 'E',
      scope: {
        scDescribe: '=',
        scModel: '=',
        scOwner: '=',
        scChange: '=',
        scRefChange: '=',
        scConnectorCode: '@',
        scShowSection: '=?',
        scOptionalCount: '=?',
        scFields: '=',
        scLocks: '=',
        noX: '=',
        scResourceType: '@'
      },
      require: "^form",
      link: function (scope, element, attr, form) {
        var isRequired = false;
        scope.form = form;
        if (typeof scope.scOptionalCount === 'undefined') {
          scope.scOptionalCount = 0;
        }
        function buildValidationAttributes(scDescribe) {
          var attributes = " ng-disabled='disabled'";
          for (var v in scDescribe.validations) {
            if (['string', 'boolean', 'number'].indexOf(typeof (scDescribe.validations[v])) === -1) {
              return attributes;
            }
            var value = scDescribe.validations[v];
            switch (v) {
              case 'required':
                isRequired = !!scDescribe.validations[v];
                if (isRequired) {
                  if (scDescribe.type === "reference" && scDescribe.multiple === true) {
                    attributes += ' ng-required="true"';
                  } else {
                    attributes += ' ng-required="!scDescribe.$hidden"';
                  }
                }
                break;
              case 'maxlength':
                if (scDescribe.validations[v]) {
                  if (scDescribe.type === "reference" && scDescribe.multiple === true) {
                    attributes += ' md-max-chips="' + value + '"';
                  } else {
                    attributes += ' maxlength="' + value + '"';
                  }
                }
                break;
              case 'pattern':
                if (scDescribe.validations[v]) {
                  attributes += ' pattern="' + value + '"';
                }
                break;
              default:
                break;
            }
          }
          return attributes;
        }

        scope.clearCustomError = function () {
          if (form[scope.scDescribe.label]) {
            form[scope.scDescribe.label].$setValidity('custom', true);
            delete form[scope.scDescribe.label].$customError;
          }
          if (typeof scope.scChange === 'function') {
            $timeout(function() {
              scope.scChange(scope.scModel, scope.scDescribe);
            }, 0);
          }
          if (['date', 'time'].indexOf(scope.scDescribe.type) !== -1) {
            if (scope.scModel) {
              scope.scModel.patchTimezone = true;
              if (scope.scDescribe.valueType) {
                scope.scModel.valueType = scope.scDescribe.valueType;
              }
            }
          }
        };
        scope.clearSelection = function () {
          if (scope.scDescribe.type == "picklist" && scope.scDescribe.multiple == true) {
            scope.scModel = [];
          } else {
            scope.scModel = undefined;
          }
          scope.clearCustomError();
        };
        scope.clearRefType = function () {
          scope.refType = undefined;
          if (typeof scope.scRefChange === 'function') {
            $timeout(function() {
              scope.scRefChange(scope.refType, scope.scDescribe);
            }, 0);
          }
        };
        scope.refTypeChange = function() {
          if (typeof scope.scRefChange === 'function') {
            $timeout(function() {
              scope.scRefChange(scope.refType, scope.scDescribe);
            }, 0);
          }
        };
        /*
        scope.speechRecognition = function(e) {
          return speechRecognition.start(e, scope);
        };
        */
      //  if (scope.scDescribe.visible) { // support mix of V2 and V3
      //   scope.scDescribe.alwaysVisible = scope.scDescribe.visible;
      //  }

        if ((scope.scLocks && scope.scLocks.$level !== 'account') && typeof(scope.scLocks[scope.scDescribe.name]) !== 'undefined') {
          scope.scDescribe.disabled = scope.scLocks[scope.scDescribe.name];
        }
        scope.toggleLock = function() {
          if (scope.scLocks && (!scope.scLocks.$level || scope.scLocks.$level !== 'none')) {
            scope.scLocks[scope.scDescribe.name] = !scope.scLocks[scope.scDescribe.name];
            if (scope.scLocks && scope.scLocks.$level !== 'account') {
              scope.scDescribe.disabled = scope.scLocks[scope.scDescribe.name];
            }
          } else {
            $mdToast.showSimple('Changing this configuration is locked by your account administrator.');
          }
        };

        function convertValueByType(value) {
          if (scope.scOwner && scope.scOwner.$$state) {
            return;
          }
          try {
            if (['date', 'datetime', 'time'].indexOf(scope.scDescribe.type) !== -1) {
              if (typeof(value) === 'string' && value.match(/^(-?)((\d+).)?(\S+)$/)) {
                scope.scModel = relativeTimeBuilder(value);
              } else if (typeof (value) === 'object') {
                scope.scModel = value;
              } else if (typeof(value) === 'number') {
                if (scope.scDescribe.type === 'time') {
                  var backendGeneratedTime = value > 86399000;
                  if (backendGeneratedTime) {
                    scope.scModel = new Date(value);
                    scope.scModel.setFullYear(1970);
                    scope.scModel.setMonth(0);
                    scope.scModel.setDate(1);
                    scope.scModel.setMilliseconds(0);
                  } else {
                    var time = new Date(value);
                    scope.scModel = new Date(time.getTime() + (time.getTimezoneOffset() * 60000));
                  }
                  scope.scModel.patchTimezone = true;
                } else if (scope.scDescribe.type === 'date') {
                  var backendGeneratedDate = value % 1000; // when BE generates default values, they are not using UTC and not zeroing the hours and minutes
                  if (backendGeneratedDate) {
                    scope.scModel = new Date(value);
                    scope.scModel.setMinutes(0);
                    scope.scModel.setHours(0);
                    scope.scModel.setSeconds(0);
                    scope.scModel.setMilliseconds(0);
                  } else {
                    var date = new Date(value);
                    scope.scModel = new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
                  }
                  scope.scModel.patchTimezone = true;
                } else if (typeof(value) === 'number') {
                  scope.scModel = new Date(value);
                } else if (typeof(value) === 'string') {
                  scope.scModel = new Date(Date.parse(value));
                }
              }
            } else if (['int', 'integer', 'number', 'double'].indexOf(scope.scDescribe.type) !== -1) {
              scope.scModel = typeof (value) === 'number' ? value : parseInt(value);
            } else if (['boolean'].indexOf(scope.scDescribe.type) !== -1) {
              scope.scModel = typeof (value) === 'boolean' ? value : (typeof (value) === 'string' ? (['true', '1'].indexOf(value.toLocaleLowerCase()) !== -1) : !!value);
            } else {
              scope.scModel = value;
            }
          } catch (e) { console.error(e); }
        }

        if (scope.scDescribe.type === 'reference' || scope.scDescribe.type === 'picklist') {
          var refDescribes = {};
          ((scope.scDescribe.reference instanceof Array) ? scope.scDescribe.reference : [scope.scDescribe.reference]).forEach(function (rd) {
            if (rd && rd.name && !rd.hidden) {
              refDescribes[rd.name] = rd;
            }
          }); // TODO it should come like this from the server
          scope.refDialog = function (ev, refItem) {
            if (refItem && !refItem.connector) {
              refItem.connector = scope.scConnectorCode;
            }
            var creatableReferences = !scope.scDescribe.reference.length ? [scope.scDescribe.reference] : scope.scDescribe.reference.filter(function (r) {
              return refItem ? ((refItem.type || refItem.resourceType) === r.name) : r.creatable;
            });
            if (!$rootScope.scFieldPanels) {
              $rootScope.scFieldPanels = $mdPanel.newPanelGroup('scFieldPanels', { maxOpen: 3 });
            }
            var position = $mdPanel.newPanelPosition().absolute().center();
            var panelOffset = $rootScope.scFieldPanels.openPanels.length * 8;
            if (panelOffset) {
              position.withOffsetX(panelOffset);
              position.withOffsetY(panelOffset);
            }
            var config = {
              attachTo: angular.element(document.body),
              controller: require('./refDialog/refDialogCtrl'),
              controllerAs: 'refCtrl',
              template: require('./refDialog/refDialog.html'),
              position: position,
              clickOutsideToClose: false,
              hasBackdrop: true,
              trapFocus: true,
              escapeToClose: true,
              panelClass: 'refDialog sc-panel-dialog sc-field-dialog',
              openFrom: ev,
              focusOnOpen: true,
              groupName: 'scFieldPanels',
              locals: {
                refType: scope.refType,
                refItem: refItem,
                references: creatableReferences,
                scConnectorCode: scope.scConnectorCode,
                eventId: 'NO_EVENT'
              },
              resolve: {
                scDescribeCbs: /*@ngInject*/ function ($q, UnifiedService) {
                  var describeCbs = {};
                  if (refItem) {
                    var storedLayoutIdKey = $scSystem.userId + '.' + scope.scConnectorCode + (refItem.type || refItem.resourceType) + 'LayoutId';
                      var params = {};
                      var storedLayoutId = localStorage.getItem(storedLayoutIdKey);
                      if (storedLayoutId) {
                        params.layoutId = storedLayoutId;
                      }
                    describeCbs[refItem.type || refItem.resourceType] = function() {
                      return new UnifiedService({
                        connector: scope.scConnectorCode,
                        resource: refItem.type || refItem.resourceType
                      }).describe(params).$promise;
                    };
                  } else {
                    creatableReferences.forEach(function (ref) {
                      if (!ref.creatable) {
                        return;
                      }
                      var storedLayoutIdKey = $scSystem.userId + '.' + scope.scConnectorCode + ref.name + 'LayoutId';
                      var params = {};
                      var storedLayoutId = localStorage.getItem(storedLayoutIdKey);
                      if (storedLayoutId) {
                        params.layoutId = storedLayoutId;
                      }
                      describeCbs[ref.name] = function() {
                        return new UnifiedService({
                          connector: scope.scConnectorCode,
                          resource: ref.name
                        }).describe(params).$promise;
                      };
                    });
                  }
                  return describeCbs;
                },
                scServices: /*@ngInject*/ function ($q, UnifiedService) {
                  var services = {};
                  if (refItem && (refItem.identifier || refItem.externalId || refItem.id)) {
                    var IntegrationContactService = new UnifiedService({
                      connector: scope.scConnectorCode,
                      resource: refItem.type || refItem.resourceType
                    });
                    services[refItem.type || refItem.resourceType] = IntegrationContactService.get({ id: refItem.identifier || refItem.externalId || refItem.id, eventId: 'NO_EVENT', referenceDetails: true }).$promise;
                  } else {
                    creatableReferences.forEach(function (ref) {
                      if (!ref.creatable) {
                        return;
                      }
                      var ResourceService = new UnifiedService({
                        connector: scope.scConnectorCode,
                        resource: ref.name
                      });
                      var defaultResource = {};
                      try {
                        var transformer = $scTransformers[scope.scConnectorCode + ref.name];
                        if (typeof transformer === 'function') {
                          defaultResource = transformer($rootScope.scCall);
                        }
                      } catch (e) {
                        if (scDebug) {
                          console.error("scField catch", e);
                        }
                      }
                      services[ref.name] = new ResourceService(defaultResource);
                    });
                  }
                  return services;
                }
              }
            };
            $mdPanel.open(config).then(function (panelRef) {
              panelRef.scDone = function (result) {
                if (scope.scDescribe.type === 'picklist') {
                  if (scope.scDescribe.picklist && scope.scDescribe.picklist.push) {
                    scope.scDescribe.picklist.push(result);
                  }
                }
                if (scope.scDescribe.multiple) {
                  if (!scope.scModel) {
                    scope.scModel = [];
                  }
                  scope.scModel.valueArray = true;
                }
                if (scope.scModel instanceof Array) {
                  if (result.valueType) {
                    scope.scModel.valueType = result.valueType;
                  }
                  if (scope.scDescribe.valueType) { // high priority
                    scope.scModel.valueType = scope.scDescribe.valueType;
                  }
                  if (scope.scDescribe.type === 'picklist' && scope.scDescribe.valueType === 'string') {
                    scope.scModel.push(result.id || result.value || result.identifier);
                  } else {
                    scope.scModel.push(result);
                  }
                } else if (scope.scModel instanceof Object) {
                  scope.scModel = result;
                } else {
                  if (scope.scDescribe.type === 'picklist' && scope.scDescribe.valueType === 'string') {
                    scope.scModel = result.id || result.value || result.identifier;
                  } else {
                    scope.scModel = result;
                  }
                }
                panelRef.destroy();
              };
            });
          };
        }

        scope.afterChipsChange = function () {
          if (scope.scDescribe.multiple) {
            if (!scope.scModel) {
              scope.scModel = [];
            }
            scope.scModel.valueArray = true;
          }
          if (scope.scDescribe.valueType) { //high priority
            scope.scModel.valueType = scope.scDescribe.valueType;
          } else {
            if (scope.scDescribe.reference) {
              var item = scope.scModel.length ? scope.scModel[0] : {};
              var ref = scope.scDescribe.reference.length ? scope.scDescribe.reference.find(function (reference) {
                return reference.name === (item.attributes ? item.attributes.type : item.resourceType || item.type || item.personSubtype);
              }) : scope.scDescribe.reference;
              if (ref && ref.valueType) {
                scope.scModel.valueType = ref.valueType;
              }
            }
          }
          scope.clearCustomError();
        };

        var html;
        if (typeof(scope.scDescribe.visible) === 'string') {
          scope.$watch('scOwner["'+scope.scDescribe.visible+'"]', function(newValue) {
            scope.scDescribe.$hidden = !newValue;
          }, true);
        } else if (typeof(scope.scDescribe.visible) === 'object') {
          var watchParentProperty = Object.keys(scope.scDescribe.visible)[0];
          var watchValue = scope.scDescribe.visible[watchParentProperty];
          if (typeof(watchParentProperty) !== 'undefined' && typeof(watchValue) !== 'undefined') {
            if(typeof(watchValue) === 'boolean'){
              scope.$watch('scOwner["'+watchParentProperty+'"]', function(newValue) {
                if (typeof(newValue) !== 'undefined') {
                  scope.scDescribe.$hidden = newValue !== watchValue;
                }
              }, true);
            }else{
              scope.$watch('scOwner["'+watchParentProperty+'"]', function(newValue) {
                if (newValue) {
                  scope.scDescribe.$hidden = (newValue.id || newValue.name || newValue) !== watchValue;
                }
              }, true);
            }              
          }
        }
        if (scope.scDescribe.type === 'reference') {
          //special conditions! TODO
          var searchUrl;
          if ($scSystem.portalUrl) { //dirtyFix for search
            if($rootScope.viewLayoutAs) {
              $scSystem.accountId = $rootScope.viewLayoutAs.accountId;
              $scSystem.userId = $rootScope.viewLayoutAs.userId;  
            }
            searchUrl = $scSystem.baseUrl.replace('/v1', '') + (scope.scConnectorCode === 'portal' ? '/v1/search' : '/v1/accounts/:accountId/users/:userId/integrations/:connector/:resourceType/search');
          } else {
            searchUrl = $scSystem.baseUrl.replace('/v1', '') + (scope.scConnectorCode === 'portal' ? '/v1/search' : '/v2/accounts/:accountId/users/:userId/connectors/:connector/:resourceType/search');
          }
          if (scope.scConnectorCode == 'mock') {
            searchUrl = './mock/search/:scope';
          }
          var serviceActions = {
            search: {
              method: 'GET',
              isArray: true,
              url: searchUrl
            },
            save: {
              method: 'POST',
              url: $scSystem.baseUrl + '/accounts/:accountId/users/:userId/connectors/:connector/:resourceType/:id',
              transformRequest: function (data) {
                var trData = {};
                for (var property in data) {
                  if (data[property] instanceof Date) {
                    if (data[property].valueType === 'isostring') {
                      trData[property] = data[property].toISOString();
                    } else {
                      if (data[property].patchTimezone) {
                        trData[property] = new Date(data[property].getTime() + (data[property].getTimezoneOffset() * 60000)).getTime();
                      } else {
                        trData[property] = data[property].getTime();
                      }
                    }
                  } else trData[property] = data[property];
                }
                //*Removing the following row... (is it really needed?)
                //trData[scope.scDescribe.name] = data[scope.scDescribe.name].identifier; //TODO check why is this?
                return angular.toJson(trData);
              }
            }
          };
          var uService = new UnifiedService({ connector: scope.scConnectorCode }, serviceActions);
          scope.querySearch = function (text) {
            //var deferred = $q.defer();
            delete scope.searchError;
            var scopeTypes = scope.refType ? scope.refType : Object.keys(refDescribes).join(',');
            var foundItems = [];
            if (!scope.getByIdPromises) {
              scope.getByIdPromises = [];
            }
            function onFoundByIds(recs) {
              //var hItem = { extra: true, id: rec._vgis.externalId, label: rec._vgis.label || '...', type: rec._vgis.resourceType };
              //foundItems.splice(0, 0, hItem);
              console.warn('What I am?', recs);
            }
            function compare(a, b) {
              if (a.label < b.label)
                return -1;
              if (a.label > b.label)
                return 1;
              return 0;
            }
            var p = { ids: text, scope: scopeTypes };
            if(refDescribes && typeof(refDescribes.extraSearchParams) == 'function') {
              var extraSearchParams = refDescribes.extraSearchParams();
              if (typeof(extraSearchParams) === 'object') {
                for (var e in extraSearchParams) {
                  p[e] = extraSearchParams[e];
                }
              }
            }
            for (var rk in refDescribes) {
              if (refDescribes[rk].idPattern) {
                var idRegex = new RegExp(refDescribes[rk].idPattern);
                if (idRegex.test(text)) {
                  scope.getByIdPromise = uService.search(p).$promise.then(onFoundByIds, angular.noop);
                }
              }
            }
            if (scope.searchPromise) {
              return scope.searchPromise;
            } else {
              if (!text) {
                return scope.scDescribe.suggestions || [];
              } else if (text.length === 1) {
                return [];
              } else {
                var params = { q: text, scope: scopeTypes };
                if(scope.scDescribe.reference && typeof(scope.scDescribe.reference.extraSearchParams) == 'function') {
                  //params.groupId = scope.scDescribe.reference.extraSearchParams();
                  var extraSearchParams2 = scope.scDescribe.reference.extraSearchParams(); //DRY!!!
                  if (typeof(extraSearchParams2) === 'object') {
                    for (var s in extraSearchParams2) {
                      params[s] = extraSearchParams2[s];
                    }
                  }
                }
                if (scope.scDescribe && scope.scDescribe.searchUrl) {
                  var refSearchUrl = $scSystem.baseUrl+'/v2/accounts/:accountId/users/:userId/connectors/:connector/resources/:resourceType';
                  if ($scSystem.portalUrl) {
                    refSearchUrl = $scSystem.baseUrl+'/accounts/:accountId/users/:userId/integrations/:connector/resources/:resourceType';
                  }
                  refSearchUrl += (scope.scDescribe.searchUrl ? scope.scDescribe.searchUrl : '/fields/'+scope.scDescribe.name+'/options') + '&q=' + text;
                  
                  var refOptionsService = new UnifiedService({ connector: scope.scConnectorCode }, {
                    search: {
                      method: 'GET',
                      isArray: true,
                      url: refSearchUrl
                    }
                  });
                  return refOptionsService.search({resourceType: $rootScope.scResourceType}).$promise.then(function(options) {
                    if (options && options.length) {
                      foundItems = options;
                    } else {
                      foundItems = [{ id: '-1', label: 'No options available!'}];
                    }
                    return foundItems;
                  }, function(err) {
                    foundItems = [{ id: '-1', label: err.data.message || err.data}];
                    return foundItems;
                  });
                } else {
                  scope.searchPromise = uService.search(params).$promise.then(function (result) {
                    //deferred.resolve(foundItems);
                    var knownType = Object.keys(refDescribes).length === 1 ? Object.keys(refDescribes)[0] : null;
                    //scope.foundItems = [];
                    if (!result.length) {
                      foundItems.length = 0;
                      var acceptNew = scope.scDescribe.acceptNew;
                      if (acceptNew && acceptNew.type && acceptNew.pattern) {
                        var regex = new RegExp(acceptNew.pattern);
                        if (regex.test(text)) {
                          foundItems.push({ id: null, label: text, type: acceptNew.type });
                        }
                      }
                    } else {
                      result.sort(compare).forEach(function (r) {
                        foundItems.push(r);
                        if (!r.type) {
                          r.type = r.attributes ? r.attributes.type : r.resourceType || r.type || r.personSubtype; //why?
                        }
                        var rd = refDescribes[knownType || r.type]; //why?
                        //if (!r.label) {
                        if (rd && rd.valueLabel) {
                          if (rd.valueLabel instanceof Array) {
                            var compositeLabelS = [];
                            for (var l = 0; l < rd.valueLabel.length; l++) {
                              if (!/Url$/.test(rd.valueLabel[l]) && r[rd.valueLabel[l]] && compositeLabelS.indexOf(r[rd.valueLabel[l]]) === -1) {
                                compositeLabelS.push(r[rd.valueLabel[l]]);
                              }
                            }
                            r.label = compositeLabelS.join(', ');
                          } else r.label = r[rd.valueLabel] || r.name || r.Name || r[rd.valueKey];
                        }
                        if (!r.label) {
                          r.label = (r.name || r.Name); //deep fallback
                        }
                        //}
                      });
                    }
                    if (scope.searchText && scope.searchText !== text) {
                      delete scope.searchPromise;
                      return scope.querySearch(scope.searchText);
                    } else {
                      setTimeout(function () { // experimental HPBR-5729
                        angular.element($window).triggerHandler('resize');
                      }, 10);
                      return foundItems;
                    }
                  }, function (errorResponse) {
                    if (errorResponse.data) {
                      scope.searchError = errorResponse.data.message;
                      if (!scope.searchError) {
                        if (errorResponse.data.integrationError) {
                          var integrationError = errorResponse.data.integrationError;
                          scope.searchError = integrationError.body.message + ' (' + integrationError.code + ')';
                        }
                        else scope.searchError = errorResponse.data.message + ' (' + errorResponse.data.exception + ')';
                      } else {
                        scope.searchError += ' ('+(errorResponse.data.exception || errorResponse.data.code)+')';
                      }
                    }
                    return [];
                    //deferred.resolve([]);
                  }).finally(function () {
                    setTimeout(function () {
                      delete scope.searchPromise;
                    }, 0);
                  });
                }
              } //if some text
            }
            return scope.searchPromise; //deferred.promise;
          };

          scope.selectedItemChange = function (item) {
            scope.clearCustomError();
            if (!item || (scope.scDescribe.defaultValue && item === scope.scDescribe.defaultValue)) {
              return;
            }
            var ref = scope.scDescribe.reference.length ? scope.scDescribe.reference.find(function (reference) {
              return reference.name === (item.attributes ? item.attributes.type : item.resourceType || item.type || item.personSubtype);
            }) : scope.scDescribe.reference;
            if (!scope.scModel.id) {
              scope.scModel.id = item[ref.valueKey];
            }
            if (!scope.scModel.value) {
              scope.scModel.value = item[ref.valueKey]; // only one should stay id/value
            }
            if (!scope.scModel.label) {
              if (ref.valueLabel instanceof Array) {
                var compositeLabelC = [];
                for (var lv = 0; lv < ref.valueLabel.length; lv++) {
                  if (!/Url$/.test(ref.valueLabel[lv]) && item[ref.valueLabel[lv]] && compositeLabelC.indexOf(item[ref.valueLabel[lv]]) === -1) {
                    compositeLabelC.push(item[ref.valueLabel[lv]]);
                  }
                }
                scope.scModel.label = compositeLabelC.join(', ');
              } else scope.scModel.label = item[ref.valueLabel] || item.name || item.id;
            }
            scope.scModel.type = ref.name;
            scope.refType = ref.name;
            if (ref.valueType) {
              scope.scModel.valueType = ref.valueType;
            }
            if (scope.scDescribe.multiple) {
              scope.scModel.valueArray = true;
            }
            scope.hasEdit = ref.updatable || (ref.creatable && ref.updatable !== false);
          };
          scope.setDefault = function () {
            if (!scope.scModel && scope.scDescribe.defaultValue && scope.scDescribe.createable) {
              var refDescribe = refDescribes[scope.scDescribe.defaultValue.reference];
              scope.scModel = scope.scDescribe.defaultValue;
              if (!scope.scModel.id) { //VGIS-4755 in custom layouts it is id, *value is undefined
                scope.scModel.id = scope.scDescribe.defaultValue.value;
              }
              if (refDescribe && refDescribe.valueType) {
                scope.scModel.valueType = refDescribe.valueType;
              }
              //*Removing the following row... (who/where require identifier?)
              //scope.scModel.identifier = scope.scModel.value; // when submit, identifier required (default provides a 'value' insted)
              if (!scope.scModel.label) {
                scope.scModel.label = scope.scModel.value;
              }
              scope.refType = scope.scDescribe.defaultValue.reference;
              scope.clearCustomError();
            } else if (scope.scModel && (scope.scModel.type || scope.scModel.resourceType)) {
              scope.refType = scope.scModel.type || scope.scModel.resourceType;
            } else if (scope.scModel && scope.scModel.valueArray && scope.scDescribe.defaultValue) {
              if (scope.scModel && scope.scModel instanceof Array && !scope.scModel.length) {
                if (scope.scDescribe.defaultValue instanceof Array && scope.scDescribe.defaultValue.length > 0) {
                  var scModelArr = [];
                  for (var defValIdx = 0; defValIdx < scope.scDescribe.defaultValue.length; defValIdx++) {
                    var defVal = scope.scDescribe.defaultValue[defValIdx];
                    scModelArr.push({
                      id: defVal.value,
                      value: defVal.value,
                      label: defVal.label || defVal.value,
                      type: defVal.type || defVal.resourceType || defVal.reference,
                    });
                  }                  
                  for(var md in scModelArr) {
                    scope.scModel.push(scModelArr[md]);
                  }
                } else {
                  var scModel = {
                    id: scope.scDescribe.defaultValue.value,
                    value: scope.scDescribe.defaultValue.value,
                    label: scope.scDescribe.defaultValue.label || scope.scDescribe.defaultValue.value,
                    type: scope.scDescribe.defaultValue.type || scope.scDescribe.defaultValue.resourceType || scope.scDescribe.defaultValue.reference,
                  };
                  scope.scModel.push(scModel);
                }
              }
              var describeRef = refDescribes[scope.scDescribe.defaultValue.reference];
              if (describeRef && describeRef.valueType) {
                scope.scModel.valueType = describeRef.valueType;
              }
              scope.clearCustomError();
            }
          };

          scope.transformChip = function (item) {
            if (angular.isObject(item)) {
              var ref = scope.scDescribe.reference.length ? scope.scDescribe.reference.find(function (reference) {
                return reference.name === (item.attributes ? item.attributes.type : item.resourceType || item.type || item.personSubtype);
              }) : scope.scDescribe.reference;
              if (ref) {
                if (!item.id) {
                  item.id = item[ref.valueKey];
                }
                if (!item.value) {
                  item.value = item[ref.valueKey] || item.id; // only one should stay id/value
                }
                if (!item.label) {
                  item.label = item[ref.valueLabel];
                }
                item.type = ref.name;
                scope.refType = ref.name;
                if (ref.valueType) {
                  scope.scModel.valueType = ref.valueType;
                }
              }
              if (scope.scDescribe.multiple) {
                scope.scModel.valueArray = true;
              }
              return item; // If it is an object, it's already a known chip
            } else return { name: item, type: 'new' }; // Otherwise, create a new one
          };

          scope.openInCRM = function(ev) {
            $rootScope.openInCRM(ev, { _vgis: { externalId: scope.scModel.id, resourceType: scope.scModel.type, connector: scope.scConnectorCode }});
          };
          scope.openExternalLink = function(ev, item) {
            ev.preventDefault();
            ev.stopPropagation();
            $rootScope.openInCRM(ev, { _vgis: { externalId: item.id, resourceType: item.type, connector: scope.scConnectorCode }});
            return false;
          }

          if (!scope.scDescribe.reference) {
            console.error('Reference object not available: ', scope.scDescribe.label, scope.scDescribe);
            html = "<md-subheader class='md-warn'>Invalid metadata ({{scDescribe.label}})</md-subheader>";
            element.html(html);
            return $compile(element.contents())(scope);
          }  //if the metadata is invalid.

          if (scope.scDescribe.reference instanceof Array) {
            var hasCreatable = false;

            for (var r in scope.scDescribe.reference) {
              var ri = scope.scDescribe.reference[r];
              if (ri.creatable) {
                hasCreatable = true;
                if (scope.refType) {
                  break; // no need to loop more 
                }
              }
              if (ri.default || ri.defaultValue) { //VGIS-2097
                scope.refType = ri.name;
              }
            }
            scope.shownReferences = [];
            scope.scDescribe.reference.forEach(function(r) {
              if (!r.hidden) {
                scope.shownReferences.push(r);
              }
            });
            html = "<md-input-container class='sc-reference-field' layout='row' layout-wrap style='padding: 0;'><md-input-container flex='20'><label>{{scDescribe.label}}</label>";
            html += "<md-select aria-label='{{scDescribe.label}}' ng-model='refType' ng-change='refTypeChange()'><md-option ng-repeat='option in shownReferences' ng-value='option.name'>{{option.name}} {{option.default}}</md-option></md-select>";
            html += "<md-button class='md-icon-button' ng-if='refType && !noX' ng-click='clearRefType($event)' aria-label='Clear' style='position: absolute; right: -8px; top: -3px;' ng-disabled='disabled'><md-icon md-svg-src='icons/ic_close_24px.svg'></md-icon></md-button>";
            html += "</md-input-container>";
            html += "<md-icon md-svg-src='icons/ic_find_in_page_24px.svg'></md-icon>";
            if (scope.scDescribe.multiple) {
              if (!scope.scModel) {
                scope.scModel = [];
              }
              if (scope.scDescribe.valueType || (scope.scDescribe.reference[0] && scope.scDescribe.reference[0].valueType)) {
                scope.scModel.valueType = scope.scDescribe.valueType || scope.scDescribe.reference[0].valueType;
              }
              scope.scModel.valueArray = true;
              html += "<md-chips md-on-add='afterChipsChange()' md-on-remove='afterChipsChange()' flex ng-model='scModel' md-autocomplete-snap md-transform-chip='transformChip($chip)' md-require-match='true' md-add-on-blur='true'>";
              html += "<md-autocomplete ng-disabled='disabled' md-delay='250' md-input-name='{{scDescribe.label}}' md-no-cache='true' md-min-length='2' md-selected-item='selectedItem' md-search-text='searchText' md-items='item in querySearch(searchText)' md-item-text='item.label' placeholder='+add' md-require-match>";
              html += "<md-item-template><div class='sc-item' ng-class='{\"extra\": item.extra}'><b title='{{item.label}} ({{item.type || item.attributes.type }}#{{item.id}})'><gu-sc-orig>{{item.label}}</gu-sc-orig></b> <span class='sc-muted'>{{item.type || item.attributes.type }}#{{item.id}}</span></div></md-item-template><md-not-found><div class='sc-item'>No records matching \"{{searchText}}\" were found.</div></md-not-found>";
              html += "</md-autocomplete>";
              html += "<md-chip-template><strong>{{$chip.label || $chip.name}}</strong><span ng-if='$chip.type'>({{$chip.type}})<md-button ng-if='$chip.type' style='margin-right: -3px; margin-top: 0;' ng-click='openExternalLink($event, $chip)' class='md-icon-button md-mini sc-live-link-small' area-label='Open in CRM'><md-icon class='md-primary'  style='min-height: initial; height: 16px;' md-svg-src='icons/ic_launch_24px.svg'></md-icon></md-button></span></md-chip-template></md-chips>";
            } else {
              html += "<md-autocomplete ng-disabled='disabled' md-delay='250' md-input-name='{{scDescribe.label}}' md-no-cache='true' flex sc-field-id='{{scDescribe.name}}' md-input-name='{{scDescribe.label}}' md-min-length='scDescribe.suggestions ? 0 : 2' md-selected-item='scModel' md-selected-item-change='selectedItemChange(item)'" +
                " md-search-text='searchText' md-items='item in querySearch(searchText)' md-item-text='item.label' md-require-match";
              html += buildValidationAttributes(scope.scDescribe);
              html += " md-floating-label='Search {{refType}}'><md-item-template><div class='sc-item' ng-class='{\"extra\": item.extra}'><span ng-show='item.extra'>&#9758; </span><b title='{{item.label}} ({{item.type || item.attributes.type }}#{{item.id}})'><gu-sc-orig>{{item.label}}</gu-sc-orig></b> <span class='sc-muted'>{{item.type || item.attributes.type }}#{{item.id}}</span></div></md-item-template>" +
                "<md-not-found><div class='sc-item'><span ng-if='searchError' style='color:rgb(221,44,0);'>{{searchError}}</span><span ng-if='!searchError' ng-switch='searchText.length > 1'><span ng-switch-when='false'>Search requires at least 2 characters.</span><span ng-switch-default>No records matching '{{searchText}}' were found.<span></span>";
              if (hasCreatable) {
                html += "<a ng-click='refDialog($event)'> <b>Click here to create a new.</b></a>";
              }
              html += "</div></md-not-found><md-button class='md-icon-button' ng-if='scModel && !noX' ng-click='clearSelection($event)' aria-label='Clear' style='position: absolute; right: -5px; top: -3px;'><md-icon md-svg-src='icons/ic_close_24px.svg'></md-icon></md-button>" +
                "<md-button class='md-icon-button' ng-if='!scModel && scDescribe.defaultValue' ng-click='setDefault($event)' aria-label='Revert default' title='Revert default' style='position: absolute; right: -5px; top: -3px;'><md-icon md-svg-src='icons/ic_sync_24px.svg'></md-icon></md-button>";
              if (!$scSystem.portalUrl) {
                // if (hasCreatable) { // TODO is selected type updatable?
                //   html += "<md-button ng-if='scModel' class='md-icon-button reference-external-link' ng-if='scModel' ng-click='refDialog($event, scModel)' aria-label='Open in CRM' title='Open in CRM' style='position: absolute; right: 48px; top: -3px;'>" +
                //   "<md-icon class='md-primary' md-svg-src='icons/ic_mode_edit_24px.svg'></md-icon></md-button>";
                // }
                html += "<md-button class='md-icon-button reference-external-link' ng-if='scModel' ng-click='openInCRM($event)' aria-label='Open in CRM' title='Open in CRM' style='position: absolute; right: 20px; top: -3px;'>" +
                "<md-icon class='md-primary' md-svg-src='icons/ic_launch_24px.svg'></md-icon></md-button>";
                html += "<md-button class='md-icon-button fix-edit-icon' ng-if='scModel && hasEdit' ng-click='refDialog($event, scModel)' aria-label='Edit' title='Edit' style='position: absolute; right: 46px; top: -3px;'><md-icon md-svg-src='icons/ic_mode_edit_24px.svg'></md-icon></md-button>";
              }
              html += "<div ng-messages='form[scDescribe.label].$error'><div ng-message='custom'>{{form[scDescribe.label].$customError}}</div></div>";
              if (scope.scDescribe.helpText) {
                html += "<div class='md-errors-spacer'>{{scDescribe.helpText}}</div>";
              }
              html += "</md-autocomplete>";
            }
            if (hasCreatable) {
              html += "<md-button ng-disabled='disabled || disableCreate' aria-label='Create a new' class='md-icon-button md-mini md-raised sc-create-button' ng-click='refDialog($event)'>" +
                "<md-tooltip>Create New</md-tooltip><md-icon md-svg-src='icons/ic_library_add_24px.svg' md-menu-align-target></md-icon></md-button>";
            }
            html += "</md-input-container>";
          } else { //single reference
            html = "<md-input-container class='sc-reference-field' layout='row' layout-wrap style='padding: 0;'>";
            html += "<md-icon md-svg-src='icons/ic_find_in_page_24px.svg'></md-icon>";
            if (scope.scDescribe.multiple) {
              if (!scope.scModel) {
                scope.scModel = [];
              }
              if (scope.scDescribe.valueType || (scope.scDescribe.reference && scope.scDescribe.reference.valueType)) {
                scope.scModel.valueType = scope.scDescribe.valueType || scope.scDescribe.reference.valueType;
              }
              scope.scModel.valueArray = true;
              html += "<md-chips md-on-add='afterChipsChange()' md-on-remove='afterChipsChange()' flex ng-model='scModel' md-autocomplete-snap md-transform-chip='transformChip($chip)' md-require-match='true' md-add-on-blur='true'";
              html += buildValidationAttributes(scope.scDescribe) + " name='{{scDescribe.label}}'>";
              html += "<md-autocomplete ng-disabled='disabled'  md-delay='250' md-input-name='{{scDescribe.label}}' md-no-cache='true' md-min-length='2' md-selected-item='selectedItem' md-search-text='searchText' md-items='item in querySearch(searchText)'" +
                " md-item-text='item.label' placeholder='{{scDescribe.label}}' md-require-match>";
              html += "<md-item-template><div class='sc-item' ng-class='{\"extra\": item.extra}'><b title='{{item.label}} ({{item.type || item.attributes.type }}#{{item.id}})'><gu-sc-orig>{{item.label}}</gu-sc-orig></b> <span class='sc-muted' ng-if='item.id'>{{item.type || item.attributes.type }}#{{item.id}}</span></div></md-item-template><md-not-found><div class='sc-item'>No records matching \"{{searchText}}\" were found.</div></md-not-found>";
              html += "</md-autocomplete>";
              html += "<md-chip-template><strong>{{$chip.label || $chip.name}}</strong><span ng-if='$chip.type'>({{$chip.type}})</span></md-chip-template></md-chips>";
            } else {
              html += "<md-autocomplete ng-disabled='disabled' md-delay='250' md-input-name='{{scDescribe.label}}' md-no-cache='true' flex sc-field-id='{{scDescribe.name}}' md-input-name='{{scDescribe.label}}' md-min-length='0' md-selected-item='scModel' md-selected-item-change='selectedItemChange(item)'" +
                " md-search-text='searchText' md-items='item in querySearch(searchText)' md-item-text='item.label' md-require-match";
              html += buildValidationAttributes(scope.scDescribe);
              html += " md-floating-label='{{scDescribe.label}}'><md-item-template><div class='sc-item' ng-class='{\"extra\": item.extra}'><span ng-show='item.extra'>&#9758; </span><b title='{{item.label}} ({{item.type || item.attributes.type }}#{{item.id}})'><gu-sc-orig>{{item.label}}</gu-sc-orig></b> <span class='sc-muted'>{{item.type || item.attributes.type }}#{{item.id}}</span></div></md-item-template>" +
                "<md-not-found><div class='sc-item'><span ng-if='searchError' style='color:rgb(221,44,0);'>{{searchError}}</span><span ng-if='!searchError' ng-switch='searchText.length > 1'><span ng-switch-when='false'>Search requires at least 2 characters.</span><span ng-switch-default>No records matching '{{searchText}}' were found.<span></span>";
              if (scope.scDescribe.reference && scope.scDescribe.reference.creatable) {
                html += "<a ng-click='refDialog($event)'> <b>Click here to create a new.</b></a>";
              }
              html += "</div></md-not-found><md-button ng-disabled='disabled' class='md-icon-button' ng-if='scModel && !noX' ng-click='clearSelection($event)' aria-label='Clear' style='position: absolute; right: -5px; top: -3px;'><md-icon md-svg-src='icons/ic_close_24px.svg'></md-icon></md-button>" +
                "<md-button ng-disabled='disabled' class='md-icon-button' ng-if='!scModel && scDescribe.defaultValue' ng-click='setDefault($event)' aria-label='Revert default' title='Revert default' style='position: absolute; right: -5px; top: -3px;'><md-icon md-svg-src='icons/ic_sync_24px.svg'></md-icon></md-button>";
              if (!$scSystem.portalUrl) {
                // if (scope.scDescribe.reference.updatable || scope.scDescribe.reference.updateable) {
                //   html += "<md-button ng-if='scModel' class='md-icon-button reference-external-link' ng-if='scModel' ng-click='refDialog($event, scModel)' aria-label='Open in CRM' title='Open in CRM' style='position: absolute; right: 48px; top: -3px;'>" +
                //   "<md-icon class='md-primary' md-svg-src='icons/ic_mode_edit_24px.svg'></md-icon></md-button>";
                // }
                scope.hasEdit = scope.scDescribe.reference.updatable || (scope.scDescribe.reference.creatable && scope.scDescribe.reference.updatable !== false); 
                html += "<md-button class='md-icon-button reference-external-link' ng-if='scModel' ng-click='openInCRM($event)' aria-label='Open in CRM' title='Open in CRM' style='position: absolute; right: 20px; top: -3px;'>" +
                  "<md-icon class='md-primary' md-svg-src='icons/ic_launch_24px.svg'></md-icon></md-button>";
                html += "<md-button class='md-icon-button fix-edit-icon' ng-if='scModel && hasEdit' ng-click='refDialog($event, scModel)' aria-label='Edit' title='Edit' style='position: absolute; right: 46px; top: -3px;'><md-icon md-svg-src='icons/ic_mode_edit_24px.svg'></md-icon></md-button>";
              }
              html += "<div ng-messages='form[scDescribe.label].$error'><div ng-message='custom'>{{form[scDescribe.label].$customError}}</div></div>";
              if (scope.scDescribe.helpText) {
                html += "<div class='md-errors-spacer'>{{scDescribe.helpText}}</div>";
              }
              html += "</md-autocomplete>";
            }
            if (scope.scDescribe.reference && scope.scDescribe.reference.creatable) {
              html += "<md-button ng-disabled='disabled || disableCreate' aria-label='Create a new' class='md-icon-button md-mini md-raised sc-create-button' ng-click='refDialog($event)'>" +
                "<md-tooltip>Create New</md-tooltip><md-icon md-svg-src='icons/ic_library_add_24px.svg' md-menu-align-target></md-icon></md-button>";
            }
            html += "</md-input-container>";
          }
          scope.setDefault();
        } else if (['composite', 'address'].indexOf(scope.scDescribe.type) !== -1) {
          scope.layoutValue = (scope.scDescribe.fields && scope.scDescribe.fields.length && scope.scDescribe.fields[0].type === 'composite') ? 'column' : 'row';
          if (scope.scDescribe.subtype === 'column') {
            scope.layoutValue = 'column';
          }
          if (typeof(scope.scDescribe.hidden) === 'string') {
            scope.$watch('scOwner["'+scope.scDescribe.hidden+'"]', function(newValue) {
              scope.scDescribe.$hidden = !!newValue;
            }, true);
          }
          html = "<div ng-if='!scDescribe.$hidden' ng-class=\"{'compact': scDescribe.compact }\" class='sc-reference-field sc-composite-container' layout='{{layoutValue}}' layout-xs='" + (scope.scDescribe.compact ? 'row' : 'column') + "' layout-wrap>";
          for (var f = 0; f < scope.scDescribe.fields.length; f++) {
            var cfd = scope.scDescribe.fields[f];
            if (cfd && cfd.validations && cfd.validations.required) {
              isRequired = true;
            }
            if (scope.scDescribe.valueType === 'keyval') {
              cfd.name = scope.scDescribe.name + '.' + cfd.name;
            }
            if (typeof(scope.scOwner) == 'string' && scope.scDescribe.fields && !scope.scDescribe.fields[0].type) {
              html += "<sc-field sc-describe='scDescribe.fields[" + f + "]' sc-model='scOwner' sc-locks='scLocks' sc-connector-code='{{scConnectorCode}}' sc-change='scChange' sc-ref-change='scRefChange' sc-fields='scFields' flex></sc-field>"; 
            } else {
              if (scope.scDescribe.valueType === 'object') {
                if (scope.scOwner && !scope.scOwner[scope.scDescribe.name]) {
                  scope.scOwner[scope.scDescribe.name] = {};
                }
                html += "<sc-field sc-describe='scDescribe.fields[" + f + "]' sc-owner='scOwner[\"" + scope.scDescribe.name + "\"]' sc-locks='scLocks' sc-model='scOwner[\"" + scope.scDescribe.name + "\"][\"" + cfd.name + "\"]' sc-connector-code='{{scConnectorCode}}' sc-resource-type='{{scResourceType}}' sc-change='scChange' sc-ref-change='scRefChange' sc-fields='scFields' flex></sc-field>";
              } else {
                html += "<sc-field sc-describe='scDescribe.fields[" + f + "]' sc-owner='scOwner' sc-locks='scLocks' sc-model='scOwner[\"" + cfd.name + "\"]' sc-connector-code='{{scConnectorCode}}' sc-resource-type='{{scResourceType}}' sc-change='scChange' sc-ref-change='scRefChange' sc-fields='scFields' flex></sc-field>";
              }
            }
            if (scope.layoutValue !== 'column' && f > 0 && scope.scDescribe.fields.length > 6 && f !== (scope.scDescribe.fields.length - 1) && ((f + 1) % 4 === 0)) {
              html += "</div><div class='sc-reference-field' layout='row' layout-wrap style='margin-top: 12px;'>";
            }
          }
          html += "</div>";
        } else if (scope.scDescribe.type === 'list') {
          if (scope.scDescribe.hidden === 'UPDATE' && (scope.scOwner && scope.scOwner._getId)) {
            scope.scDescribe.$hidden = true;
          }
          if (scope.scOwner && scope.scOwner[scope.scDescribe.name] instanceof Array) {
            scope.scDescribe.$optional = false;
            scope.scShowSection = true;
          }
          scope.$watch('scOwner["'+scope.scDescribe.name+'"]', function(newValue) {
            if (newValue instanceof Array) {
              scope.scDescribe.$optional = false;
              scope.scShowSection = true;
            }
          }, true);
          scope.addItemToListType = function () {
            if (!scope.scModel || scope.scModel.length === 0) {
              scope.scModel = [];
            }
            if (typeof scope.scModel.valueArray === 'undefined') {
              scope.scModel.valueArray = true;
            }
            if (!scope.scModel.valueType) {
              scope.scModel.valueType = scope.scDescribe.valueType;
            }
            if (scope.scModel.length !== scope.scDescribe.limit) {
              if (scope.scDescribe.reference instanceof Array || scope.scDescribe.reference.type === 'composite') {
                scope.scModel.push({});
              } else {
                scope.scModel.length++;
              }
            } else $mdToast.showSimple('Max ' + scope.scDescribe.limit + ' fields allowed.');
            form.$setDirty();
            if (typeof scope.scChange === 'function') {
              $timeout(function() {
                scope.scChange(scope.scModel, scope.scDescribe);
              }, 0);
            }
          };
          scope.removeItemFromList = function (item) {
            var index;
            if (typeof item !== 'undefined') {
              index = scope.scModel.indexOf(item);
            } else {
              for (var jj = 0; jj < scope.scModel.length; jj++) {
                if (typeof scope.scModel[jj] === 'undefined') {
                  index = jj;
                  break;
                }
              }
            }
            if (index > -1) {
              scope.scModel.splice(index, 1);
              form.$setDirty();
            }
            if (typeof scope.scModel.valueArray === 'undefined') {
              scope.scModel.valueArray = true;
            }
            if (!scope.scModel.valueType) {
              scope.scModel.valueType = scope.scDescribe.valueType;
            }
            if (typeof scope.scChange === 'function') {
              $timeout(function() {
                scope.scChange(scope.scModel, scope.scDescribe);
              }, 0);
            }
          };
          if (scope.scDescribe.validations && scope.scDescribe.validations.required && !scope.scModel) {
            scope.addItemToListType();
          }
          if (scope.scDescribe.unique) { // VGIS-5475 (check for duplicate rules)
            scope.$watch('scModel', function() {
              delete scope.dupIndex;
              if (scope.scModel instanceof Array) {
                for (var r = scope.scModel.length - 1; r >= 0; r--) {
                  var rule = scope.scModel[r];
                  var restRules = scope.scModel.slice(0, r);
                  for (var rr = 0; rr < restRules.length; rr++) {
                    var cmpRule = restRules[rr];
                    if (angular.equals(cmpRule, rule)) {
                      scope.dupIndex = r;
                    }
                  }
                }
              }
            }, true);
          }
          scope.listLimit = scope.scDescribe.validations ? (scope.scDescribe.validations.max || scope.scDescribe.limit) : scope.scDescribe.limit;
          if (scope.scDescribe.reference instanceof Array) {
            html = "<div layout='column' layout-wrap>";
            html += "<div ng-repeat='i in scModel track by $index' layout='row'><div flex layout='column'>";
            html += "<sc-field ng-class='{ \"sc-reference-field\": $index, \"error\": errorIndex === $index }' ng-repeat='ref in scDescribe.reference' sc-describe='ref' sc-owner='i' sc-model='scOwner[i]' sc-connector-code='{{scConnectorCode}}' sc-resource-type='{{scResourceType}}' sc-change='scChange'></sc-field>";
            html += "</div><md-button ng-click='removeItemFromList(i)' aria-label='Remove' class='md-icon-button md-mini' style='margin-top: 20px; margin-bottom: -12px;'><md-icon md-svg-src='icons/ic_delete_forever_24px.svg'></md-icon></md-button>";
            html += "</div>";
            html += "<md-button ng-if='!listLimit || scModel.length !== listLimit' ng-click='addItemToListType()' aria-label='Add' >Add {{scDescribe.label}}</md-button>";
            html += "</div>";
          } else {
            html = "<md-input-container class='sc-reference-field' layout='column' layout-wrap>";
            html += "<div class='sc-list-label'>{{scDescribe.label}} ({{scModel.length || 0}})</div>";
            html += "<div ng-repeat='i in scModel track by $index' layout='row'>";
            html += "<sc-field ng-class='{ \"duplicate\": dupIndex === $index }' sc-describe='scDescribe.reference' sc-owner='scModel[$index]' sc-model='i' sc-connector-code='{{scConnectorCode}}' sc-resource-type='{{scDescribe.resourceType}}' sc-change='scChange' flex></sc-field>";
            html += "<md-button ng-click='removeItemFromList(i)' aria-label='Remove' class='md-icon-button md-mini' style='margin-top: 5px; margin-bottom: -12px;'><md-icon md-svg-src='icons/ic_delete_forever_24px.svg'></md-icon></md-button>";
            html += "</div>";
            html += "<md-button ng-if='!listLimit || scModel.length !== listLimit' class='md-primary' ng-click='addItemToListType()' aria-label='Add' md-colors='{ color: (!listLimit || scModel.length !== listLimit) ? \"primary-500\" : \"primary-100\" }'>Add {{scDescribe.reference.label}}</md-button>";
            html += "</md-input-container>";
          }
        } else if (scope.scDescribe.type === 'combobox') {
          scope.scDescribe.picklist.forEach(function(item) {
            item.extra = true;   //To look same as suggestions (applying css)
            if (item.defaultValue && !scope.scModel) {
              scope.scModel = item.id || item.value || item;
            }
          });
          html = "<md-autocomplete ng-disabled='disabled' md-selected-item-change='clearCustomError()' md-input-name='{{scDescribe.label}}' md-selected-item='selectedItem' md-no-cache='true' flex md-input-name='{{scDescribe.label}}' md-min-length='0'  " +
          " md-items='item in scDescribe.picklist' md-search-text='scModel' md-item-text='item.label' md-clear-button='true'";
          html += buildValidationAttributes(scope.scDescribe);
          html += " md-floating-label='{{scDescribe.label}}'><md-item-template><div class='sc-item' ng-class='{\"extra\": item.extra}'><span ng-show='item.extra'>&#9758; </span><gu-sc-orig>{{item.label}}</gu-sc-orig></div></md-item-template>";
          if (scope.scDescribe.helpText) {
            html += "<div class='md-errors-spacer'>{{scDescribe.helpText}}</div>";
          }
          html += "</md-autocomplete>";
        } else {
          html = "<md-input-container class='md-block' data-type='"+scope.scDescribe.type+"' ng-class='{ lockable: scDescribe.lockable }'>";
          if (scope.scDescribe.type === 'checkbox' && scope.scDescribe.picklist && scope.scDescribe.picklist.length) {
            scope.scDescribe.type = 'picklist';
          }
          switch (scope.scDescribe.type) {
            case 'radio':
              html = "<md-input-container class='sc-reference-field md-input-has-placeholder' layout='row' layout-wrap><div flex style='position: relative;'>";
              html += "<label>{{scDescribe.label}}</label>";
              html += "<md-radio-group ng-model='scModel' ng-change='clearCustomError()' name='{{scDescribe.label}}' style='display: block; margin-top: 11px; margin-bottom: 3px;'";
              //html += buildValidationAttributes(scope.scDescribe);
              html += "><md-radio-button style='display: inline; margin-right: 10px;' ng-repeat=\"option in scDescribe.picklist | orderObjectBy: 'label'\" ";
              if (scope.scDescribe.valueType === 'object') {
                html += "ng-value='option'>";
              } else {
                html += "ng-value='option.name || option.id'>";
              }
              html += "{{option.label}}</md-radio-button></md-radio-group>";
              html += "<div ng-messages='form[scDescribe.label].$error'><div ng-message='custom'>{{form[scDescribe.label].$customError}}</div></div>";
              if (scope.scDescribe.helpText) {
                html += "<div class='md-errors-spacer'>{{scDescribe.helpText}}</div>";
              }
              if (scope.scModel) {
                for (var v in scope.scDescribe.picklist) {
                  if (scope.scDescribe.picklist[v].id === (scope.scModel.id || scope.scModel)) {
                    if (scope.scDescribe.valueType === 'object') {
                      scope.scModel = scope.scDescribe.picklist[v];
                    } else {
                      scope.scModel = scope.scDescribe.picklist[v].name || scope.scDescribe.picklist[v].id || scope.scDescribe.picklist[v];
                    }
                    break;
                  }
                }
              } else {
                for (var p in scope.scDescribe.picklist) {
                  if (scope.scDescribe.picklist[p].defaultValue) {
                    if (!scope.scOwner.$$state) {
                      scope.scModel = scope.scDescribe.picklist[p];
                    } else {
                      scope.scOwner.finally(function() {
                        var stopWatchR = scope.$watch('scModel', function (newValue) {
                          if (typeof (newValue) !== 'undefined') {
                            stopWatchR();
                            for (var mv in scope.scDescribe.picklist) {
                              if (scope.scDescribe.picklist[mv].id === (newValue.id || newValue)) {
                                if (scope.scDescribe.valueType === 'object') {
                                  scope.scModel = scope.scDescribe.picklist[mv];
                                } else {
                                  scope.scModel = scope.scDescribe.picklist[mv].name || scope.scDescribe.picklist[mv].id || scope.scDescribe.picklist[mv];
                                }
                                break;
                              }
                            }
                          } else {
                            if (scope.scDescribe.valueType === 'object') {
                              scope.scModel = scope.scDescribe.picklist[p];
                            } else {
                              scope.scModel = scope.scDescribe.picklist[p].name || scope.scDescribe.picklist[p].id || scope.scDescribe.picklist[p];
                            }
                          }
                        });
                      });
                    }
                    break;
                  }
                }
                if (!scope.scModel && scope.scDescribe.defaultValue) { //fallback to outside defaultValue
                  for (var s in scope.scDescribe.picklist) {	
                    if ((scope.scDescribe.defaultValue.value || scope.scDescribe.defaultValue.label) === scope.scDescribe.picklist[s].id) {	
                      if (scope.scDescribe.valueType === 'object') {
                        scope.scModel = scope.scDescribe.picklist[s];
                      } else {
                        scope.scModel = scope.scDescribe.picklist[s].name || scope.scDescribe.picklist[s].id || scope.scDescribe.picklist[s];
                      }
                      break;	
                    }	
                  }
                }
                if (!scope.scModel && scope.scDescribe.validations && scope.scDescribe.validations.required && scope.scDescribe.picklist.length) { // fix incorrect metadata
                  if (scope.scDescribe.valueType === 'object') {
                    scope.scModel = scope.scDescribe.picklist[0];
                  } else {
                    scope.scModel = scope.scDescribe.picklist[0].name || scope.scDescribe.picklist[0].id || scope.scDescribe.picklist[0].name;
                  }
                }
              }
              html += "</div>";
              break;
            case 'picklist':
            case 'select':
              scope.isAsync = scope.scDescribe.async || scope.scDescribe.searchUrl || scope.scDescribe.searchParams;
              scope.isDependent = scope.scDescribe.dependentPicklist && scope.scDescribe.controllerName;
              scope.filterDependentOptions = function(value) {
                if (!scope.filteredOptions) {
                  scope.filteredOptions = [];
                }
                scope.filteredOptions.length = 0;
                var modelIsValid = false;
                for (var o in scope.scDescribe.picklist) {
                  var option = scope.scDescribe.picklist[o];
                  var valid = option.validFor === value;
                  if (option.validFor instanceof Array) {
                    valid = option.validFor.indexOf(value) !== -1;
                  }
                  if (valid) {
                    scope.filteredOptions.push(option);
                    if (scope.scModel && (scope.scModel === option || scope.scModel === option.id || scope.scModel.id === option.id)) {
                      modelIsValid = true;
                    }
                  }
                }
                if (!modelIsValid) {
                  scope.clearSelection();
                }
              };
              scope.loadAsyncOptions = function() {
                var searchUrl = $scSystem.baseUrl+'/v2/accounts/:accountId/users/:userId/connectors/:connector/resources/:resourceType';
                if ($scSystem.portalUrl) {
                  searchUrl = $scSystem.baseUrl+'/accounts/:accountId/users/:userId/integrations/:connector/resources/:resourceType';
                }
                searchUrl += (scope.scDescribe.searchUrl ? scope.scDescribe.searchUrl : '/fields/'+scope.scDescribe.name+'/search');
                function watchParentValuesChanges(newValue, oldValue) {
                  if (newValue != oldValue) {
                    scope.clearSelection();
                    scope.parentValueWatcher();
                  }
                }
                scope.scDescribe.picklist = [];
                if (scope.scDescribe.searchParams && scope.scOwner) {
                  for (var par in scope.scDescribe.searchParams) {
                    var parValue = scope.scOwner[scope.scDescribe.searchParams[par]];
                    if (parValue) {
                      searchUrl += ((searchUrl.indexOf('?') === -1 ? '?' : '&') + par + '=' +encodeURIComponent(parValue.id || parValue.value || parValue));
                      if (scope.parentValueWatcher) {
                        scope.parentValueWatcher(); //release previous watcher 
                      }
                      scope.parentValueWatcher = scope.$watch('scOwner["'+scope.scDescribe.searchParams[par]+'"]', watchParentValuesChanges, true);
                    } else {
                      var depLabel = scope.scDescribe.searchParams[par];
                      if (scope.scFields) {
                        for (var ff in scope.scFields) {  //finding dependent field
                          if (depLabel === scope.scFields[ff].name) {
                            depLabel = scope.scFields[ff].label;
                            break;
                          }
                        }
                      }
                      scope.scDescribe.picklist = [{ id: '-1', label: 'The options depend on another field. Please select "'+depLabel+'" first.', disabled: true}];
                      return;
                    }
                  }
                }
                var optionsService = new UnifiedService({ connector: scope.scConnectorCode }, {
                  search: {
                    method: 'GET',
                    isArray: true,
                    url: searchUrl
                  }
                });
              return optionsService.search({resourceType: scope.scResourceType || $rootScope.scResourceType}).$promise.then(function(options) {
                  if (options && options.length) {
                    scope.scDescribe.picklist = options;
                  } else {
                    scope.scDescribe.picklist = [{ id: '-1', label: 'No options available!', disabled: true}];
                  }
                }, function(err) {
                  scope.scDescribe.picklist = [{ id: '-1', label: err.data.message || err.data, disabled: true }];
                });
              };
              if (scope.isAsync) {
                if (scope.scModel && (!scope.scDescribe.picklist || !scope.scDescribe.picklist.length)) {
                  scope.loadAsyncOptions();
                } else {
                  scope.waitForModelWatcher = scope.$watch('scOwner["'+scope.scDescribe.name+'"]', function(newValue) {
                    if (newValue && (!scope.scDescribe.picklist || !scope.scDescribe.picklist.length)) {
                      scope.loadAsyncOptions();
                      scope.waitForModelWatcher();
                    }
                  }, true);
                }
              }
              if (scope.isDependent) {
                if (scope.scDescribe.picklist && scope.scDescribe.picklist.length) {
                  scope.$watch('scOwner["'+scope.scDescribe.controllerName+'"]', function(newValue) {
                    scope.filterDependentOptions(newValue);
                  }, true);
                } else {
                  scope.waitForModelWatcher = scope.$watch('scModel', function(newValue) {
                    if (newValue && scope.scDescribe.picklist && scope.scDescribe.picklist.length) {
                      scope.$watch('scOwner["'+scope.scDescribe.controllerName+'"]', function(newValue) {
                        scope.filterDependentOptions(newValue);
                      }, true);
                    }
                    scope.waitForModelWatcher();
                  }, true);
                }
              }
              // convert strings to int custom case TODO: remove me when BE fix it
              if (['date', 'datetime', 'integer'].indexOf(scope.scDescribe.valueType) !== -1 && scope.scDescribe.picklist) {
                for (var o in scope.scDescribe.picklist) {
                  if (scope.scDescribe.picklist[o]) {
                    scope.scDescribe.picklist[o].name = (scope.scDescribe.picklist[o].name === "0") ? "0" : parseInt(scope.scDescribe.picklist[o].name);
                  }
                }
                if (scope.scDescribe.defaultValue && scope.scDescribe.defaultValue.label && scope.scDescribe.createable) {
                  scope.scDescribe.defaultValue.label = parseInt(scope.scDescribe.defaultValue.label);
                }
              }
              html = "<md-input-container ng-class='{lockable: scDescribe.lockable}' class='sc-reference-field' layout='row' layout-wrap><div flex style='position: relative;'>";
              html += "<label>{{scDescribe.label}}</label>";
              html += "<md-select ng-class='{\"has-value\":scModel}' ng-model='scModel' name='{{scDescribe.label}}' aria-label='{{scDescribe.label}}' ng-change='afterChipsChange()' style='margin-right: 7px;'";
              if (scope.isAsync) {
                html += " md-on-open='loadAsyncOptions()'";
              } else { // we honor disabled conditions only when not async (otherwise md-on-open won't work)
                html += " ng-disabled='disabled || !(filteredOptions || scDescribe.picklist).length'"
              }
              if (scope.scDescribe.validations) { // fix incorrect metadata
                delete scope.scDescribe.validations.maxlength;
              }
              html += buildValidationAttributes(scope.scDescribe);
              if (scope.scDescribe.multiple) {
                html += " multiple='true'";
              }
              if (scope.scDescribe.valueType === 'object') {
                html += " ng-model-options=\"{trackBy: '$value.id || $value.name'}\"";
                html += "><md-option ng-repeat=\"option in (filteredOptions || scDescribe.picklist)";
                if (!scope.scDescribe.skipSort) {
                  html += " | orderObjectBy: 'label'";
                }
                html += "\" ng-value='option' ng-disabled='option.disabled'>{{option.label}}</md-option></md-select>";
              } else {
                html += "><md-option ng-repeat=\"option in (filteredOptions || scDescribe.picklist)";
                if (!scope.scDescribe.skipSort) {
                  html += " | orderObjectBy: 'label'";
                }
                html += "\" ng-value='option.name || option.id' ng-disabled='option.disabled'>{{option.label}}</md-option></md-select>";
              }
              html += "<md-button class='md-icon-button' ng-if='scModel && !noX' ng-click='clearSelection($event)' aria-label='Clear' style='position: absolute; right: -8px; top: -3px;' ng-disabled='disabled'><md-icon md-svg-src='icons/ic_close_24px.svg'></md-icon></md-button>";
              html += "<div ng-messages='form[scDescribe.label].$error'><div ng-message='custom'>{{form[scDescribe.label].$customError}}</div></div>";
              if (scope.scDescribe.helpText) {
                html += "<div class='md-errors-spacer'>{{scDescribe.helpText}}</div>";
              }
              html += "</div>";
              if (scope.scDescribe.reference && (scope.scDescribe.reference.createable || scope.scDescribe.reference.creatable)) {
                html += "<md-button style='margin-left: 5px;' aria-label='Create a new' class='md-icon-button md-mini md-raised sc-create-button' ng-click='refDialog($event)' ng-disabled='disabled || disableCreate'>" +
                  "<md-tooltip>Create New</md-tooltip><md-icon md-svg-src='icons/ic_library_add_24px.svg' md-menu-align-target></md-icon></md-button>";
              }
              if (!scope.scModel && (scope.scDescribe.createable !== false)) {
                if (scope.scDescribe.valueType === "object" && scope.scDescribe.defaultValue) {
                  scope.scModel = scope.scDescribe.defaultValue;
                }
                if (!scope.scDescribe.multiple) {
                  for (var option in scope.scDescribe.picklist) {
                    if (scope.scDescribe.picklist[option].defaultValue) {
                      if (scope.scDescribe.valueType !== "object") {
                        scope.scModel = scope.scDescribe.picklist[option].name || scope.scDescribe.picklist[option].id || scope.scDescribe.picklist[option].value || scope.scDescribe.picklist[option];
                      } else {
                        scope.scModel = scope.scDescribe.picklist[option].name || scope.scDescribe.picklist[option];
                      }
                      break;
                    }
                  }
                }
              }
              if (scope.scDescribe.multiple && scope.scDescribe.valueType === "object") {
                if (!scope.scModel) {
                  scope.scModel = [];
                }
                scope.scModel.valueArray = true;
                scope.scModel.valueType = scope.scDescribe.valueType;
              }
              break;
            case 'multipicklist':
              html += "<label>{{scDescribe.label}}</label>";
              html += "<md-select multiple='true' ng-model='scModel' name='{{scDescribe.label}}' aria-label='{{scDescribe.label}}' ng-change='clearCustomError()'";
              html += buildValidationAttributes(scope.scDescribe);
              html += "><md-option ng-repeat='option in scDescribe.picklist' ng-value='option.name'>{{option.label}}</md-option></md-select>";
              html += "<div ng-messages='form[scDescribe.label].$error'><div ng-message='custom'>{{form[scDescribe.label].$customError}}</div></div>";
              if (scope.scDescribe.helpText) {
                html += "<div class='md-errors-spacer'>{{scDescribe.helpText}}</div>";
              }
              break;
            case 'int':
            case 'integer':
            case 'number':
            case 'double':
            case 'float':
            case 'bigdecimal':
            case 'percent':
            case 'currency':
              html += "<label>{{scDescribe.label}}</label>";
              html += "<input type='number' ng-model='scModel' name='{{scDescribe.label}}' ng-change='clearCustomError()'";
              if (['double', 'float', 'bigdecimal'].indexOf(scope.scDescribe.type) !== -1) {
                html += " step='0.01'";
              } else if (['integer'].indexOf(scope.scDescribe.type) !== -1) {
                html += " step='1'";
              }
              html += buildValidationAttributes(scope.scDescribe) + ">";
              html += "<div ng-messages='form[scDescribe.label].$error'><div ng-message='custom'>{{form[scDescribe.label].$customError}}</div></div>";
              if (scope.scDescribe.helpText) {
                html += "<div class='md-errors-spacer'>{{scDescribe.helpText}}</div>";
              }
              break;
            case 'boolean':
            case 'checkbox':
              if (scope.scDescribe.subtype === 'switch') {
                html += "<md-switch style='margin: 0; flex: 1;' ng-model='scModel' aria-label='{{scDescribe.label}}' class='md-accent' name='{{scDescribe.label}}' ng-change='clearCustomError()'";
                html += buildValidationAttributes(scope.scDescribe);
                html += "ng-false-value='false'>{{scDescribe.label}}</md-switch>";
              } else {
                html += "<md-checkbox style='margin-bottom: 0;' ng-model='scModel' aria-label='{{scDescribe.label}}' class='md-primary' name='{{scDescribe.label}}' ng-change='clearCustomError()'";
                html += buildValidationAttributes(scope.scDescribe);
                html += "ng-false-value='false'>{{scDescribe.label}}</md-checkbox>";
              }
              html += "<div ng-messages='form[scDescribe.label].$error'><div ng-message='custom'>{{form[scDescribe.label].$customError}}</div></div>";
              if (scope.scDescribe.helpText) {
                html += "<div class='md-errors-spacer'>{{scDescribe.helpText}}</div>";
              }
              if (typeof (scope.scModel) !== 'boolean' && scope.scDescribe.defaultValue) {
                scope.scModel = scope.scDescribe.defaultValue.value || false;
                if (scope.scDescribe.defaultValue.label === 'true') {
                  scope.scModel = true;
                }
              }
              break;
            case 'switch':
              html += "<md-switch style='margin: 0; flex: 1;' md-invert ng-model='scModel' aria-label='{{scDescribe.label}}' class='md-accent' name='{{scDescribe.label}}' ng-change='clearCustomError()'";
              html += buildValidationAttributes(scope.scDescribe);
              html += "ng-false-value='false'>{{scDescribe.label}}</md-switch>";
              html += "<div ng-messages='form[scDescribe.label].$error'><div ng-message='custom'>{{form[scDescribe.label].$customError}}</div></div>";
              if (scope.scDescribe.helpText) {
                html += "<div class='md-errors-spacer'>{{scDescribe.helpText}}</div>";
              }
              if (typeof (scope.scModel) !== 'boolean' && scope.scDescribe.defaultValue) {
                scope.scModel = scope.scDescribe.defaultValue.value || false;
                if (scope.scDescribe.defaultValue.label === 'true') {
                  scope.scModel = true;
                }
              }
              break;
            case 'date':
              html += "<label>{{scDescribe.label}}</label>"; //html += "<md-datepicker ng-model='scModel'"+buildValidationAttributes(scope.scDescribe)+"></md-datepicker>";
              html += "<input type='date' ng-model='scModel' name='{{scDescribe.label}}' ng-change='clearCustomError()'" + buildValidationAttributes(scope.scDescribe) + ">";
              html += "<div ng-messages='form[scDescribe.label].$error'><div ng-message='custom'>{{form[scDescribe.label].$customError}}</div></div>";
              if (scope.scDescribe.helpText) {
                html += "<div class='md-errors-spacer'>{{scDescribe.helpText}}</div>";
              }
              break;
            case 'datetime':
              html += "<label>{{scDescribe.label}}</label>";
              html += "<input type='datetime-local' step='1' ng-model='scModel' name='{{scDescribe.label}}' ng-change='clearCustomError()'" + buildValidationAttributes(scope.scDescribe) + ">";
              html += "<div ng-messages='form[scDescribe.label].$error'><div ng-message='custom'>{{form[scDescribe.label].$customError}}</div></div>";
              if (scope.scDescribe.helpText) {
                html += "<div class='md-errors-spacer'>{{scDescribe.helpText}}</div>";
              }
              break;
            case 'textarea':
              html += "<label>{{scDescribe.label}}</label>";
              html += "<textarea ng-model='scModel' rows='{{scDescribe.rows || 3}}' name='{{scDescribe.label}}' ng-change='clearCustomError()'" + buildValidationAttributes(scope.scDescribe) + "></textarea>";
              html += "<div ng-messages='form[scDescribe.label].$error'><div ng-message='custom'>{{form[scDescribe.label].$customError}}</div></div>";
              if (scope.scDescribe.helpText) {
                html += "<div class='md-errors-spacer'>{{scDescribe.helpText}}</div>";
              }
              /*
              if (speechRecognitionEnabled) {
                html += "<md-button ng-click='speechRecognition($event)' class='md-icon-button sc-spech-recognition-button' ng-class=\"{ 'sc-sr-active md-accent': srActive }\"><md-tooltip md-direction='left'>Speech Recognition</md-tooltip><md-icon md-svg-src=\"{{ srActive ? 'icons/ic_mic_off_24px.svg' : 'icons/ic_mic_24px.svg' }}\"></md-icon></md-button>";
                html += "<md-progress-linear class='md-accent' md-mode='query' ng-disabled='!srActive'></md-progress-linear>";
              }
              */
              break;
            case 'email':
              html += "<label>{{scDescribe.label}}</label>";
              html += "<input type='email' ng-model='scModel' name='{{scDescribe.label}}' ng-change='clearCustomError()'" + buildValidationAttributes(scope.scDescribe) + ">";
              html += "<div ng-messages='form[scDescribe.label].$error'><div ng-message='custom'>{{form[scDescribe.label].$customError}}</div></div>";
              if (scope.scDescribe.helpText) {
                html += "<div class='md-errors-spacer'>{{scDescribe.helpText}}</div>";
              }
              break;
            case 'phone':
              html += "<label>{{scDescribe.label}}</label>";
              html += "<input type='tel' ng-model='scModel' name='{{scDescribe.label}}' ng-change='clearCustomError()'" + buildValidationAttributes(scope.scDescribe) + ">";
              html += "<div ng-messages='form[scDescribe.label].$error'><div ng-message='custom'>{{form[scDescribe.label].$customError}}</div></div>";
              if (scope.scDescribe.helpText) {
                html += "<div class='md-errors-spacer'>{{scDescribe.helpText}}</div>";
              }
              break;
            case 'text':
            case 'string':
              html += "<label>{{scDescribe.label}}</label>";
              if (scope.scDescribe.multiple) {
                if (!scope.scModel) {
                  scope.scModel = [];
                }
                html += "<md-chips md-on-add='afterChipsChange()' md-on-remove='afterChipsChange()' class='no-margin' placeholder='+add' ng-model='scModel' md-removable='true' name='{{scDescribe.label}}' ng-change='clearCustomError()' md-add-on-blur='true'" + buildValidationAttributes(scope.scDescribe) + "></md-chips>";
              } else {
                html += "<input type='text' ng-model='scModel' name='{{scDescribe.label || scDescribe.name}}' ng-change='clearCustomError()'" + buildValidationAttributes(scope.scDescribe) + ">";
              }
              html += "<div ng-messages='form[scDescribe.label || scDescribe.name].$error'><div ng-message='custom'>{{form[scDescribe.label].$customError}}</div><div ng-message='pattern'>Invalid value</div></div>";
              if (scope.scDescribe.helpText) {
                html += "<div class='md-errors-spacer'>{{scDescribe.helpText}}</div>";
              }
              if ((typeof (scope.scModel) === 'undefined') && scope.scDescribe.defaultValue) {
                scope.scModel = scope.scDescribe.defaultValue.value || scope.scDescribe.defaultValue.id || scope.scDescribe.defaultValue.label;
              }
              break;
            case 'blank':
              break;
            default:
              if (scVgisEnv === 'dev') {
                console.info('Metadata unknown field type "'+scope.scDescribe.type+'"', scope.scDescribe, scope.scOwner);
              }
              html += "<label>{{scDescribe.label}}</label><input type='";
              html += ['color', 'month', 'search', 'tel', 'week', 'url', 'password', 'time'].indexOf(scope.scDescribe.type) !== -1 ? scope.scDescribe.type : 'text';
              html += "' ng-model='scModel' name='{{scDescribe.label || scDescribe.name}}' ng-change='clearCustomError()'" + buildValidationAttributes(scope.scDescribe) + " ng-disabled='disabled'>";
              html += "<div ng-messages='form[scDescribe.label || scDescribe.name].$error'><div ng-message='custom'>{{form[scDescribe.label].$customError}}</div><div ng-message='pattern'>Invalid value</div></div>";
              if (scope.scDescribe.helpText) {
                html += "<div class='md-errors-spacer'>{{scDescribe.helpText}}</div>";
              }
              // if (!scope.scModel && scope.scDescribe.defaultValue) {
              //   scope.scModel = scope.scDescribe.defaultValue.label || scope.scDescribe.defaultValue.value;
              //   if (typeof(scope.scModel) === "string" && scope.scDescribe.type === "time") {
              //     scope.scModel = new Date(parseInt(scope.scModel));
              //   }
              // } // this is repeating code 6 lines below
          }
          if (scope.scLocks && scope.scLocks.$level && scope.scDescribe.lockable) {
            html += "<md-button ng-click='toggleLock()' class='md-icon-button sc-lock-button'><md-tooltip>{{ scLocks[scDescribe.name] ? 'Allow' : 'Disallow' }} {{ scLocks.$level === 'user' ? 'current user' : 'individual users' }} to change {{scDescribe.label}}</md-tooltip><md-icon>{{ scLocks[scDescribe.name] ? 'lock' : 'lock_open' }} </md-icon></md-button>";
          }
          html += "</md-input-container>";
          if ((typeof (scope.scModel) === 'undefined')) {
            if (scope.scDescribe && scope.scDescribe.defaultValue && (scope.scDescribe.creatable !== false || scope.scDescribe.createable !== false)) {
              convertValueByType(scope.scDescribe.defaultValue.value || scope.scDescribe.defaultValue.id || scope.scDescribe.defaultValue.label);
            }
            var stopWatch = scope.$watch('scModel', function (newValue) {
              if (typeof (newValue) !== 'undefined') {
                convertValueByType(newValue);
                stopWatch();
              } // continue watching if undefined
            });
          }
        }
        element.html(html);
        if (typeof (scope.scDescribe.createable) !== 'undefined' && !scope.scDescribe.createable && (scope.scOwner && !scope.scOwner._getId)) {
          scope.scDescribe.$auto = true;
        }
        if (
          scope.scDescribe.type === 'list' ||
          (scope.scDescribe.createable && !(isRequired || scope.scDescribe.alwaysVisible || scope.scDescribe.visible) && (!scope.scModel || (scope.scModel instanceof Array && !scope.scModel.length)))
        ) {
          scope.scDescribe.$optional = true;
          scope.scOptionalCount += 1;
        } else {
          scope.scDescribe.$optional = false;
          if (scope.scModel || scope.scDescribe.alwaysVisible || scope.scDescribe.visible) {
            scope.scShowSection = true;
          } else {
            if (scope.scOwner && scope.scOwner._getId && (scope.scDescribe.updatable || typeof(scope.scDescribe.updatable) === 'undefined')) {
              scope.scShowSection = true;
            } else if (
              (scope.scDescribe.creatable || typeof(scope.scDescribe.creatable) === 'undefined') || 
              (scope.scDescribe.createable || typeof(scope.scDescribe.createable) === 'undefined')
            ) {
              scope.scShowSection = true;
            }
          }
        }

        if (!$scSystem.portalUrl) {   
          scope.scDescribe.disabled = false; //dirty fix (disabling reference fields in portal but not in extension)
        } else {
          scope.disableCreate = scope.scConnectorCode !== 'portal'; //disable create only in layout builder
        }
        Object.defineProperty(scope, 'disabled', {
          get: function () {
            return (scope.scDescribe && scope.scDescribe.disabled) || (scope.scOwner && scope.scOwner._getId && scope.scDescribe.updateable === false);
          }
        });

        // try to fix incompatible dates
        if (['date', 'datetime', 'time'].indexOf(scope.scDescribe.type) !== -1 && ['number', 'string'].indexOf(typeof(scope.scModel)) !== -1) {
          scope.scModel = new Date(scope.scModel);
          if (scope.scDescribe.type === 'date') { //VGIS-2016
            scope.scModel.setFullYear(scope.scModel.getUTCFullYear());
            scope.scModel.setMonth(scope.scModel.getUTCMonth());
            scope.scModel.setDate(scope.scModel.getUTCDate());
            scope.scModel.setMinutes(0);
            scope.scModel.setHours(0);
            scope.scModel.setSeconds(0);
            scope.scModel.setMilliseconds(0);
            scope.scModel.patchTimezone = true;
          } else if (scope.scDescribe.type === 'time') {
            scope.scModel.setFullYear(1970);
            scope.scModel.setMonth(0);
            scope.scModel.setDate(1);
            scope.scModel.setMinutes(scope.scModel.getUTCHours());
            scope.scModel.setHours(scope.scModel.getUTCMinutes());
            scope.scModel.setSeconds(scope.scModel.getUTCSeconds());
            scope.scModel.patchTimezone = true;
          }
        }

        // loading order fix
        if (scope.scModel && scope.scModel.type && scope.scDescribe && scope.scDescribe.reference) {
          var ref;
          if (scope.scDescribe.reference instanceof Array) {
            for (var t = 0; t < scope.scDescribe.reference.length; t++) {
              if (scope.scDescribe.reference[t].name == scope.scModel.type) {
                ref = scope.scDescribe.reference[t];
                break;
              }
            }
          } else {
            ref = scope.scDescribe.reference;
          }
          if (ref && ref.valueType) {
            scope.scModel.valueType = ref.valueType;
          }
        }
        $compile(element.contents())(scope);
      }
    };
  });