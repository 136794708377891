angular.module("sc.common.fixedTableHead", [
  'sc'
]).directive('scFixedTableHead', function ($window) {
  var resizeListener;
  return {
    restrict: 'A',
    scope: {
      scFixedTableHead: '='
    },
    link: function (scope, el) {
      var timer;
      var origHead = el[0];
      var fixedHead = origHead.cloneNode(true);
      for (var n = 0; n < fixedHead.rows[0].childNodes.length; n++) { //Firefox puts text node between cells
        var node = fixedHead.rows[0].childNodes[n];
        if (node.nodeType === 3) {
          fixedHead.rows[0].removeChild(node);
        }
      }
      //fixedHead.style.position = 'fixed';
      var fixedBar = origHead.parentNode.cloneNode();
      fixedBar.appendChild(fixedHead);
      fixedBar.style.display = 'none';
      fixedBar.style.position = 'absolute';
      fixedBar.style.marginTop = '-1px;';
      fixedBar.style.zIndex = 1;
      fixedBar.style.width = origHead.parentNode.offsetWidth+'px';
      //console.log(origHead.parentNode.parentNode.parentNode.parentNode);
      origHead.parentNode.parentNode.parentNode.parentNode.append(fixedBar);
      origHead.parentNode.style.paddingBottom = 'initial';
      function copyWidths() {
        fixedHead.style.position = 'absolute';
        for (var i = 0; i < origHead.rows[0].cells.length; i++) {
          var cell = origHead.rows[0].cells[i];
          var computedWidth = window.getComputedStyle ? window.getComputedStyle(cell).getPropertyValue('width') : (cell.currentStyle || {}).width;
          var computedDisplay = window.getComputedStyle ? window.getComputedStyle(cell).getPropertyValue('display') : (cell.currentStyle || {}).display;
          if (computedWidth) {
            var widthMatch = computedWidth.match(/(\d+)\.?\d*/);
            if (widthMatch) {
              fixedHead.rows[0].cells[i].style.width = parseInt(widthMatch[1])+'px';
            } else {
              fixedHead.rows[0].cells[i].style.width = computedWidth;
            }
          } else {
            fixedHead.rows[0].cells[i].style.width = cell.offsetWidth+'px';
          }
          fixedHead.rows[0].cells[i].style.display = computedDisplay || 'inline-block';
        }
        fixedBar.style.display = 'initial';
        fixedHead.style.width = origHead.parentNode.offsetWidth+'px';
        fixedHead.style.overflow = 'hidden';
        fixedHead.style.height = '18px';
        timer = null;
      }
      copyWidths();

      if (scope.scFixedTableHead) {
        var origCallback = scope.scFixedTableHead.callback;
        scope.scFixedTableHead.callback = function() {
          if (!timer) {
            timer = setTimeout(copyWidths, 0);
            if (typeof(origCallback) === 'function') {
              origCallback();
            }
          }
        };
        scope.$watch(function() {
          return scope.scFixedTableHead.topIndex;
        }, function () {
          if (!timer) {
            timer = setTimeout(copyWidths, 0);
          }
        });
      }
      if (!resizeListener) {
        resizeListener = angular.element($window).bind('resize', copyWidths);
      }
    }
  };
});