

// Sharing sessionStorage between tabs for secure multi-tab authentication
if (!sessionStorage.length) {
  // Ask other tabs for session storage
  localStorage.setItem('getSessionStorage', Date.now());
}

function updateSessionStore(newValue) {
  var data = JSON.parse(newValue);
  sessionStorage.clear();
  for (var key in data) {
    sessionStorage.setItem(key, data[key]);
  }
}

function onStorageEvent(event) {
  //console.log('storage event', event);
  if (sessionStorage.getItem('scWebLogin')) {
    if (event.key == 'getSessionStorage') {
      // Some tab asked for the sessionStorage -> send it
      localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
      localStorage.removeItem('sessionStorage');
    } else if (event.key == 'updateSessionStorage') {
      updateSessionStore(event.newValue);
      if (!sessionStorage.getItem('accessToken') && location.pathname !== '/login') { //shared session tab logout
        location.href = '/login';
      }
    }
  } else if (event.key == 'sessionStorage' && !sessionStorage.length) {
    updateSessionStore(event.newValue);
  }
}

window.addEventListener('storage', onStorageEvent);
