
var url = scDevEnv ? 'https://extensions.gunify-'+scVgisEnv+'.vocal-'+(scVgisEnv === 'dev' ? 'dev' : 'qa')+'.com' : 'https://extensions.gunify.vonage.com';
var defaultConfig = {
  scUI: 'vgis',
  scServerHost: url+"/extensions",
  scWebHost: url+"/sc/web",
  authClientId: typeof(scPortal) !== 'undefined' ? 'gUnifyPortal' : 'gUnifyConnector',
  activeCallStates: ["INITIALIZING", "RINGING", "ACTIVE", "HELD", "REMOTE_HELD"],
  talkingCallStates: ["ACTIVE", "HELD", "REMOTE_HELD"],
  releasedCallStates: ["DETACHED", "REJECTED", "CANCELLED", "ANSWERED", "MISSED", "DELIVERED"]
};

Object.defineProperty(defaultConfig, 'authSecret', {
  get: function() {
    if (defaultConfig.scServerHost === 'https://extensions.gunify.vonage.com/extensions') {
      return 'Afi5YPcYvziF9AHAWw52WXEll79wJ93T7pDZMXB59JzTbl0J';
    } else {
      return scDevEnv ? 'rnDsXU98y4Ncv5D4Sb6eZFdJQCydwZVGFsYYmKTpa2RSKCw2' : 'Afi5YPcYvziF9AHAWw52WXEll79wJ93T7pDZMXB59JzTbl0J';
    }
  },
  configurable: true
});

module.exports = defaultConfig;