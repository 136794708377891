angular.module('sc.model.systemRole', [
  'sc',
  'ngResource'
]).factory('SystemRoleFactory', /*@ngInject*/ function($injector, $resource, $scSystem) {
  var RoleResource = function () {  //attributes parameter is unused
    var Role = $resource($scSystem.portalUrl+'/system/roles/:id', {
      id: '@code'
    });

    return Role;
  };

  return RoleResource;
});