var formErrorParser = require('../../common/utils/formErrorParser');

module.exports = /*@ngInject*/ function ($scope, $mdDialog, IntegrationFactory, gEmailNotificationService, gUser, selectedUsers) {
  var self = this;
  self.integrationsMap = {};
  self.userIntegrations = gUser.integrations;
  self.selectedUsers = selectedUsers;

  for (var i = 0; i < self.userIntegrations; i++) {
    var code = self.userIntegrations[i].code;
    self.integrationsMap[code] = false;
  }

  self.email = {
    integrations: []
  };
  
  self.cancel = function () {
    $mdDialog.cancel('cancel');
  };

  self.send = function() {
    delete self.formErrors;
    angular.forEach(self.integrationsMap, function (value, key) {
      if (value === true) {
        self.email.integrations.push(key);
      }
    });
    if (self.emailForm.$valid) {
      self.busyMask = true;
      if (self.email && self.selectedUsers) {
        self.email.userIds = self.selectedUsers;
        delete self.email.integrations;
      }
      gEmailNotificationService.create(self.email, function (info) {
        $mdDialog.hide(info);
      }, function (errorResponse) {
        self.formErrors = formErrorParser(errorResponse, self.emailForm, self.niceFields);   
        self.busyMask = false;
      });
    } else {
      self.emailForm.$setDirty(true);
      var errorNames = [];
      angular.forEach(self.emailForm.$error, function (type) {
        angular.forEach(type, function (field) {
          field.$touched = true;
          field.$setDirty();
          errorNames.push(field.$name);
        });
      });
      if (self.email.integrations.length === 0) {
          errorNames.push("Integrations");
      }
      self.formErrors = "Please fill out the required fields: " + errorNames.join(", ") + ".";
    }
  };
};