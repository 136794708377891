angular.module("sc.common.scp", [
  'sc'
]).value('scpHas', function (has, p) {
  var ps = (p instanceof Array) ? p : [p];
  for (var i = 0; i < ps.length; i++) {
    if (has(ps[i].p, ps[i].o, ps[i].id)) {
      return true;
    }
  }
}).directive('scpShow', /*@ngInject*/ function ($rootScope, scpHas) {
  return {
    restrict: 'A',
    scope: {
      scpShow: '='
    },
    link: function (scope, el) {
      if (!scpHas($rootScope.scPermissions.has, scope.scpShow)) {
        el.remove();
      }
    }
  };
}).directive('scpHide', /*@ngInject*/ function ($rootScope, scpHas) {
  return {
    restrict: 'A',
    scope: {
      scpHide: '='
    },
    link: function (scope, el) {
      if (scpHas($rootScope.scPermissions.has, scope.scpHide)) {
        el.remove();
      }
    }
  };
}).directive('scpDisable', /*@ngInject*/ function ($rootScope, scpHas) {
  return {
    restrict: 'A',
    scope: {
      scpDisable: '='
    },
    link: function (scope, el) {
      if (scpHas($rootScope.scPermissions.has, scope.scpDisable)) {
        el.attr('DISABLED', true);
        el.attr('title', 'Require permission '+scope.scpDisable.p);
        angular.element(el[0].querySelector('button')).attr('DISABLED', true);
      }
    }
  };
}).directive('scpEnable', /*@ngInject*/ function ($rootScope, scpHas) {
  return {
    restrict: 'A',
    scope: {
      scpEnable: '='
    },
    link: function (scope, el) {
      if (!scpHas($rootScope.scPermissions.has, scope.scpEnable)) {
        el.attr('DISABLED', true);
        el.attr('title', 'Require permission '+scope.scpEnable.p);
        angular.element(el[0].querySelector('button')).attr('DISABLED', true);
      }
    }
  };
});