angular.module('sc.system.integrations', [
  'ui.router'
]).config(function ($stateProvider) {
  $stateProvider.state('sc.system.integrations', {
    url: '/integrations',
    template: require('./integrations.html'),
    controller: require('./integrationsCtrl'),
    controllerAs: "integrationsCtrl",
    onEnter: /*@ngInject*/ function ($portalHead) {
      $portalHead.title = 'System Integrations';
    }
  });
});