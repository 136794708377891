module.exports = /*@ngInject*/ function($mdDialog, gIdentityId, gIdentity, gUser) {
  var self = this;
  self.gIdentityId = gIdentityId;
  self.gIdentity = gIdentity;
  self.gUser = gUser;

  self.cancel = function() {
    $mdDialog.cancel('cancel');
  };

  self.save = function() {
    self.gUser.$updateIdentity(self.gIdentity, $mdDialog.hide, function(err) {
      self.formErrors = err.data;
    });
  };
};