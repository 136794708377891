angular.module('sc.portal.import', [

]).controller('ImportCtrl', require('./importCtrl'));

require('ng-cache-loader?-url,module=sc.portal.import,prefix=src:**!./import.htm');

require('ng-cache-loader?-url,module=sc.portal.import,prefix=src:**!./ucp.htm');
require('ng-cache-loader?-url,module=sc.portal.import,prefix=src:**!./csv.htm');
require('ng-cache-loader?-url,module=sc.portal.import,prefix=src:**!./google.htm');
require('ng-cache-loader?-url,module=sc.portal.import,prefix=src:**!./bullhorn.htm');
require('ng-cache-loader?-url,module=sc.portal.import,prefix=src:**!./salesforce.htm');