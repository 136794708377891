var defaultConfig = require('../../gcf/sc/defaultConfig');
angular.module('sc.portal.auth', [
]).provider('$portalAuth', function () {
  var self = this;

  self.getTokens = function (code, callback) {
    sessionStorage.removeItem('token');
    self.http({
      method: 'POST',
      url: '/extensions/v2/auth/token',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      transformRequest: function (obj) {
        var str = [];
        for (var p in obj) {
          if(obj[p]) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          }
        }
        return str.join("&");
      },
      data: {
        code: code,
        client_id: defaultConfig.authClientId,
        client_secret: defaultConfig.authSecret,
        grant_type: 'authorization_code',
        redirect_uri: location.origin
      },
      skipAuthorization: true
    }).then(function (response) {
      sessionStorage.setItem('accessToken', response.data.access_token);
      sessionStorage.setItem('refreshToken', response.data.refresh_token);
      var tokenExpire = new Date();
      tokenExpire.setSeconds(tokenExpire.getSeconds() + (parseInt(response.data.expires_in) - 6));
      sessionStorage.setItem('tokenExpire', tokenExpire.getTime());
      sessionStorage.setItem('wUserId', response.data.user_id);
      sessionStorage.setItem('wAccountId', response.data.account_id);
      sessionStorage.setItem('scWebLogin', true);
      // shared tab session
      localStorage.setItem('updateSessionStorage', JSON.stringify(sessionStorage));
      localStorage.removeItem('updateSessionStorage');
      callback(null, response.data);
    }, function (errorResponse) {
      sessionStorage.removeItem('accessToken');
      sessionStorage.removeItem('refreshToken');
      // shared tab session
      localStorage.setItem('updateSessionStorage', JSON.stringify(sessionStorage));
      localStorage.removeItem('updateSessionStorage');
      callback(errorResponse);
    });
  };

  self.refreshToken = function () {
    //callback parameter is never used
  };

  self.getAuthorization = function () {
    //getAuthorization for future use
  };

  self.$get = function ($http) {
    self.http = $http;
    return {
      getTokens: self.getTokens,
      refreshToken: self.refreshToken,
      getAuthorization: self.getAuthorization
    };
  };
});