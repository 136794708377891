var VirtualRepeatLoader = require('../../gcf/sc/common/virtualRepeatRestfulLoader');

require('ng-cache-loader?module=sc.portal,prefix=src:**!./_usersFilters.htm');

module.exports = /*@ngInject*/ function ($scope, $rootScope, $window, $state, $stateParams, VgisUser, VgisUcp, VgisIntegration) {
  var self = this;
  self.selection = {};
  self.filter = {
    statuses: ['ACTIVE', 'PENDING']
  };
  if (!$stateParams.clearSearch && location.hash) {
    var params = location.hash.substr(1).split('&');
    params.forEach(function(pair) {
      var kv = pair.split('=');
      var key = kv[0];
      var value = decodeURIComponent(kv[1]);
      if (['integrationCodes', 'ucpIds'].indexOf(key) === -1) {
        self.filter[key] = value;
      } else {
        self.filter[key] = value.split(',');
      }
    });
  }

  if (!$rootScope.scPermissions.has('_LIST', 'USER')) {
    self.forbidden = true;
    self.error = 'Missing permission list all users';
    return;
  }

  VgisUcp.query(function (ucps) {
    self.ucps = ucps;
  }, function (err) {
    console.error(err);
  });

  VgisIntegration.query(function (integrations) {
    self.integrations = integrations.map(function (i) {
      return { id: i.code, label: i.name };
    });
  }, function (err) {
    console.error(err);
  });

  self.dynamicFetch = new VirtualRepeatLoader(VgisUser, {
    filters: function () {
      var query = {};
      if (self.filter.q) {
        query.q = self.filter.q;
      }
      if (self.filter.integrationCodes) {
        query.integrationCodes = self.filter.integrationCodes.join(',');
      }
      if (self.filter.ucpIds) {
        query.ucpIds = self.filter.ucpIds.join(',');
      }
      if (self.filter.statuses) {
        query.statuses = self.filter.statuses.join(',');
      }
      return query;
    },
    onUpdate: function () {
      setTimeout(function () {
        if (!$rootScope.$$phase) {
          $rootScope.$apply();
        }
      });
    }
  }, $scope);

  setTimeout(function () {
    self.ready = true;
    angular.element($window).triggerHandler('resize');
  }, 1);

  self.submit = function () {
    if (self.dynamicFetch && self.dynamicFetch.loaded) {
      if (!self.prevFilter) {
        self.prevFilter = {};
      }
      if (!angular.equals(self.prevFilter, self.filter)) {
        self.prevFilter = angular.copy(self.filter);
        self.dynamicFetch.refresh();
        if (history && typeof (history.pushState) === "function") {
          setTimeout(function() {
            var hash = '';
            if (self.filter.q) {
              hash += "#q="+encodeURIComponent(self.filter.q);
            }
            if (self.filter.integrationCodes) {
              hash += (hash.length ? '&' : '#')+'integrationCodes='+encodeURIComponent(self.filter.integrationCodes.join(','));
            }
            if (self.filter.ucpIds) {
              hash += (hash.length ? '&' : '#')+'ucpIds='+encodeURIComponent(self.filter.ucpIds.join(','));
            }
            history.replaceState({},'', location.origin+location.pathname+hash);
          }, 0);
        }
        self.clearSelection();
      }
    }
  };

  self.toggleSelection = function(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    setTimeout(function() {
      self.selectionCount = 0;
      for (var s in self.selection) {
        if (self.selection[s]) self.selectionCount++;
      }
      if (!$scope.$$phase) {
        $scope.$apply();
      }
    }, 0);
    return false;
  };

  self.clearSelection = function() {
    self.selection = {};
    self.selectionCount = 0;
  };

  self.onUserCreate = function (err, user) {
    if (user) {
      $state.go('sc.portal.users.item', { accountId: user.accountId, id: user.id });
    }
  };
};