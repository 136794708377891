module.exports = function (report) { //el is unused parameter

  function generateInOut(user) {
    var inbound = 0;
    var outbound = 0;
    for (var i = 0; i < user.records.length; i++) {
      var r = user.records[i];
      if (r.direction === 'INBOUND') {
        inbound += r.totalCalls;
      } else {
        outbound += r.totalCalls;
      }
    }
    return {
      inbound: inbound,
      outbound: outbound
    };
  }
  function generateData() {
    var inbound = [];
    var outbound = [];
    if (report && report.accountRecords && report.accountRecords.length) {
      for (var a = 0; a < report.accountRecords.length; a++) {
        var accountRecord = report.accountRecords[a];
        if (accountRecord.userRecords && accountRecord.userRecords.length) {
          var aIn = 0;
          var aOut = 0;
          for (var r = 0; r < accountRecord.userRecords.length; r++) {
            var u = accountRecord.userRecords[r];
            var inOut = generateInOut(u);
            aIn += inOut.inbound;
            aOut += inOut.outbound;
          }
          //Dirty ellipsis fix for long labels;
          if (accountRecord.accountLabel && accountRecord.accountLabel.length > 20) {
            var len = accountRecord.accountLabel.length;
            accountRecord.accountLabel = accountRecord.accountLabel.substring(0, 10) + "..." + accountRecord.accountLabel.substring(len - 8, len);
          }
          inbound.push({ x: accountRecord.accountLabel, y: aIn });
          outbound.push({ x: accountRecord.accountLabel, y: aOut });
        }
      }
    }
    var d = [
      { key: 'Inbound', values: inbound },
      { key: 'Outbound', values: outbound }
    ];
    return d;
  }

  var data = generateData();

  return {
    data: data,
    config: {
      visible: true, // default: true
      extended: false, // default: false
      disabled: false, // default: false
      refreshDataOnly: true, // default: true
      deepWatchOptions: true, // default: true
      deepWatchData: true, // default: true
      deepWatchDataDepth: 2, // default: 2
      debounce: 10 // default: 10
    },
    options: {
      chart: {
        type: 'multiBarHorizontalChart',
        height: 74 + (data[0].values.length * 74), //200 + 20 * accountRecord.userRecords.length,
        margin: {
          top: 36,
          right: 36,
          bottom: 40,
          left: 136
        },
        clipEdge: true,
        duration: 500,
        stacked: true,
        color: ['#871FFF', '#FA7554'],
        showValues: true,
        transitionDuration: 500,
        xAxis: {
          //axisLabel: "Account",
          // tickFormat: function (d) {
          //   var date = new Date(d);
          //   var format = date.getHours() ? '%Hh' : '%m/%d %Hh';
          //   return d3.time.format('%m/%d %Hh')(date);
          // }
          // tickFormat: function (d) {
          //   return d3.format(',f')(d);
          // }
        },
        yAxis: {
          axisLabel: "Calls",
          tickFormat: function (d) {
            return d3.format(',f')(d);
          }
        }
      }
    }
  };
};