require('../../gcf/vendor/angular-nvd3');
// require('../vendor/time-picker/md-time-picker');
// require('../vendor/time-picker/md-time-picker.css');

require('./summary');
require('./cdr');

require('./reports.scss');

angular.module('sc.portal.reports', [
  'ui.router',
  'nvd3',
  'sc.portal.reports.summary',
  'sc.portal.reports.cdr'
]).value('$scReportCommonParams', {
}).config(/*@ngInject*/ function ($stateProvider) {
  $stateProvider.state('sc.portal.reports', {
    abstract: true,
    url: '/reports',
    onEnter: /*@ngInject*/ function ($portalHead, $rootScope, $scReportCommonParams) {
      $portalHead.title = 'Reports';
      $rootScope.portalAutorefresh = $scReportCommonParams;
    },
    onExit: /*@ngInject*/ function ($rootScope) {
      delete $rootScope.portalAutorefresh;
    },
    template: require("./reports.html"),
    controller: require('./reportsCtrl'),
    controllerAs: "reportsCtrl"
  });
});