/*
 * @Author: Alexander.Vangelov@vonage.com 
 * @Date: 2022-08-11 16:09:31 
 * @Last Modified by: Alexander.Vangelov@vonage.com
 * @Last Modified time: 2022-08-11 16:15:06
 */

var PhoneNumberUtil = require('google-libphonenumber/dist/libphonenumber').PhoneNumberUtil;
var phoneUtil = PhoneNumberUtil.getInstance();

angular.module('sc.common.filters.phoneFilter', [
  'sc'
]).filter('phone', function () {
  var countryCode = 'US';
  var code = phoneUtil.getCountryCodeForRegion('US').toString();
  gU.scSendMessage({ action: 'getLocalSetting', key: 'localCountryCode' }, function(result) {
    countryCode = result.value || 'US';
    code = phoneUtil.getCountryCodeForRegion(countryCode).toString();
  });
  return function (numberstring) {
    try {
      if (PhoneNumberUtil.isViablePhoneNumber(numberstring)) {
        var number = phoneUtil.parse(numberstring, countryCode);
        if (phoneUtil.isPossibleNumber(number)) {
          if (numberstring.substr(1, code.length) === code) {
            return phoneUtil.format(number, 2);
          } else {
            return phoneUtil.format(number, 1);
          }
        } else {
          return numberstring;
        }
      } else {
        return numberstring;
      }
    } catch(e) {
      return numberstring;
    }
    // if (numberstring && numberstring[0] === '+' && numberstring.substr(1, code.length) === code) {
    //   var number = numberstring.substr(code.length+1);
    //   console.log(numberstring, code);
    //   if (number && number.length > 6 && parseInt(number.slice(0, 3))) { //+1xxxxxxxxxx
    //     var nxxend = number.length - 4;
    //     var npa = number.slice(0, 3);
    //     var nxx = number.slice(3, nxxend);
    //     if (code == '44') {
    //       npa = number.slice(0, 4);
    //       nxx = number.slice(4, nxxend);
    //       number = '(' + npa + ') ' + nxx + '-' + number.slice(nxxend);
    //     } else {
    //       number = '(' + npa + ') ' + nxx + '-' + number.slice(nxxend);
    //     }
    //   }
    //   return number;
    // } else {
    //   return numberstring;
    // }
  };
});