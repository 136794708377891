angular.module('sc.model.ucp', [
  'sc',
  'ngResource'
]).factory('UcpFactory', /*@ngInject*/ function($injector, $resource, $scSystem) {
  var UcpResource = function () {  //attributes parameter is unused
    var Ucp = $resource($scSystem.portalUrl+'/ucps/:id', {
      id: '@id'
    });

    Ucp.prototype.$findUserByUcpId = function (ucpUserId, successCallback, errorCallback) {
      $resource(
        $scSystem.portalUrl + '/ucps/' + this.id + '/users'
      ).get({ ucpUserId: ucpUserId}, successCallback, errorCallback);
    };

    return Ucp;
  };

  return UcpResource;
});