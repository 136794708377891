var VirtualRepeatLoader = require('../../../gcf/sc/common/virtualRepeatRestfulLoader');

require('ng-cache-loader?module=sc.portal,prefix=src:**!./_cdrFilters.htm');

module.exports = /*@ngInject*/ function ($scope, $rootScope, $http, VgisCdrsReport, $scReportCommonParams, $portalHead, authorizedUser, AccountFactory, UserFactory) {
  var self = this;

  function getAccountLabel(id, target) {
    new AccountFactory().get({ id: id }, function(account) {
      target.label = id+', '+account.name;
    });
  }

  function getUserLabel(id, target) {
    new UserFactory().get({ id: id }, function(user) {
      target.label = id+', '+user.firstName+' '+user.lastName;
    });
  }

  if (location.hash) {
    var params = location.hash.substr(1).split('&');
    params.forEach(function(pair) {
      var kv = pair.split('=');
      var key = kv[0];
      var value = decodeURIComponent(kv[1]);
      if ('accountIds' === key) {
        self.accounts = value.split(',').map(function(id) {
          var a = { id: id, label: id+', ', type: 'Account' };
          getAccountLabel(id, a);
          return a;
        });
      } else if ('userIds' === key) {
        self.users = value.split(',').map(function(id) {
          var a = { id: id, label: id+', ', type: 'User' };
          getUserLabel(id, a);
          return a;
        });
      } else if ('direction' === key) {
        self[key] = value;
      } else if ('states' === key) {
        self.states = value.split(',');
      } else if ('types' === key) {
        self.types = value.split(',');
      }else if ('kind' === key) {
        self.kind = value.split(',');
      } else if (['from', 'to'].indexOf(key) !== -1) {
        var dt = new Date();
        dt.setTime(value);
        if (key === 'to') {
          self.toDate = dt;
          self.toTime = dt;
        } else {
          self.fromDate = dt;
          self.fromTime = dt;
        }
      }
    });
  } else {
    self.states = ['ANSWERED', 'MISSED', 'REJECTED', 'CANCELLED', 'DELIVERED'];
    self.types = ['CALL', 'SMS', 'VOICEMAIL'];
  }

  // self.gAccountId = sessionStorage.getItem('wAccountId') || sessionStorage.getItem('accountId');
  // self.gUserId = sessionStorage.getItem('wUserId') || sessionStorage.getItem('userId');
  if (parseInt(authorizedUser.accountId) > 0) {
    self.gAccountId = authorizedUser.accountId;
  }
  self.gUserId = authorizedUser.id;
  //set Dates visible for last 45 days
  self.minDate = new Date();
  self.minDate.setDate(self.minDate.getDate() - 45);

  function prepareQuery() {
    self.query = {
      // states: self.states,
      accountIds: (self.accounts && self.accounts.length) ? self.accounts.map(function(a) { return a.id; }) : [],
      userIds: (self.users && self.users.length) ? self.users.map(function(u) { return u.id; }) : [],
      direction: self.direction,
      // types: self.types
    };
    if (self.states && self.states.length < 5) { //if all the states selected dont send params to API
      self.query.states = self.states;
    }
    if (self.types && self.types.length < 3) { //if all the types selected dont send params to API
      self.query.types = self.types;
    }
    if (self.fromDate) {
      var from = self.fromDate;
      from.setHours(self.fromTime ? self.fromTime.getHours() : 0);
      from.setMinutes(self.fromTime ? self.fromTime.getMinutes() : 0);
      from.setSeconds(self.fromTime ? self.fromTime.getSeconds() : 0);
      self.query.fromDate = from.getTime();
    }
    if (self.toDate) {
      var to = self.toDate;
      to.setHours(self.toTime ? self.toTime.getHours() : 23);
      to.setMinutes(self.toTime ? self.toTime.getMinutes() : 59);
      to.setSeconds(self.toTime ? self.toTime.getSeconds() : 59);
      self.query.toDate = to.getTime();
    }
    if (self.kind && self.kind.length) {
      if (self.kind.length !== 2) {
        self.query.internal = self.kind.indexOf('EXTERNAL') === -1;
      }
    }
  }

  function download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
  }

  $portalHead.exports.csv = function() {
    prepareQuery();
    $http.post("/portal/v1/reports/cdrs.csv", self.query).then(function(response) {
      download('cdrs.csv', response.data);
    });
  };

  if ($rootScope.scPermissions.has('REPORTS_CDR', 'ACCOUNT') || $rootScope.scPermissions.has('REPORTS_CDR', 'USER')) {
    self.showAccountsSelect = true;
    self.showUsersSelect = true;
    if (self.gAccountId && parseInt(self.gAccountId) > 0) {
      var a = { id: self.gAccountId, label: self.gAccountId+', ', type: 'Account' };
      self.accounts = [a];
      getAccountLabel(self.gAccountId, a);
    }
  } else if ($rootScope.scPermissions.has('REPORTS_CDR', 'ACCOUNT', self.gAccountId) || $rootScope.scPermissions.has('REPORTS_CDR', 'ACCOUNT_USERS', self.gAccountId)) {
    if (self.gAccountId && parseInt(self.gAccountId) > 0) {
      self.accounts = [{ id: self.gAccountId }];
    }
    self.showUsersSelect = true;
  } else {
    self.users = [{ id: self.gUserId }];
  }

  self.submit = function() {
    delete self.error;
    prepareQuery();
    if (self.dynamicFetch) {
      self.dynamicFetch.refresh();
      if (history && typeof (history.pushState) === "function") {
        setTimeout(function() {
          var hash = '';
          if (self.states) {
            hash += "#states="+encodeURIComponent(self.states.join(','));
          }
          if (self.accounts && self.accounts.length) {
            hash += (hash.length ? '&' : '#')+'accountIds='+self.accounts.map(function(a) { return a.id; }).join(',');
          }
          if (self.users && self.users.length) {
            hash += (hash.length ? '&' : '#')+'userIds='+self.users.map(function(u) { return u.id; }).join(',');
          }
          if (self.direction) {
            hash += (hash.length ? '&' : '#')+'direction='+self.direction;
          }
          if (self.types) {
            hash += (hash.length ? '&' : '#')+'types='+self.types;
          }
          if (self.kind) {
            hash += (hash.length ? '&' : '#')+'kind='+encodeURIComponent(self.kind.join(','));
          }
          if (self.fromDate) {
            var from = self.fromDate;
            from.setHours(self.fromTime ? self.fromTime.getHours() : 0);
            from.setMinutes(self.fromTime ? self.fromTime.getMinutes() : 0);
            from.setSeconds(self.fromTime ? self.fromTime.getSeconds() : 0);
            hash += (hash.length ? '&' : '#')+'from='+from.getTime();
            self.fromTime = from;
          }
          if (self.toDate) {
            var to = self.toDate;
            to.setHours(self.toTime ? self.toTime.getHours() : 23);
            to.setMinutes(self.toTime ? self.toTime.getMinutes() : 59);
            to.setSeconds(self.toTime ? self.toTime.getSeconds() : 59);
            hash += (hash.length ? '&' : '#')+'to='+to.getTime();
            self.toTime = to;
          }
          history.replaceState({},'', location.origin+location.pathname+hash);
        }, 0);
      }
    }
  };

  self.submit();

  self.dynamicFetch = new VirtualRepeatLoader(VgisCdrsReport, {
    idKey: 'eventId',
    filters: function () {
      return self.query;
    },
    onUpdate: function () {
      setTimeout(function(){
        $scope.$apply();
      });
    }
  });

  self.durationSec = function (durationMs) {
    return durationMs ? ((durationMs < 1000) ? 1 : (durationMs / 1000)) : 0;
  };
  
  $scReportCommonParams.currentScope = $scope ;
  $scReportCommonParams.currentSubmit = self.submit;
};