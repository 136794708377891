module.exports = function(callback) {
  var done;
  function sysListener(event) {
    if (event.data && event.data.scSysResp) {
      callback(event.data.scSysResp);
      done = true;
    }
  }
  window.addEventListener("message", sysListener);
  window.postMessage({ scSysReq: true }, location);
  setTimeout(function() {
    if (!done) {
      window.removeEventListener("message", sysListener, false);
      callback({ error: "timeout" });
    }
  }, 1800);
};