angular.module('sc.portal.users.settings.conferences', [
  'ui.router'
]).config(function ($stateProvider) {
  $stateProvider.state('sc.portal.users.item.settings.conferences', {
    url: '/conferences',
    onEnter: /*@ngInject*/ function($state, $mdDialog, gAccountId, gUserId, UserConferenceFactory) {
      $mdDialog.show({
        multiple: true,
        template: require('./conferences.html'),
        controller: require('./conferencesCtrl'),
        controllerAs: 'conferencesCtrl',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: { 
          ConferencingSettings: new UserConferenceFactory({ accountId: gAccountId, userId: gUserId, feature: 'conferences' })
        }
      }).then(function () {
        $state.go('sc.portal.users.item', { accountId: gAccountId, userId: gUserId}, { reload: true} );
      }, function() {
        $state.go('sc.portal.users.item', { accountId: gAccountId, userId: gUserId}, { reload: true} );
      });
    }
  });
});