var integrationLogos = require('./integrationLogos');
var VirtualRepeatLoader = require('../../gcf/sc/common/virtualRepeatRestfulLoader');

module.exports = /*@ngInject*/ function($mdDialog, integrationCode, VgisUser, $window, IntegrationFactory, $flash) {
  var self = this;

  self.integrationCode = integrationCode;
  self.logoForIntegration = integrationLogos.get;

  var integrationService = new IntegrationFactory({ userId: true });

  self.dynamicUsers = new VirtualRepeatLoader(VgisUser, {
    idKey: 'id',
    filters: function () {
      return {
        integrationCodes: integrationCode
      };
    }
  });
  self.dynamicUsers.callback = function() {
    self.dynamicUsers.getVisibleItems().forEach(function(u) {
      if (!u.$profilePromise) {
        u.$profilePromise = integrationService.profile({ accountId: u.accountId, userId: u.id, id: self.integrationCode }).$promise;
      }
      self.profileBusyMask = true;
      u.$profilePromise.then(function(profile) {
        u.profile = profile;
        self.profileBusyMask = false;
      }, function() {
        delete $flash.error;
        self.profileBusyMask = false;
      });
    });
  };
  setTimeout(function () {
    angular.element($window).triggerHandler('resize');
  });

  self.cancel = function() {
    $mdDialog.cancel();
    $window.history.back();
  };

  self.select = function(user) {
    $mdDialog.hide(user);
  };
};