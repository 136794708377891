require('ng-cache-loader?module=sc.portal,prefix=src:**!./_summaryFilters.htm');
var chartAccounts = require('./chartAccounts');
var chartUsers = require('./chartUsers');
var chartIntervals = require('./chartIntervals');
var chartTotal = require('./chartTotal');

module.exports = /*@ngInject*/ function ($scope, $rootScope, summaryReportService, $scReportCommonParams, AccountFactory, UserFactory) {
  var self = this;

  function getAccountLabel(id, target) {
    new AccountFactory().get({ id: id }, function(account) {
      target.label = id+', '+account.name;
    });
  }

  function getUserLabel(id, target) {
    new UserFactory().get({ id: id }, function(user) {
      target.label = id+', '+user.firstName+' '+user.lastName;
    });
  }

  if (location.hash) {
    var params = location.hash.substr(1).split('&');
    params.forEach(function(pair) {
      var kv = pair.split('=');
      var key = kv[0];
      var value = decodeURIComponent(kv[1]);
      if ('accountIds' === key) {
        self.accounts = value.split(',').map(function(id) {
          var a = { id: id, label: id+', ', type: 'Account' };
          getAccountLabel(id, a);
          return a;
        });
      } else if ('userIds' === key) {
        self.users = value.split(',').map(function(id) {
          var a = { id: id, label: id+', ', type: 'User' };
          getUserLabel(id, a);
          return a;
        });
      } else if (['interval', 'direction', 'selectedChart', 'types'].indexOf(key) !== -1) {
        self[key] = value;
      } else if (['from', 'to'].indexOf(key) !== -1) {
        var dt = new Date();
        dt.setTime(value);
        if (key === 'to') {
          self.toDate = dt;
          self.toTime = dt;
        } else {
          self.fromDate = dt;
          self.fromTime = dt;
        }
      }
    });
  }
  if (['HOURLY', 'WEEKLY', 'MONTHLY'].indexOf(self.interval) < 0) {
    self.interval = 'HOURLY';
  }

  self.gAccountId = sessionStorage.getItem('wAccountId') || sessionStorage.getItem('accountId');
  self.gUserId = sessionStorage.getItem('wUserId') || sessionStorage.getItem('userId');

  self.charts = ['INTERVALS'];
  if (!self.selectedChart) self.selectedChart = 'INTERVALS';
  self.types = ['CALL', 'SMS', 'VOICEMAIL'];
  
  if ($rootScope.scPermissions.has('REPORTS_SUMMARY', 'ACCOUNT') || $rootScope.scPermissions.has('REPORTS_SUMMARY', 'USER')) {
    self.showAccountsSelect = true;
    self.showUsersSelect = true;
    self.charts = ['ACCOUNTS', 'USERS', 'INTERVALS'];
    if (!location.hash && self.gAccountId && parseInt(self.gAccountId) > 0) {
      var a = { id: self.gAccountId, label: self.gAccountId+', ', type: 'Account' };
      self.accounts = [a];
      getAccountLabel(self.gAccountId, a);
    }
  } else if ($rootScope.scPermissions.has('REPORTS_SUMMARY', 'ACCOUNT', self.gAccountId) || $rootScope.scPermissions.has('REPORTS_SUMMARY', 'ACCOUNT_USERS', self.gAccountId)) {
    if (!location.hash && self.gAccountId && parseInt(self.gAccountId) > 0) {
      self.accounts = [{ id: self.gAccountId }];
    }
    self.showUsersSelect = true;
    self.charts = ['USERS', 'INTERVALS'];
  } else {
    self.users = [{ id: self.gUserId }];
    self.selectedChart = 'INTERVALS';
  }

  function storeQuery() {
    if (history && typeof (history.pushState) === "function") {
      setTimeout(function() {
        var hash = '';
        if (self.interval) {
          hash += "#interval="+self.interval;
        }
        if (self.accounts && self.accounts.length) {
          hash += (hash.length ? '&' : '#')+'accountIds='+self.accounts.map(function(a) { return a.id; }).join(',');
        }
        if (self.users && self.users.length) {
          hash += (hash.length ? '&' : '#')+'userIds='+self.users.map(function(u) { return u.id; }).join(',');
        }
        if (self.direction) {
          hash += (hash.length ? '&' : '#')+'direction='+self.direction;
        }
        if (self.types) {
          hash += (hash.length ? '&' : '#')+'types='+self.types;
        }
        if (self.fromDate) {
          var from = self.fromDate;
          from.setHours(self.fromTime ? self.fromTime.getHours() : 0);
          from.setMinutes(self.fromTime ? self.fromTime.getMinutes() : 0);
          from.setSeconds(self.fromTime ? self.fromTime.getSeconds() : 0);
          hash += (hash.length ? '&' : '#')+'from='+from.getTime();
          self.fromTime = from;
        }
        if (self.toDate) {
          var to = self.toDate;
          to.setHours(self.toTime ? self.toTime.getHours() : 23);
          to.setMinutes(self.toTime ? self.toTime.getMinutes() : 59);
          to.setSeconds(self.toTime ? self.toTime.getSeconds() : 59);
          hash += (hash.length ? '&' : '#')+'to='+to.getTime();
          self.toTime = to;
        }
        //hash += (hash.length ? '&' : '#')+'selectedChart='+self.selectedChart;
        history.replaceState({},'', location.origin+location.pathname+hash);
      }, 0);
    }
  }

  self.submit = function() {
    delete self.error;
    self.loading = true;
    if (!self.reportForm || self.reportForm.$valid) {
      var query = {
       interval: self.interval,
       accountIds: (self.accounts && self.accounts.length) ? self.accounts.map(function(a) { return a.id; }) : [],
       userIds: (self.users && self.users.length) ? self.users.map(function(u) { return u.id; }) : [],
       direction: self.direction,
       types: self.types
      };
      if (self.fromDate) {
        var from = self.fromDate;
        from.setHours(self.fromTime ? self.fromTime.getHours() : 0);
        from.setMinutes(self.fromTime ? self.fromTime.getMinutes() : 0);
        from.setSeconds(self.fromTime ? self.fromTime.getSeconds() : 0);
        query.fromDate = from.getTime();
      }
      if (self.toDate) {
        var to = self.toDate;
        to.setHours(self.toTime ? self.toTime.getHours() : 23);
        to.setMinutes(self.toTime ? self.toTime.getMinutes() : 59);
        to.setSeconds(self.toTime ? self.toTime.getSeconds() : 59);
        query.toDate = to.getTime();
      }
      summaryReportService.query(query, function(report) {
        self.loading = false;
        self.reportDetails = report;        
        self.interval = report.query.interval;

        self.totalCalls = report.totalCount ? report.totalCount : '0';
        self.totalDuration = report.totalDuration ? Math.round(report.totalDuration/1000) : '0';  //convert on FE ms to seconds
        self.selectChart(self.selectedChart);
      });
      storeQuery();
    }
  };

  self.selectChart = function(chart) {
    setTimeout(function() { //fix chart size
      switch (chart) {
        case 'ACCOUNTS':
          self.totals = chartTotal(self.reportDetails);
          self.report = chartAccounts(self.reportDetails);
          break;
        case 'USERS':
          self.totals = chartTotal(self.reportDetails);
          self.report = chartUsers(self.reportDetails);
          break;
        case 'INTERVALS':
          self.totals = chartTotal(self.reportDetails);
          self.report = chartIntervals(self.reportDetails, document.querySelector('#bars'), self.direction);
          break;
        default:
          delete self.report;
          delete self.totals;
      }
      var evt = window.document.createEvent('UIEvents'); 
      evt.initUIEvent('resize', true, false, window, 0); 
      window.dispatchEvent(evt);
    }, 500);
  };

  var pieChart = {
    type: 'pieChart',
    height: 200,
    x: function (d) { return d.key; },
    y: function (d) { return d.y; },
    showLabels: true,
    labelType: 'value',
    duration: 500,
    labelThreshold: 0.01,
    labelSunbeamLayout: false,
    showLegend: true,
    color: ['#871FFF', '#FA7554'],
    valueFormat: function (d) {
      return d3.format(',f')(d);
    }
  };

  self.optionsTotalCalls = {
    chart: pieChart
  };
  self.optionsTotalDuration = {
    chart: Object.assign(angular.copy(pieChart), {
      valueFormat: function (d) {
        var s = d/1000;
        if (s < 60) {
          return d3.format(',f')(s) + ' sec';
        } else if (s < 3600) {
          return d3.format(',.1f')(s / 60) + ' min';
        } else return d3.format(',.1f')(s / 3600) + ' hrs';
      }
    })
  };

  self.submit();
  self.minDate = new Date();
  self.minDate.setDate(self.minDate.getDate() - 90);

  $scReportCommonParams.currentScope = $scope ;
  $scReportCommonParams.currentSubmit = self.submit;
};