require('./resource');

angular.module('sc.model.integration', [
    'sc',
    'ngResource',
    'sc.model.resource'
  ])
  .factory('IntegrationFactory', /*@ngInject*/ function($injector, $resource, $scSystem) {
    var IntegrationModel = function (attributes) {
      if (!attributes) {
        attributes = {};
      }
      if (attributes.user) {
        self.user = attributes.user;
        attributes.accountId = attributes.user.accountId;
        attributes.userId = attributes.user.id;
      }
      var Integration = $resource($scSystem.portalUrl+'/integrations/:id', {
        id: '@code',
        // accountsPath: function(that) { 
        //   if ((that || {}).accountId || attributes.accountId) {
        //     return 'accounts';
        //   }
        // },
        accountId: function(that) {
          return that ? (that.accountId || attributes.accountId) : attributes.accountId;
        },
        usersPath: function(that) { 
          if ((that || {}).userId || attributes.userId) {
            return 'users';
          }
        },
        userId: function(that) {
          return that ? (that.userId || attributes.userId) : attributes.userId;
        }
      }, {
        resources: {
          method: 'GET',
          url: $scSystem.portalUrl + '/accounts/:accountId/:usersPath/:userId/integrations/:code/resources',
          isArray: true
        },
        profile: {
          method: 'GET',
          url: $scSystem.portalUrl + '/accounts/:accountId/:usersPath/:userId/integrations/:id/profile'
        },
        getIntegrationsList: {
          method: 'GET',
          url: $scSystem.portalUrl + '/accounts/:accountId/:usersPath/:userId/integrations',
          isArray: true
        }
      });

      Object.defineProperty(Integration.prototype, 'resources', { 
        get: function() {
          return new $injector.get('ResourceFactory')({ accountId: this.accountId, integrationCode: this.code });
        }
      });
  
      return Integration;
    };
  
    return IntegrationModel;
  });