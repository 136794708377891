require('./user');

angular.module('sc.model.userUci', [
  'sc',
  'ngResource',
  'sc.model.user'
])
.factory('UserUciFactory', /*@ngInject*/ function($injector, $resource, $scSystem) {
  var UserUciResource = function (attributes) {
    var self = this;
    if (!attributes) {
      attributes = {};
    }
    if (attributes.user) {
      self.user = attributes.user;
      attributes.accountId = attributes.user.accountId;
      attributes.userId = attributes.user.id;
    }
    var UserUci = $resource($scSystem.portalUrl+'/accounts/:accountId/users/:userId/ucis/:id', {
      accountId: function(that) {
        return that ? that.accountId || -1 : attributes.accountId || -1;
      },
      userId: function(that) {
        return that ? (that.userId || attributes.userId) : attributes.userId;
      },
      id: '@id'
    }, {
      update: {
        method: 'PUT'
      },
      create: {
        method: 'POST'
      },
      describe: {
        method: 'GET',
        url: $scSystem.portalUrl + '/accounts/:accountId/users/:userId/ucis/:id/describe'
      }
    });

    UserUci.prototype.$save = function(success, error) {
      return (this.id) ? this.$update(success, error) : this.$create(success, error);
    };

    Object.defineProperty(UserUci.prototype, 'user', { 
      get: function() {
        if (self.account) {
          return self.account;
        } else {
          var User = new $injector.get('UserFactory')({ ucis: this });
          return new User({ id: this.userId });
        }
      }
    });

    return UserUci;
  };

  return UserUciResource;
});