require('../../../gcf/sc/common/directives/scCallIcon');

angular.module('sc.portal.reports.cdr', [
  'ui.router',
  'sc.common.callIcon'
]).config(/*@ngInject*/ function ($stateProvider) {
  $stateProvider.state('sc.portal.reports.cdr', {
    url: '/cdr',
    template: require('./cdr.html'),
    controller: require('./cdrCtrl'),
    controllerAs: "cdrCtrl",
    onExit: /*@ngInject*/ function($portalHead) {
      $portalHead.exports = {};
    },
    resolve: {
      VgisCdrsReport: /*@ngInject*/ function (portalSystem, $scSystem, $resource) {
        return $resource($scSystem.portalUrl + '/reports/cdrs', {
        }, {
          query: {
            method: 'POST',
            isArray: true
          },
          count: {
            method: 'POST',
            url: $scSystem.portalUrl + '/reports/cdrs/count'
          }
        });
      }
    }
  });
});