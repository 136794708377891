var SYSTEM_SETTINGS = [
  'accountId', 'scServerHost', 'token', 'refreshToken', 'tokenExpire', 'userId', 'smsUciId', 'preferredUciId', 'action',
  'scServerHost', 'scWebHost', 'scUI'
];
var SYNC_SETTING_KEYS = [
  'ui.c2dEnabled',
  'ui.c2dPromptEnabled',
  'ui.smsEnabled',
  'ui.callRecordingsEnabled',
  'ui.voicemailsEnabled',
  'ui.meetingsEnabled',
  'ui.webLauncherEnabled',
  //'ui.speechRecogEnabled',
  'ui.dialPrefixEnabled',
  'ui.callHistoryEnabled',
  'ui.favContactsEnabled',
  'ui.callNotesEnabled',
  'ui.scCallAppearance',
  'ui.scCallAutoConfirm',
  'ui.autopop'
];
function keyValsToSettngs(keyvals) { // convert key-values to settings
  if (typeof(keyvals) === 'object' && keyvals !== null) {
    var settings = {};
    for (var s in keyvals) {
      var setting = keyvals[s];
      if (!setting || ['boolean', 'string', 'number'].indexOf(typeof(setting.value)) === -1) {
        if (setting.value && ['boolean', 'string', 'number'].indexOf(typeof(setting.value.value)) !== -1) {
          setting.value = setting.value.value; // make it compatible even with the old API
        } else if (s !== 'ui.autopop') {
          continue;
        }
      }
      if (!setting.key && typeof(s) === 'string') {
        setting.key = s;
      }
      if (/ui\.popup\./.test(setting.key || '')) { // popup settings
        if (!settings.popup) {
          settings.popup = {};
        }
        var ruleMatch = (setting.key || '').match(/^ui\.popup\.(\w+)\[(\d)\]\.(\w+)\.([\w|]+)?\.?([\w|]+)?/);
        if (ruleMatch) {
          var group = ruleMatch[1];
          if (!settings.popup[group]) {
            settings.popup[group] = [];
          }
          var index = parseInt(ruleMatch[2], 10);
          var rule = {
            type: ruleMatch[3]
          };
          if (typeof(setting.locked) !== 'undefined') {
            rule.locked = setting.locked;
          }
          if (group === 'internal') {
            rule.direction = ruleMatch[4];
            rule.state = ruleMatch[5];
          } else {
            rule.state = ruleMatch[4];
          }
          rule.popup = setting.value;
          settings.popup[group][index] = rule;
        }
      } else { // other setting
        var validMatch = (setting.key || '').match(/^ui\.(\w+)$/);
        if (validMatch) {
          settings[validMatch[1]] = setting.value;
        }
      }
    }
    return settings;
  }
}

function settingsToKeyVals(settings) { // convert settings to key-values
  var keyVals = {};
  var key;
  if (typeof(settings) === 'object' && settings !== null) {
    for (var s in settings) {
      if (SYSTEM_SETTINGS.indexOf(s) !== -1) {
        continue;
      }
      if (s === 'autopop') { // new autopop settings
        keyVals['ui.autopop'] = { value: settings[s] };
      } else if (s === 'popup') { // pop rules
        for (var p in settings.popup) {
          var rules = settings.popup[p];
          if (rules instanceof Array) {
            for (var i = 0; i < rules.length; i++) {
              key = 'ui.popup.' + p + '[' + i + '].' + rules[i].type + '.';
              if (p === 'internal') {
                key += (rules[i].direction || '') + '.' + (rules[i].state || '');
              } else {
                key += (rules[i].state || '');
              }
              keyVals[key] = { value: rules[i].popup };
            }
          } 
        }
      } else if (typeof(s) === 'string') { // other settings
        var value = settings[s];
        if (['boolean', 'string', 'number'].indexOf(typeof(value)) !== -1) {
          key = 'ui.' + s;
          keyVals[key] = { value: value };
        }
      }
    }
  }
  return keyVals;
}

var settingsHelper = {
  keyValsToSettngs: keyValsToSettngs,
  settingsToKeyVals: settingsToKeyVals,
  syncSettingsKeys: SYNC_SETTING_KEYS
};

module.exports = settingsHelper;