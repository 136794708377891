require('../models/user');
require('./settings');

angular.module('sc.portal.users', [
  'ui.router',
  'sc.model.user',
  'sc.portal.users.settings'
]).config(function ($stateProvider) {
  $stateProvider.state('sc.portal.users', {
    abstract: true,
    template: "<ui-view layout='column' flex></ui-view>",
    resolve: {
      VgisUser: /*@ngInject*/ function(portalSystem, UserFactory) {
        return new UserFactory();
      }
    }
  }).state('sc.portal.users.list', {
    url: '/users',
    onEnter: function($portalHead) {
      $portalHead.title = 'Users';
    },
    template: require('./users.html'),
    controller: require('./usersCtrl'),
    controllerAs: "usersCtrl"
  }).state('sc.portal.users.item', {
    url: '/accounts/:accountId/users/:id',
    onEnter: function($portalHead) {
      $portalHead.title = 'Users';
    },
    template: require('./user.html'),
    controller: require('./userCtrl'),
    controllerAs: 'userCtrl',
    resolve: {
      gUserId: /*@ngInject*/ function($stateParams) {
        return $stateParams.id;
      },
      gAccountId: /*@ngInject*/ function($stateParams) {
        return $stateParams.accountId;
      },
      gUserService: /*@ngInject*/ function ($stateParams, VgisUser) {
        return new VgisUser({ id: $stateParams.id });
      },
      VgisCdrsReport: /*@ngInject*/ function (portalSystem, $scSystem, $resource) {
        return $resource($scSystem.portalUrl + '/reports/cdrs', {
        }, {
          query: {
            method: 'POST',
            isArray: true
          },
          count: {
            method: 'POST',
            url: $scSystem.portalUrl + '/reports/cdrs/count'
          }
        });
      }
    }
  }).state('sc.portal.users.account', {
    url: '/accounts/:accountId/users',
    onEnter: /*@ngInject*/ function ($portalHead) {
      $portalHead.title = 'My Account Users';
    },
    template: require('./users.html'),
    controller: require('./accountUsersCtrl'),
    controllerAs: 'usersCtrl',
    resolve: {
      gAccountId: /*@ngInject*/ function($stateParams) {
        return $stateParams.accountId;
      },
      VgisAccountUsers: /*@ngInject*/ function(portalSystem, $stateParams, UserFactory) {
        return new UserFactory({ accountId: $stateParams.accountId });
      }
    }
  });
});