var VirtualRepeatLoader = require('../../../gcf/sc/common/virtualRepeatRestfulLoader');
require('ng-cache-loader?module=sc.system,prefix=src:**!./_adminsFilters.htm');
module.exports = /*@ngInject*/ function($rootScope, $window, $state, VgisAdmin, VgisSystemRole) {
  var self = this;
  self.filter = {};
  if (location.hash) {
    var params = location.hash.substr(1).split('&');
    params.forEach(function(pair) {
      var kv = pair.split('=');
      var key = kv[0];
      var value = decodeURIComponent(kv[1]);
      if (['roles'].indexOf(key) === -1) {
        self.filter[key] = value;
      } else {
        self.filter[key] = value.split(',');
      }
    });
  }

  if (!$rootScope.scPermissions.has('_LIST', 'ACCOUNT')) {
    self.forbidden = true;
    self.error = 'Missing permission to list all accounts';
  }

  VgisSystemRole.query(function(roles) {
    self.roles = roles.map(function(role) {
      return { id: role.code, label: role.name+' ('+role.code+')' };
    });
  }, function(err) {
    console.error(err);
  });

  self.dynamicFetch = new VirtualRepeatLoader(VgisAdmin, {
    filters: function () {
      var query = {};
      if (self.filter.q) {
        query.q = self.filter.q;
      }
      if (self.filter.roles) {
        query.roles = self.filter.roles.join(',');
      }
      return query;
    }
  });

  self.openUser = function(ev, user) {
    //self.toggleRightBar(false);
    if (user) {
      $state.go('sc.system.admins.item', { id: user.id });
    }
  };

  self.submit = function() {
    self.dynamicFetch.refresh();
    if (history && typeof (history.pushState) === "function") {
      setTimeout(function() {
        var hash = '';
        if (self.filter.q) {
          hash += "#q="+encodeURIComponent(self.filter.q);
        }
        if (self.filter.roles) {
          hash += (hash.length ? '&' : '#')+'roles='+encodeURIComponent(self.filter.roles.join(','));
        }
        history.replaceState({},'', location.origin+location.pathname+hash);
      }, 0);
    }
  };

  setTimeout(function() {
    angular.element($window).triggerHandler('resize');
  });
};