var formErrorParser = require('../../common/utils/formErrorParser');

module.exports = /*@ngInject*/ function($scope, $portalHead, $mdDialog, gAccountUciPromises, gUciId, gAccountId, UcpFactory) {
  var self = this;
  self.gUciId = gUciId;
  self.niceUcps = {};

  self.busyMask = true;
  
  gAccountUciPromises.item.then(function(uci) {
    self.gAccountUci = uci;
    if (!self.gAccountUci.accountId) {
      self.gAccountUci.accountId = gAccountId;
    }
    new UcpFactory().query(function(ucps) {
      self.gUcps = ucps;
      for (var i = 0; i < ucps.length; i++) {
        self.niceUcps[ucps[i].id] = ucps[i];
      }
      if (self.gAccountUci.ucpId) {
        self.ucpChanged(self.gAccountUci.ucpId);
      }
      self.busyMask = false;
    }, function(errorResponse) {
      self.formErrors = formErrorParser(errorResponse, self.uciForm, self.niceFields);
      self.busyMask = false;
    });
  }, function(errorResponse) {
    self.formErrors = formErrorParser(errorResponse, self.uciForm, self.niceFields);
    self.busyMask = false;
  });
  // gAccountUciPromises.describe.then(function(describe) {
  //   self.describe = describe;
  // });
  self.niceFields = {};
  self.niceFields.ucpId = { label: 'Service Provider' };
  self.niceFields.ucpAccountId = { label: 'UCP Account ID' };
  self.niceFields.ucpAdminUsername = { label: 'UCP Admin Username' };
  self.niceFields.ucpAdminPassword = { label: 'UCP Admin Password' };

  self.cancel = function() {
    $mdDialog.cancel('cancel');
  };

  self.ucpChanged = function(ucpId) {
    self.isVBS = self.niceUcps[ucpId] && self.niceUcps[ucpId].ucpType === 'VBS';
    self.isVCC = self.niceUcps[ucpId] && /^NVM|VCC/.test(self.niceUcps[ucpId].ucpType);
    // force UI to update username required
    self.hideCredentialsFields = true;
    setTimeout(function() {
      self.hideCredentialsFields = false;
      $scope.$apply();
    }, 200);
  };

  self.save = function() {
    delete self.formErrors;
    if (self.uciForm.$valid) {
      self.busyMask = true;
      self.gAccountUci.$save(function(uci) {
        $mdDialog.hide(uci);
      }, function(errorResponse) {
        self.busyMask = false;
        self.formErrors = formErrorParser(errorResponse, self.uciForm, self.niceFields);
        // self.formErrors = errorResponse.data;
      });
    } else {
      self.uciForm.$setDirty(true);
      var errorNames = [];
      angular.forEach(self.uciForm.$error, function(type) {
        angular.forEach(type, function(field) {
          field.$touched = true;
          field.$setDirty();
          errorNames.push(field.$name);
        });
      });
      self.formErrors = "Please fill out the required fields: "+errorNames.join(", ")+".";
    }
  };
};