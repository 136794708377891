require('../models/account');
require('../models/ucp');

angular.module('sc.portal.accounts', [
  'ui.router',
  'sc.model.account',
  'sc.model.ucp'
]).config(/*@ngInject*/ function ($stateProvider) {
  $stateProvider.state('sc.portal.accounts', {
    abstract: true,
    template: "<ui-view layout='column' flex></ui-view>",
    resolve: {
      VgisAccount: /*@ngInject*/ function (portalSystem, AccountFactory) {
        return new AccountFactory();
      }
    }
  }).state('sc.portal.accounts.list', {
    url: '/accounts',
    onEnter: /*@ngInject*/ function ($portalHead) {
      $portalHead.title = 'Accounts';
    },
    template: require('./accounts.html'),
    controller: require('./accountsCtrl'),
    controllerAs: 'accountsCtrl'
  }).state('sc.portal.accounts.item', {
    url: '/accounts/:id',
    onEnter: /*@ngInject*/ function ($portalHead, $stateParams) {
      $portalHead.title = 'Account #' + $stateParams.id;
    },
    template: require('./account.html'),
    controller: require('./accountCtrl'),
    controllerAs: 'accountCtrl',
    resolve: {
      gAccountId: /*@ngInject*/ function ($stateParams) {
        return $stateParams.id;
      },
      gAccountService: /*@ngInject*/ function ($stateParams, VgisAccount) {
        return new VgisAccount({ id: $stateParams.id });
      }
    }
  });
});