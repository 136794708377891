angular.module('sc.common.formValidation', [
]).directive('scAccessibleForm', function ($rootScope) {
  return {
    restrict: 'A',
    require: "^form",
    link: function (scope, elem, attr, form) {
      $rootScope.currentForm = form;
      elem.on('submit', function () {
        var parent = elem[0];
        if (form && form.$$element && form.$$element.length) {
          parent = form.$$element[0];
        }
        var firstInvalid = parent.querySelector('md-dialog-content.visible md-input-container .ng-invalid');
        if (!firstInvalid) {
          firstInvalid = parent.querySelector('md-input-container .ng-invalid');
        }
        if (firstInvalid) {
          firstInvalid.focus();
        }
      });
    }
  };
}).directive('scFormErrors', function() {
  return {
    restrict: 'E',
    scope: {
      errors: '='
    },
    template: require('./formErrors.html'),
    link: function (scope) {
      scope.clearErrors = function() {
        delete scope.errors;
      };
    }
  };
});