var formErrorParser = require('../common/utils/formErrorParser');
var gAccountDescribe = require('../mocks/accountDescribe.json');

module.exports = /*@ngInject*/ function($scope, $mdDialog, $state, gAccountId, gAccountPromises, UcpFactory) {
  var self = this;
  self.gAccountId = gAccountId;
  self.busyMask = true;

  gAccountPromises.item.then(function(account) {
    self.gAccount = account;
    self.busyMask = false;
    self.isDemo = !!self.gAccount.expiringDate;
  });
  //gAccountPromises.describe.then(function(describe) {
    self.describe = gAccountDescribe;
    self.niceFields = {};
    for (var f=0; f < self.describe.sections[0].fields.length; f++) {
      var field = self.describe.sections[0].fields[f];
      self.niceFields[field.name] = self.describe.sections[0].fields[f];
    }
    self.niceFields['uci.ucpId'] = { label: 'Service Provider' };
    self.niceFields['uci.username'] = { label: 'UCP Username' };
    self.niceFields['uci.password'] = { label: 'UCP Password' };
  //});

  self.niceUcps = {};
  new UcpFactory().query(function(ucps) {
    self.gUcps = ucps;
    for (var i = 0; i < ucps.length; i++) {
      self.niceUcps[ucps[i].id] = ucps[i];
    }
  });

  self.ucpChanged = function(ucpId) {
    self.isVBS = self.niceUcps[ucpId] && self.niceUcps[ucpId].ucpType === 'VBS';
    self.isVCC = self.niceUcps[ucpId] && /^NVM|VCC/.test(self.niceUcps[ucpId].ucpType);
    // force UI to update username required
    self.hideCredentialsFields = true;
    setTimeout(function() {
      self.hideCredentialsFields = false;
      $scope.$apply();
    }, 200);
  };

  self.cancel = function() {
    $mdDialog.cancel('cancel');
  };

  self.disableAccount = function(ev) {
    var confirm = $mdDialog.confirm()
    .title('Are you sure you want to disable account '+self.gAccount.name+'?')
    .textContent('The account will be archived after 4 days.')
    .ariaLabel('Change Account Status')
    .targetEvent(ev)
    .ok('Yes')
    .cancel('No');
    $mdDialog.show(confirm).then(function() {
      self.gAccount.$delete(function() {
        $state.go('sc.portal.accounts.item', { id: self.gAccountId }, { reload: true });
      }, function() {
         //error can be handled here
      });
    }, function() {
      if(window.scDebug) {
        console.info('Disable account not confirmed');
      }
    });
  };

  self.save = function() {
    delete self.formErrors;
    if (self.accountForm.$valid) {
      self.busyMask = true;
      if (self.isVBS && typeof(self.gAccount.uci) === "object" && self.gAccount.uci.adminCredentials) {
        delete self.gAccount.uci.adminCredentials;
      }
      if (self.isDemo) {
        var d = new Date();
        d.setFullYear('2048');
        self.gAccount.expiringDate = d.toISOString();
      } else {
        delete self.gAccount.expiringDate;
      }
      self.gAccount.$save(function(account) {
        $mdDialog.hide(account);
      }, function(errorResponse) {
        self.formErrors = formErrorParser(errorResponse, self.accountForm, self.niceFields);
        self.busyMask = false;
      });
    } else {
      self.accountForm.$setDirty(true);
      var errorNames = [];
      angular.forEach(self.accountForm.$error, function(type) {
        angular.forEach(type, function(field) {
          field.$touched = true;
          field.$setDirty();
          errorNames.push(field.$name);
        });
      });
      self.formErrors = "Please fill out the required fields: "+errorNames.join(", ")+".";
    }
  };
};