module.exports = /*@ngInject*/ function ($scope, $scReportCommonParams, $state, $stateParams) {
  var self = this;
  self.common = $scReportCommonParams;

  $stateParams = {
    autorefresh: $scReportCommonParams.autoRefreshInterval
  };

  self.triggerAutoRefresh = function () {
    if (self.common.autoRefreshInterval) {
      self.common.refreshCounter = self.common.autoRefreshInterval;
    } else {
      delete self.common.refreshCounter;
      delete self.common.refreshIn;
    }
    $stateParams.autorefresh = self.common.autoRefreshInterval;
    $state.transitionTo($state.current.name, $stateParams, { notify: false });
  };

  if (!self.common.autoRefreshInterval && $stateParams.autorefresh && typeof parseInt($stateParams.autorefresh) === 'number') {
    if (parseInt($stateParams.autorefresh) < 300) self.error = "Autorefresh interval can't be less than 5 min";
    else {
      self.common.autoRefreshInterval = parseInt($stateParams.autorefresh);
      self.triggerAutoRefresh();
    }
  }
};