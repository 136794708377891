module.exports = function (permissionCodes) {
  var self = this;
  self.codes = permissionCodes;
  self.expandCodes = [];

  for (var i = 0; i < permissionCodes.length; i++) {
    var code = permissionCodes[i];
    if (/^ANY_ACCOUNT/.test(code)) {
      self.viewAccountsMenu = true;
      self.viewMyAccountMenu = false;
    }
    if (/^OWN_ACCOUNT/.test(code) && !self.viewAccountsMenu) {
      self.viewMyAccountMenu = true;
    }
    if (/^ANY_USER/.test(code)) {
      self.viewUsersMenu = scDevEnv ? true : false;  //For all SA, VA, JA hiding users menu (but show it in dev)
      self.viewMyUserMenu = false;
      self.viewOwnAccountUsersMenu = false;
    }
    if (/^OWN_ACCOUNT_USERS/.test(code) && !self.viewUsersMenu) {
      self.viewOwnAccountUsersMenu = true;
      self.viewMyUserMenu = false;
    }
    if (/^OWN_USER/.test(code) && !self.viewUsersMenu && !self.viewOwnAccountUsersMenu) {
      self.viewMyUserMenu = true;
    }
    if (/REPORTS_SUMMARY/.test(code)) {
      self.viewReportsSummaryMenu = true;
    }
    if (/REPORTS_CDR/.test(code)) {
      self.viewReportsCdrMenu = true;
    }

    var manageMatch = code.match(/(.*)_MANAGE$/);
    if (manageMatch) {
      self.expandCodes.push(manageMatch[1] + '_LIST');
      self.expandCodes.push(manageMatch[1] + '_VIEW');
      self.expandCodes.push(manageMatch[1] + '_CREATE');
      self.expandCodes.push(manageMatch[1] + '_UPDATE');
    } else {
      var readonlyMatch = code.match(/(.*)_READONLY$/);
      if (readonlyMatch) {
        self.expandCodes.push(readonlyMatch[1] + '_LIST');
        self.expandCodes.push(readonlyMatch[1] + '_VIEW');
      } else {
        self.expandCodes.push(code);
      }
    }

    if (/SYSTEM/.test(code)) {
      self.viewAdminMenu = true;
    }
  }

  self.has = function (code, type, id) {
    var currentAccountId = sessionStorage.getItem('wAccountId') || sessionStorage.getItem('accountId');
    var currentUserId = sessionStorage.getItem('wUserId') || sessionStorage.getItem('userId');
    if (self.expandCodes.indexOf((type === 'SYSTEM' ? '' : 'ANY_') + type + ':' + code) !== -1) {
      return true;
    } else if (self.expandCodes.indexOf('OWN_' + type + ':' + code) !== -1) {
      if (id) {
        if (['ACCOUNT', 'ACCOUNT_USERS'].indexOf(type) !== -1 && id == currentAccountId) {
          return true;
        } else if (type === 'USER' && id == currentUserId) {
          return true;
        }
      } else {
        if (window.scDebug) {
          console.error('Check permission ' + 'OWN_' + type + ':' + code + ' require id parameter');
        }
      }
    } else if (type === 'ACCOUNT_USERS' && self.expandCodes.indexOf('ANY_USER:' + code) !== -1) {
      return true;
    }
  };

  self.hasStaringWith = function () { 
    //prefix is unused parameter
  };
};