var formErrorParser = require('../../common/utils/formErrorParser');
module.exports = /*@ngInject*/ function ($scope, $mdDialog, gUser, RoleFactory) {
  var self = this;
  self.gUser = gUser;
  self.busyMask = true;
  self.rolesMap = {};
  new RoleFactory().query(function (roles) {
    self.initialRoleCodes = (gUser.roles || []).map(function(r) {
      return r.code;
    });
    self.roles = roles;
    for (var i = 0; i < roles.length; i++) {
      var code = roles[i].code;
      self.rolesMap[code] = self.initialRoleCodes.indexOf(code) !== -1;
    }
    self.busyMask = false;
  });

  self.cancel = function () {
    $mdDialog.cancel('cancel');
  };

  self.save = function() {
    var setRoles = {};
    for (var r = 0; r < self.initialRoleCodes.length; r++) { //track changes of existing roles
      var code = self.initialRoleCodes[r];
      setRoles[code] = self.rolesMap[code];
    }
    for (var role in self.rolesMap) { // and include ony new added roles
      if (self.rolesMap[role]) {
        setRoles[role] = true;
      }
    }
    self.busyMask = true;
    self.gUser.$setRoles(setRoles, function(user) {
      $mdDialog.hide(user);
    }, function (errorResponse) {
      self.formErrors = formErrorParser(errorResponse, self.rolesForm);
      self.busyMask = false;
    });
  };
};