var formErrorParser = require('../common/utils/formErrorParser');
var accountUserDescribe = require('../mocks/accountUserDescribe.json');

module.exports = /*@ngInject*/ function ($scope, $rootScope, $portalHead, $mdDialog, $q, gUserPromises, gAccount, UcpFactory, importUsers, onUserImported, IntegrationFactory) {
  var self = this;

  self.gAccount = gAccount;
  self.usersCount = importUsers.length;
  self.busyMask = true;

  self.gAccount.$get(function (account) {
    self.gAccountUcis = account.ucis.map(function (uci) {
      return { id: uci.ucpId, label: uci.ucpLabel };
    });
    if (self.gUser.uci.ucpAuthLevel === 'INHERIT') {
      self.ucps = self.gAccountUcis;
      if (!self.gUser.uci.ucpId) {
        self.gUser.uci.ucpId = self.ucps[0].id;
      }
    }
  });

  // gUserPromises.describe.then(function (describe) {
    self.describe = accountUserDescribe;
    self.niceFields = {};
    for (var f = 0; f < self.describe.sections[0].fields.length; f++) {
      var field = self.describe.sections[0].fields[f];
      self.niceFields[field.name] = self.describe.sections[0].fields[f];
    }
    self.niceFields['uci.ucpId'] = { label: 'Service Provider' };
    self.niceFields['uci.ucpUserId'] = { label: 'UCP User ID' };
    self.niceFields['uci.credentials.username'] = { label: 'UCP Username' };
    self.niceFields['uci.credentials.password'] = { label: 'UCP Password' };
    self.niceFields['uci.credentials'] = { label: 'UCP Credentials' };
  // });

  self.users = importUsers;
  var chain = $q.when();
  for (var i = 0; i < self.users.length; i++) {
    chain = chain.then(nextUserImport(i)).finally(finallyUserImport(i));
  }

  new IntegrationFactory().query(function(integrations) {
    self.integrations = integrations.map(function(i) {
      return { id: i.code, label: i.name };
    });
  });

  function nextUserImport(i) {
    return function () {
      self.currentIndex = i;
      self.determinateValue = (100 * self.currentIndex) / self.usersCount;
      return buildCreateUserPromise(self.users[i]);
    };
  }
  function finallyUserImport() {
    return function () {
      if (self.currentIndex === self.usersCount - 1) {
        self.cancel();
      }
    };
  }

  function buildCreateUserPromise(user) {
    self.gUser = new self.gAccount.users(user);
    self.stopDefer = $q.defer();
    self.saveCurrentUser();
    return self.stopDefer.promise;
  }

  self.saveCurrentUser = function () {
    delete self.formErrors;
    delete self.otherUser;
    var origIntegrations = angular.copy(self.gUser.integrations);
    if (origIntegrations && origIntegrations.length) {
      self.gUser.integrations = origIntegrations.map(function(i) {
        return { code: i };
      });
    }
    self.gUser.$save(function (user) {
      onUserImported(user);
      self.stopDefer.resolve();
    }, function (error) {
      self.gUser.integrations = origIntegrations;
      if (self.ignoreErrors) {
        self.stopDefer.resolve();
      } else {
        if (error.status === 422 && error.data && error.data.errors && error.data.errors.length && error.data.errors[0].code === 'AlreadyRegistered' ) {
          var VgisUcp = new UcpFactory();
          var UcpService = new VgisUcp({ id: self.gUser.uci.ucpId });
          UcpService.$findUserByUcpId(self.gUser.ucpUserId, function(otherUser) {
            if (otherUser.accountId === self.gUser.account.id) {
              self.stopDefer.resolve();
            } else {
              self.otherUser = otherUser;
            }
          });
        }
        self.formErrors = formErrorParser(error, self.importUsersForm, self.niceFields);
        // self.formErrors = error.data.messages ? error.data.messages.join(', ') : error.data;
        $rootScope.toggleRightBar(false);
      }
    });
  };

  self.transferOtherUser = function(ev) {
    var confirm = $mdDialog.confirm()
      .title('Transfer another user to this account. Are you sure?')
      .textContent(
        'AccountId:' + self.otherUser.accountId + ' | ' +
        'UserId:' + self.otherUser.id + ' | ' + self.otherUser.status +
        ' (ucpUserId:' + self.otherUser.uci.ucpUserId + ')'
      ).ariaLabel('Transfer')
      .targetEvent(ev)
      .multiple(true)
      .clickOutsideToClose(true)
      .ok('Yes')
      .cancel('Cancel');

    $mdDialog.show(confirm).then(function() {
        console.info("trnasfer!")
      }, function() {}//on cancel do nothing
    ); 
  };

  self.skip = function () {
    if (self.importUsersForm) {
      for (var f in self.niceFields) {
        var fName = self.niceFields[f].label;
        if (self.importUsersForm[fName]) {
          self.importUsersForm[fName].$setValidity("custom", true);
          delete self.importUsersForm[fName].$customError;
        }
      }
      self.importUsersForm.$setPristine();
    }
    self.stopDefer.resolve();
  };

  self.cancel = function () {
    $mdDialog.cancel();
  };
};