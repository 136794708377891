require('../scField');
require('./scSections.scss');

angular.module("sc.common.sections", [
  'sc',
  'sc.common.field'
]).directive('scSections', function () {
  return {
    restrict: "E",
    scope: {
      scSections: '=',
      scOwner: '=',
      scConnectorCode: '@',
      scLocks: '=',
      showAll: '='
    },
    template: require('./scSections.html'),
    controller: /*@ngInject*/ function ($scope) {
      $scope.toggleOptionalFields = function (showAll) {
        var self = this;
        $scope.toggleOptionalView = showAll;
        if (typeof (self.optionalCount) === 'undefined') {
          self.optionalCount = $scope.scSections.$optionalCount;
        } else {
          $scope.scSections.$optionalCount = self.optionalCount;
        }
      };
    }
  };
});