require('./refDialog.scss');

module.exports = /*@ngInject*/ function ($rootScope, mdPanelRef, $injector, $scSystem, $q, references, refType, scServices, scDescribeCbs, scConnectorCode, eventId, $scIntegrationErrorParsers) {
  var self = this;
  self.vonageIcon = require('../../../../activities/gunify/img/48_white.png');
  self.scConnectorCode = scConnectorCode;
  self.helpPanelKey = scConnectorCode + '_refDialog';
  self.refType = refType;
  self.references = references;
  self.formErrors = {};
  self.errors = {};
  self.sections = {};
  self.niceFields = {};
  self.scServices = {};
  self.eventId = eventId;

  self.layouts = {};
  self.currentLayouts = {};
  self.resolveDescribeFunctions = {};
  
  if (scConnectorCode !== 'gunify') {
    self.externalEdit = true;
  }
  
  var integrations = [];
  if ($injector.has('$scActivities')) {
    integrations = $injector.get('$scActivities').all;
  } else if ($injector.has('$scConnectors')) {
    integrations = $injector.get('$scConnectors').all;
  }
  self.connectorIconSmall = (scConnectorCode !== 'gunify' && integrations[scConnectorCode] && integrations[scConnectorCode].iconSmall) ? integrations[scConnectorCode].iconSmall : self.vonageIcon;
 
  self.openInCRM = $rootScope.openInCRM;

  function resolveService(servicePromises, s) {
    if (servicePromises[s] && servicePromises[s].then) {
      servicePromises[s].then(function (result) {
        if (result._vgis && result._vgis.layoutId) {
          self.currentLayouts[s] = result._vgis.layoutId;
        }
        self.scServices[s] = result;
      }, function(errorResult) {
        self.formErrors[s] = errorResult.data.message || errorResult.data;
        if (errorResult.status === 404) {
          var customErrorParser = $scIntegrationErrorParsers[(self.scConnectorCode || '').toLowerCase()+'ErrorParser'];
          if (errorResult.data.integrationError && typeof(customErrorParser) === 'function') {
            var errorMsg = errorResult.data ? errorResult.data.message : errorResult.data;
            try {
              var integrationError = errorResult.data.integrationError;
              var parsed = customErrorParser(integrationError, self['form' + s], self.niceFields[self.currentRef.name]);
              if (parsed) {
                errorMsg = parsed;
              }
            } catch (e) {
              console.error('Custom "'+scConnectorCode+'" parser exception!', e);
            }
            self.formErrors[s] = { message: "The requested resource does not exist in the remote system.", 
              errors: {
                "": errorMsg
              } 
            };
          }
        }
      });
    } else {
      self.scServices[s] = servicePromises[s];
    }
  }
  //collect errors
  for (var s in scServices) {
    if(scServices[s]) {
      resolveService(scServices, s);
    }
  }

  function resolveDescribe(refName) {
    delete self.sections[refName];
    delete self.formErrors[refName];
    var storedLayoutIdKey = $scSystem.userId + '.' + self.scConnectorCode + refName + 'LayoutId';
    return scDescribeCbs[refName]().then(function (itemDescribe) {
      self.layouts[refName] = itemDescribe.availableLayouts;
      if (!self.currentLayouts[refName]) {
        self.currentLayouts[refName] = itemDescribe.layout.id;
      }
      localStorage.setItem(storedLayoutIdKey, self.currentLayouts[refName]);
      return itemDescribe.layout;
    }, function(errorDescribe) {
      //self.formErrors[refName] = errorDescribe.data || errorDescribe;
      self.formErrors[refName] = errorDescribe.data ? (errorDescribe.data.message ? errorDescribe.data.message : errorDescribe.data) : errorDescribe;
      localStorage.removeItem(storedLayoutIdKey); //cleanup stored layoutId
      return $q.reject(errorDescribe);
    }).then(function (itemDescribe) {
      self.sections[refName] = [];
      self.niceReferences = {};
      for (var r in self.references) {
        var reference = self.references[r];
        self.niceReferences[reference.name] = reference;
        if (reference.name === refName) {
          self.sections[refName] = itemDescribe.sections;
          self.niceFields[refName] = {};
          for (var s in self.sections[refName]) {
            if (self.sections[refName][s]) {
              var section = self.sections[refName][s];
              // if (!section.fields || !section.fields.length) {
              //   break;
              // }
              if (section.fields && section.fields.length) {
                for (var f in section.fields) {
                  if (section.fields[f]) {
                    var field = section.fields[f];
                    if (field.type !== 'composite') {
                      self.niceFields[refName][field.name] = field;
                    } else if (field.fields.length) {
                      for (var ff = 0; ff < field.fields.length; ff++) {
                        self.niceFields[refName][field.fields[ff].name] = field.fields[ff];
                      }
                    }
                  }
                }
              }
            }
          }
          break;
        }
      }
    }, function (itemDescribeError) {
      self.formErrors[refName] = itemDescribeError.data || itemDescribeError;
      if (scDebug) {
        console.error('itemDescribeError', itemDescribeError);
      }
    });
  }

  Object.keys(scDescribeCbs).forEach(function(refName) {
    self.resolveDescribeFunctions[refName] = function (resourceName, layoutId, refreshMetadata) {
      scDescribeCbs[resourceName] = function() {
        return self.scServices[resourceName].constructor.describe({ layoutId: layoutId, refresh: refreshMetadata }).$promise;
      };
      resolveDescribe(resourceName);
    };
  });

  self.onTabSelected = function(ref) {
    if (!self.layouts[ref.name]) {
      if (!self.currentRef) {
        resolveDescribe(ref.name);
      } else {  //delay net request to not block css animation on tab switch
        setTimeout(function() {
          resolveDescribe(ref.name);
        }, 250);
      }
    }
    self.currentRef = ref;
    $rootScope.scResourceType = self.currentRef.name;
  };

  self.changeLayout = function (resourceName) {
    resolveService(scServices, resourceName);
    var layoutId = self.currentLayouts[resourceName];
    localStorage.setItem($scSystem.userId + '.' + self.scConnectorCode + resourceName + 'LayoutId', layoutId);
    if (typeof self.resolveDescribeFunctions[resourceName] === 'function') {
      self.resolveDescribeFunctions[resourceName](resourceName, layoutId);
    }
  };

  self.refreshMetadata = function() {
    var resourceName = self.currentRef.name;
    if(!self.optionalCount) {
      self.optionalCount = {};
    }
    if(!self.optionalCount[resourceName]) {
      self.optionalCount[resourceName] = angular.copy(self.optionalCounts[resourceName]);
    }
    self.resolveDescribeFunctions[resourceName](resourceName, self.currentLayouts[resourceName], true);
  }; 
  
  self.toggleOptionalFields = function (refName, showAll) {
    if(!self.showAll) {
      self.showAll = {};
    }
    self.showAll[refName] = showAll;
    if(!self.optionalCount) {
      self.optionalCount = angular.copy(self.optionalCounts);
    } else self.optionalCounts = angular.copy(self.optionalCount);
  }; 

  self.cancel = function () {
    mdPanelRef.close().then(function() {
      $rootScope.scResourceType = self.prevResourceType;
      mdPanelRef.destroy();
    });
  };
  
  self.save = function () {
    if (self.currentRef) {
      delete self.formErrors[self.currentRef.name];
      var form = self['form' + self.currentRef.name];
      if (form.$valid) {
        self.busyMask = true;
        //if (self.currentLayouts[self.currentRef.name]) {
          if (!self.scServices[self.currentRef.name]._vgis) {
            self.scServices[self.currentRef.name]._vgis = {
              resourceType: self.currentRef.name,
              connector: self.scConnectorCode
            };
          }
          // if (self.scServices[self.currentRef.name]._getId) { // update 
          var model = self.scServices[self.currentRef.name];
          var currentNiceField = self.niceFields[self.currentRef.name];
          for (var p in model.toJSON()) {
            if (p && p !== '_vgis') {
              var fMeta = currentNiceField[p];
              if (!fMeta || fMeta.updateable === false) { // remove non updatable fields
                if (!(self.scConnectorCode === 'clio' && p === 'custom_field_values')) {
                  delete self.scServices[self.currentRef.name][p];
                }
              }
              //clio custom time field dirty hack
              if (self.scConnectorCode === 'clio' && p === '10100507' && typeof(model.toJSON()[p]) === 'object') {
                self.scServices[self.currentRef.name][p] = model.toJSON()[p].toISOString();
              }
            }
          }
          // Clio Contact dirty hack
          if (self.scConnectorCode === 'clio' && self.currentRef.name === 'Contact' && model.type) {
            var clioContactType = model.type.id || model.type.value || model.type;
            if (clioContactType === 'Company') {
              delete model.first_name;
              delete model.last_name;
              delete model.middle_name;
              delete model.prefix;
              delete model.title;
            } else if (clioContactType === 'Person') {
              delete model.name;
            }
          }
          // }
          self.scServices[self.currentRef.name]._vgis.layoutId = self.currentLayouts[self.currentRef.name];
          if (self.eventId) {
            self.scServices[self.currentRef.name]._vgis.eventId = self.eventId;
          }
        //}
        self.scServices[self.currentRef.name].$save().then(function (result) {
          self.busyMask = false;
          if (result._vgis) {
            var vgis = {
              id: result._vgis.externalId,
              localId: result._vgis.id,
              label: result._vgis.label,
              type: result._vgis.resourceType,
              connector: result._vgis.connector,
              _raw: angular.copy(result)
            };
            if (self.niceReferences[result._vgis.resourceType] && self.niceReferences[result._vgis.resourceType].valueType) {
              vgis.valueType = self.niceReferences[result._vgis.resourceType].valueType;
            }
            $rootScope.scResourceType = self.prevResourceType;
            mdPanelRef.scDone(vgis);
          } else {
            console.info('Should never go here');
            $rootScope.scResourceType = self.prevResourceType;
            mdPanelRef.scDone(result.toJSON());
          }
        }, function (errorResult) {
          self.busyMask = false;
          var errorMsg = errorResult.data ? errorResult.data.message : errorResult.data;
          if (errorResult.status === 400 || errorResult.status === 422) {
            var customErrorParser = $scIntegrationErrorParsers[(scConnectorCode || '').toLowerCase()+'ErrorParser'];
            if (errorResult.data.integrationError && typeof(customErrorParser) === 'function') {
              try {
                var integrationError = errorResult.data.integrationError;
                var parsed = customErrorParser(integrationError, form, self.niceFields[self.currentRef.name]);
                if (parsed) {
                  errorMsg = parsed;
                }
              } catch (e) {
                console.error('Custom "'+scConnectorCode+'" parser exception!', e);
              }
            } else {
              var errs = [];
              if (errorResult.data instanceof Array) {
                for (var e = 0; e < errorResult.data.length; e++) {
                  if (errorResult.data[e].message) {
                    errs.push(errorResult.data[e].message + " (" + (errorResult.data[e].code || errorResult.data[e].errorCode) + ")");
                  }
                }
              } else if (errorResult.data.message) {
                errs.push(errorResult.data.message + " (" + (errorResult.data.code || errorResult.data.errorCode) + ")");
              } else {
                errs.push(errorResult.data);
              }
              errorMsg = errs.join(', ');
            }
          }
          self.formErrors[self.currentRef.name] = errorMsg;
        });
      } else {
        form.$setDirty(true);
        var errorNames = [];
        angular.forEach(form.$error, function (type) {
          angular.forEach(type, function (field) {
            field.$touched = true;
            field.$setDirty();
            errorNames.push(field.$name);
          });
        });
        self.formErrors[self.currentRef.name] = "Validation failed. (" + errorNames.join(", ") + ")";
      }
    } else {
      console.error('refDialog current ref error');
    }
  };
  self.prevResourceType = $rootScope.scResourceType;
};