angular.module('sc.model.accountSyncResources', [
  'sc',
  'ngResource'
])
.factory('AccountSyncResourcesFactory', /*@ngInject*/ function($injector, $resource, $scSystem) {
  var AccountSyncResourcesModel = function (attributes) {
    var self = this;
    if (!attributes) {
      attributes = {};
    }
    if (attributes.account) {
      self.account = attributes.account;
      attributes.accountId = attributes.account.accountId;
      attributes.accountId = attributes.account.id;
    }
    var AccountSyncResources = $resource($scSystem.portalUrl+'/accounts/:accountId/sync/:resources', {
      accountId: function(that) {
        return that ? that.accountId || attributes.accountId || -1 : attributes.accountId || -1;
      },
      resources: function(that) {
        return that ? that.resources || attributes.resources || 'contacts' : attributes.resources || 'contacts';
      }
    }, {
      get: {
        method: 'GET',
        isArray: false
      }
    });

    return AccountSyncResources;
  };

  return AccountSyncResourcesModel;
});