angular.module('sc.model.layout', [
    'sc',
    'ngResource'
  ])
  .factory('LayoutFactory', /*@ngInject*/ function($injector, $resource, $scSystem) {
    var LayoutResource = function () { //attributes parameter is unused
      var Layout = $resource($scSystem.portalUrl+'/accounts/:accountId/integrations/:integrationCode/resources/:resourceType/layouts/:id', {
        id: '@id',
        resourceType: '@resourceType',
        accountId: '@accountId',
        integrationCode: '@integrationCode'
      }, {
        create:{
          method: 'POST'
        },
        update: {
          method: 'PUT'
        },
      });

      Layout.prototype.$save = function(success, error) {
        return (this.id) ? this.$update(success, error) : this.$create(success, error);
      };
  
      return Layout;
    };
  
    return LayoutResource;
  });