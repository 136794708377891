require('./user');

angular.module('sc.model.userSettings', [
  'sc',
  'ngResource',
  'sc.model.user'
])
.factory('UserSettingsFactory', /*@ngInject*/ function($injector, $resource, $scSystem) {
  var UserSettingsResource = function (attributes) {
    var self = this;
    if (!attributes) {
      attributes = {};
    }
    if (attributes.user) {
      self.user = attributes.user;
      attributes.accountId = attributes.user.accountId;
      attributes.userId = attributes.user.id;
    }
    var UserSettings = $resource($scSystem.portalUrl+'/accounts/:accountId/users/:userId/:connector/settings/:feature/:id', {
      accountId: function(that) {
        return that ? that.accountId || attributes.accountId || -1 : attributes.accountId || -1;
      },
      userId: function(that) {
        return that ? that.userId || attributes.userId : attributes.userId;
      },
      connector: function(that) {
        return that ? that.connector || attributes.connector : attributes.connector;
      },
      feature: function(that) {
        return that ? that.feature || attributes.feature : attributes.feature;
      },
      id: '@id'
    }, {
      update: {
        method: 'PATCH',
        transformRequest: function (data) {
          var trData = {};
          for (var property in data) {
            if (!/^\$/.test(property) && property !== 'toJSON' && typeof(data[property]) !== 'undefined') {
              if (data[property] && typeof(data[property].value) !== 'undefined') {
                trData[property] = data[property];
              } else {
                trData[property] = { value: data[property] };
              }
            }
          }
          return angular.toJson(trData);
        }
      },
      put: {
        method: 'PUT'
      },
      get: {
        method: 'GET',
        transformResponse: function(data) {
          var settings = angular.fromJson(data);
          var trData = {};
          for (var property in settings) {
            if (!/^\$/.test(property) && property !== 'toJSON') {
              trData[property] = settings[property];
            }
          }
          return trData;
        }
      },
      delete: {
        method: 'DELETE'   
      },
      describe: {
        method: 'GET',
        url: $scSystem.portalUrl + '/accounts/:accountId/users/:userId/:connector/settings/metadata' 
      }
    });

    // Object.defineProperty(UserSettings.prototype, '_getId', { 
    //   value: 'settings',
    //   enumerable: false
    // });

    return UserSettings;
  };

  return UserSettingsResource;
})
.factory('UserConferenceFactory', /*@ngInject*/ function($injector, $resource, $scSystem) {
  var UserConferenceResource = function (attributes) {
    var self = this;
    if (!attributes) {
      attributes = {};
    }
    if (attributes.user) {
      self.user = attributes.user;
      attributes.accountId = attributes.user.accountId;
      attributes.userId = attributes.user.id;
    }
    var UserConference = $resource($scSystem.portalUrl+'/accounts/:accountId/users/:userId/:connector/settings/:feature/:id', {
      accountId: function(that) {
        return that ? that.accountId || attributes.accountId || -1 : attributes.accountId || -1;
      },
      userId: function(that) {
        return that ? that.userId || attributes.userId : attributes.userId;
      },
      connector: function(that) {
        return that ? that.connector || attributes.connector : attributes.connector;
      },
      feature: function(that) {
        return that ? that.feature || attributes.feature : attributes.feature;
      },
      id: '@id'
    }, {
      put: {
        method: 'PUT'
      },
      get: {
        method: 'GET',
        isArray: true
      },
      delete: {
        method: 'DELETE'   
      },
      describe: {
        method: 'GET',
        url: $scSystem.portalUrl + '/accounts/:accountId/users/:userId/:connector/settings/metadata' 
      }
    });

    return UserConference;
  };

  return UserConferenceResource;
});