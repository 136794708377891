require('./integrationResourcesDlg.scss');

var formErrorParser = require('../../common/utils/formErrorParser');
module.exports = /*@ngInject*/ function ($scope, $mdDialog, gAccount, integrationCode, logoForIntegration, ResourceFactory) {
  var self = this;
  self.gAccount = gAccount;
  self.integrationCode = integrationCode;
  self.logoForIntegration = logoForIntegration;
  self.busyMask = true;
  self.integrationsMap = {};

  // self.enableResourceCustomization = scDevEnv; // enable only in DEV env
  
  self.resourcesModel = new ResourceFactory({ accountId: self.gAccount.id, integrationCode: self.integrationCode });

  self.filterResources = function(txt) {
    var t = (txt || '').toLowerCase();
    var resourceFilterItems = document.querySelectorAll('md-list-item.sc-relative-resourceFilterItems');
    resourceFilterItems.forEach(function(rc) {
      if ((rc.dataset.label || '').toLowerCase().includes(t)) {
        rc.style.display = '';
      } else {
        rc.style.display = 'none';
      }
    });
  };

  self.loadResources = function(refresh) {
    delete self.resourcesMap;
    self.busyMask = true;
    self.resourcesModel.query({ refresh: refresh }, function (resources) {
      self.resourcesMap = { 'Contacts': [], 'Activities': [] };
      self.resourcesExclusionMap = {  'ExcludedContacts': [], 'ExcludedActivities': []};
      resources.forEach(function(r) {
        if (r.isContactable) {
          if (r.isEnabled) {
            self.resourcesMap.Contacts.push(r);
          } else {
            self.resourcesExclusionMap.ExcludedContacts.push(r);
          }          
        } else {
          if (r.isEnabled) {
            self.resourcesMap.Activities.push(r);
          } else {
            self.resourcesExclusionMap.ExcludedActivities.push(r);
          }          
        }
        self.resourcesMap.Contacts.sort(function(a , b){ //Contacts order filter.
          if(a.order > b.order) return 1;
          if(a.order < b.order) return -1;
          return 0;
        });
        self.resourcesExclusionMap.ExcludedContacts.sort(function(a , b){ //AvailableContacts label filter.
          if(a.label > b.label) return 1;
          if(a.label < b.label) return -1;
          return 0;
        });
        self.resourcesMap.Activities.sort(function(a , b){ //Activities order filter.
          if(a.order > b.order) return 1;
          if(a.order < b.order) return -1;
          return 0;
        });
        self.resourcesExclusionMap.ExcludedActivities.sort(function(a , b){ //AvailableActivities label filter.
          if(a.label > b.label) return 1;
          if(a.label < b.label) return -1;
          return 0;
        });
      });
      self.busyMask = false;
    }, function (errorResponse) {
      self.formErrors = formErrorParser(errorResponse, self.integrationsForm);
      self.busyMask = false;
    });
  };
  self.loadResources();

  self.save = function() {
    delete self.formErrors;
    self.busyMask = true;
    for (var j=0; j < self.resourcesExclusionMap.ExcludedContacts.length; j++) { //AvailableContacts
      self.resourcesExclusionMap.ExcludedContacts[j].order = j;
      self.resourcesExclusionMap.ExcludedContacts[j].isEnabled = false;
    }
    for (var i=0; i < self.resourcesMap.Contacts.length; i++) { //Contacts
      self.resourcesMap.Contacts[i].order = i;
      self.resourcesMap.Contacts[i].isEnabled = true;
    }
    for (var l=0; l < self.resourcesExclusionMap.ExcludedActivities.length; l++) { //AvailableActivities
      self.resourcesExclusionMap.ExcludedActivities[l].order = l;
      self.resourcesExclusionMap.ExcludedActivities[l].isEnabled = false;
    }
    for (var k=0; k < self.resourcesMap.Activities.length; k++) { //Activitites
      self.resourcesMap.Activities[k].order = k;
      self.resourcesMap.Activities[k].isEnabled = true;
    }
    self.mergeResources = self.resourcesMap.Contacts.concat(self.resourcesExclusionMap.ExcludedContacts, self.resourcesMap.Activities, self.resourcesExclusionMap.ExcludedActivities);
    self.resourcesModel.update(self.mergeResources, function(resources) {
      self.busyMask = false;
      $mdDialog.cancel(resources);
    }, function(errorResponse){
      self.busyMask = false;
      self.formErrors = formErrorParser(errorResponse, self.integrationsForm);
    });
  };

  self.cancel = function () {
    $mdDialog.cancel('cancel');
  };

  // self.onResourceChange = function(resource) {
  //   delete self.formErrors;
  //   self.busyMask = true;
  //   resource.$update(angular.noop, function (errorResponse) {
  //     self.formErrors = formErrorParser(errorResponse, self.integrationsForm);
  //   }).finally(function() {
  //     self.busyMask = false;
  //   });
  // };

  self.resetResources = function() {
    $mdDialog.show(
      $mdDialog.confirm()
        .title('Are you sure?')
        .textContent('Reset selections (default resources appearance).')
        .multiple(true)
        .ok('Yes')
        .cancel('No')
    ).then(function () {
      self.busyMask = true;
      self.resourcesModel.reset(function(resources) {
        self.resources = resources;
        self.busyMask = false;
        self.loadResources();
      }, function (errorResponse) {
        self.busyMask = false;
        self.formErrors = formErrorParser(errorResponse, self.integrationsForm);
      });
    }, angular.noop);
  };

  self.editResource = function(ev, resource) {
    $mdDialog.show({
      controller: require('./integrationResourceDlgCtrl'),
      controllerAs: 'integrationResourceDlgCtrl',
      template: require('./integrationResourceDlg.html'),
      parent: angular.element(document.body),
      targetEvent: ev,
      scope: $scope.$new(true),
      preserveScope: false,
      multiple: true,
      locals: {
        gAccount: self.gAccount,
        integrationCode: integrationCode,
        logoForIntegration: self.logoForIntegration,
        resource: resource
      }
    }).then(function (updatedResource) {
      if (updatedResource.isContactable !== resource.isContactable) {
        if (resource.isContactable) {
          self.resourcesMap.Contacts.splice(self.resourcesMap.Contacts.indexOf(resource), 1);
          self.resourcesMap.Activities.push(updatedResource);
        } else {
          self.resourcesMap.Activities.splice(self.resourcesMap.Activities.indexOf(resource), 1);
          self.resourcesMap.Contacts.push(updatedResource);
        }
      }
    }, function (err) {
      console.error(err);
    });
  };
};