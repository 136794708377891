var VirtualRepeatLoader = require('../../gcf/sc/common/virtualRepeatRestfulLoader');

module.exports = /*@ngInject*/ function ($rootScope, $flash, $scope, $state, gUserService, gUserId, gAccountId, $mdDialog, VgisCdrsReport, UserNotificationsFactory, IntegrationFactory) {
  var self = this;
  self.gUserId = gUserId;
  self.gAccountId = gAccountId;
  var IntegrationModel = new IntegrationFactory({ accountId: gAccountId, userId: gUserId});
  var activationCode = undefined;

  if (!$rootScope.scPermissions.has('_VIEW', 'USER', gUserId) && !$rootScope.scPermissions.has('_VIEW', 'ACCOUNT_USERS', gAccountId)) {
    self.forbidden = true;
    self.error = 'Missing permission to see user #' + gUserId;
    return;
  }
  self.showAccountInfo = $rootScope.scPermissions.has('_VIEW', 'ACCOUNT', self.gAccountId);

  function getUser() {
    gUserService.$get(function (user) {
      activationCode = user.activationCode;
      self.gUser = user;
      if (!self.gUser.isFree && !self.gUser.isInternal && self.showAccountInfo) {
        self.gUser.account.$get(function (account) {
          self.gAccount = account;
        });
      }
      
      (self.gUser.integrations || []).forEach(function(vgi) {
        IntegrationModel.profile({ id: vgi.code, t: new Date().getTime()  }, function(profile) {
          vgi.healthy = profile.healthy;
          vgi.userName = profile.userName;
          vgi.healthLoaded = true;
        }, function(err) {
          if (err.status == 404) {
            delete $flash.error;
            vgi.neverUsed = true;
          }
          vgi.healthLoaded = true;
        });
      });
    });
  }
  getUser();
  
  var gNotifyService = new UserNotificationsFactory({ accountId: self.gAccountId, usersPath: 'users', userId: self.gUserId });
  gNotifyService.getHealth(function(response) {
    self.notify = response;
    if(response.status === "HEALTHY") {
      self.readStatus = 'Connected.';
    } else self.readStatus = 'Not Connected.';
  }, function(err) {
    self.errorNotify = err.data.message;
  });


  // var VgisUserSettings = new UserSettingsFactory({ accountId: self.gAccountId, userId: self.gUserId });
  // VgisUserSettings.describe(function (describe) {
  //   self.describeSettings = describe.layout;
  // }, function (err) {
  //   self.errorSettings = err.data.message;
  // });

  // self.gUserSettings = new VgisUserSettings({});

  // function getUserSettings() {
  //   VgisUserSettings.get(function (userSettings) {
  //     var settings = {};
  //     for (s = 0; s < userSettings.length; s++) {
  //       var setting = userSettings[s];
  //       if (setting) {
  //         settings[setting.key] = setting.value;
  //         self.gUserSettings = new VgisUserSettings(settings);
  //       } 
  //     }
  //   }, function (err) {
  //     self.errorSettings = err.data.message;
  //   });
  // }
  // getUserSettings();
 

  if (self.dynamicFetch) {
    self.dynamicFetch.refresh();
  }

  self.dynamicFetch = new VirtualRepeatLoader(VgisCdrsReport, {
    idKey: 'eventId',
    filters: function () {
      if (gUserId) {
        return {
          "states": ["ANSWERED", "MISSED", "REJECTED", "CANCELLED"],
          "accountIds": [self.gAccountId],
          "userIds": [self.gUserId]
        };
      }
    },
    onUpdate: function () {
      setTimeout(function () {
        $scope.$apply();
      });
    }
  });

  self.durationSec = function (durationMs) {
    return durationMs ? ((durationMs < 1000) ? 1 : (durationMs / 1000)) : 0;
  };

  self.onUpdate = function (error, user) {
    if (user) {
      self.gUser = user;
    }
  };

  self.onUciUpdate = function () {
    getUser();
  };

  // self.onSettingsUpdate = function () {
  //   getUserSettings();
  // };

  self.calcDeleteDate = function () {
    if (!self.gUser.deletedDate) {
      return 'N/A';
    }
    var deletedDate = new Date(self.gUser.deletedDate);
    // deletedDate.setDate(deletedDate.getDate() + 4);
    return deletedDate;
  };

  self.enableUser = function (ev) {
    var confirm = $mdDialog.confirm()
      .title('Are you sure?')
      .textContent('Enable user ' + self.gUser.firstName + ' ' + self.gUser.lastName)
      .ariaLabel('Change User Status')
      .targetEvent(ev)
      .ok('Yes')
      .cancel('No');
    $mdDialog.show(confirm).then(function () {
      self.gUser.$setEnabled(function () {
        // success/error can be handled
      }, function () {
        // success/error can be handled 
      });
    }, function () {
      console.info('Enable user not confirmed');
    });
  };

  self.notificationsDialog = function (ev, user, status) {
    var gNotificationService = new UserNotificationsFactory({ accountId: self.gAccountId, usersPath: 'users', userId: self.gUserId, type: 'card'});
    if(status !== 'HEALTHY') {
      $mdDialog.show($mdDialog.alert({
        title: 'Notification Status',
        textContent: 'User is not signed in to send the notifications.',
        ok: 'Close'
      }));
    } else {
      $mdDialog.show({
        controller: require('./tools/notificationsDlgCtrl'),
        controllerAs: 'notificationsDlgCtrl',
        template: require('./tools/notificationsDlg.html'),
        parent: angular.element(document.body),
        targetEvent: ev,
        preserveScope: true,
        locals: {
          gNotificationService: gNotificationService
        }
      }).then(function (data) {
        if(data && data.status === "SUCCESS") {
          self.cardResponse = "Notification sent successfully.";
        } else self.cardResponse = "Please Try Again. ";
      });
    }    
  };


  self.userConfSettingsDialog = function() {
    $state.go('sc.portal.users.item.settings.conferences', { accountId: self.gAccountId, userId: self.gUserId });
  };

  self.invokeAuthorization = function(ev, vgi) {
    this.gUser.$getAuthorizeUrl(vgi.code, function(authorizeUrl) {
      var confirm = $mdDialog.confirm()
      .title('Authorization')
      .textContent('Link a ' + vgi.name + ' identity on behalf of the current user.')
      .targetEvent(ev)
      .ok('Authorize')
      .cancel('Cancel');
      $mdDialog.show(confirm).then(function() {
        if (typeof(activationCode) === "undefined") {
          authorizeUrl += ((authorizeUrl.indexOf('?') === -1 ? '?' : '&')) + "apiUser=true";
        }
        var authWindow = window.open(
          authorizeUrl, 'vgis3_auth', 
          'width=680,height=680,left=' + ((screen.width / 2) - 340) + ',top=' + ((screen.height / 2) - 340)
        );
        var authWatchdog;
        if (authWindow) {
          authWindow.moveTo((screen.width / 2) - 340, (screen.height / 2) - 340);
          authWatchdog = window.setInterval(function(){
            if (authWindow.closed) {
              window.clearInterval(authWatchdog);
              IntegrationModel.profile({ id: vgi.code, t: new Date().getTime()  }, function(profile) {
                delete vgi.neverUsed;
                vgi.healthy = profile.healthy;
                vgi.userName = profile.userName;
                vgi.healthLoaded = true;
              }, function(err) {
                if (err.status == 404) {
                  delete $flash.error;
                  vgi.neverUsed = true;
                }
                vgi.healthLoaded = true;
              });
            }
          }, 1000);
        }
      }, function() {
        if(window.scDebug) {
          console.info('Enable account not confirmed');
        }
      });
    }, function(err) {
      console.error('getAuthState error', err);
    });
  };

  self.revokeAuthorization = function(ev, vgi) {
    var that = this;
    var confirm = $mdDialog.confirm()
    .title('Revoke Authorization')
    .textContent('Unlink the ' + vgi.name + ' identity from the current user. Are you sure?')
    .targetEvent(ev)
    .ok('Yes')
    .cancel('Cancel');
    $mdDialog.show(confirm).then(function() {
      that.gUser.$revokeAuth(vgi.code, function() {
        vgi.healthy = false;
        vgi.neverUsed = true;
        delete vgi.userName;
      });
    }, function() {
      if(window.scDebug) {
        console.info('Enable account not confirmed');
      }
    });
  };
};