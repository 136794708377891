require('./account');
require('./userUci');
require('./integration');
require('./userSettings');
require('./userNotifications');

angular.module('sc.model.user', [
  'sc',
  'ngResource',
  'sc.model.account',
  'sc.model.userUci',
  'sc.model.integration',
  'sc.model.userSettings',
  'sc.model.userNotifications',
]).factory('UserFactory', /*@ngInject*/ function ($injector, $resource, $scSystem, UserUciFactory, IntegrationFactory) {
  function decorateUser(user) {
    user.fullName = user.firstName + ' ' + user.lastName;
    if (user.integrations) {
      user.integrationNames = user.integrations.map(function (i) { return i.name; }).join(', ');
    }
    if (user.ucis) {
      user.ucpNames = user.ucis.map(function (u) { return u.ucpLabel; }).join(', ');
    }
    if (user.roles) {
      user.roleNames = user.roles.map(function (r) { return r.name; }).join(', ');
      user.roleCodes = user.roles.map(function (r) { return r.code; }).join(', ');
    }
  }

  function transformUserResponse(data, headersGetter, status) {
    var jsonData = angular.fromJson(data);
    if ([200, 201].indexOf(status) !== -1) {
      if (jsonData.ucis) {
        var UserUciService = new UserUciFactory({ user: jsonData });
        jsonData.ucis = jsonData.ucis.map(function(uci) {
          return new UserUciService(uci);
        });
      }
      if (jsonData.integrations) {
        var IntegrationService = new IntegrationFactory({ user: jsonData });
        jsonData.integrations = jsonData.integrations.map(function(integration) {
          return new IntegrationService(integration);
        });
      }
      decorateUser(jsonData);
    }
    return jsonData;
  }

  var UserResource = function (attributes) {
    var self = this;
    if (!attributes) {
      attributes = {};
    }
    if (attributes.account) {
      self.account = attributes.account;
      attributes.accountId = attributes.account.id;
    }
    var User = $resource($scSystem.portalUrl + '/:accountsPath/:accountId/users/:id', {
      accountId: function (that) {
        return attributes.accountId || (that ? that.accountId : attributes.accountId);
      },
      accountsPath: function (that) {
        return ((that && that.accountId) || attributes.accountId) ? 'accounts' : null;
      },
      id: '@id'
    }, {
        me: {
          method: 'POST',
          url: $scSystem.extensionsUrl + '/auth',
          transformResponse: function (data) {
            var jsonData = angular.fromJson(data);
            return { id: parseInt(jsonData.userId), accountId: parseInt(jsonData.accountId) };
          }
        },
        count: {
          method: 'GET',
          url: $scSystem.portalUrl + '/:accountsPath/:accountId/users/count'
        },
        query: {
          method: 'GET',
          isArray: true,
          transformResponse: function (data, headersGetter, status) {
            var response = angular.fromJson(data);
            if (status === 200) {
              for (var i = 0; i < response.length; i++) {
                decorateUser(response[i]);
              }
            }
            return response;
          }
        },
        get: {
          transformResponse: transformUserResponse
        },
        update: {
          method: 'PUT',
          transformResponse: transformUserResponse
        },
        create: {
          method: 'POST',
          transformResponse: transformUserResponse
        },
        describe: {
          method: 'GET',
          url: $scSystem.portalUrl + '/:accountsPath/:accountId/users/:id/describe'
        },
        // setIntegrations: {
        //   method: 'PATCH',
        //   url: $scSystem.portalUrl + '/:accountsPath/:accountId/users/:id/integrations'
        // },
        // setRoles: {
        //   method: 'PATCH',
        //   url: $scSystem.portalUrl + '/:accountsPath/:accountId/users/:id/roles'
        // }
      });

    User.prototype.$save = function (success, error) {
      return (this.id) ? this.$update(success, error) : this.$create(success, error);
    };

    User.prototype.$setIntegrations = function (data, successCallback, errorCallback) {
      $resource($scSystem.portalUrlV2 + '/accounts/' + this.accountId + '/users/' + this.id + '/integrations', {}, {
        patch: {
          method: 'PATCH',
          isArray: true
        }
      }).patch(data, successCallback, errorCallback);
    };

    User.prototype.$setRoles = function (data, successCallback, errorCallback) {
      var that = this;
      $resource($scSystem.portalUrl + '/accounts/' + this.accountId + '/users/' + this.id + '/roles', {}, {
        patch: {
          method: 'PATCH',
          isArray: true
        }
      }).patch(data, function (roles) {
        that.roles = roles;
        decorateUser(that);
        successCallback(that);
      }, errorCallback);
    };

    User.prototype.$setEnabled = function (isEnabled, successCallback, errorCallback) {
      var that = this;
      $resource($scSystem.portalUrl + '/accounts/' + this.accountId + '/users/' + this.id + '/enable', {}, {
        patch: {
          method: 'PATCH'
        }
      }).patch({}, function (u) {
        that.status = u.status;
        successCallback(that);
      }, errorCallback);
    };

    User.prototype.$getPermissions = function (data, successCallback, errorCallback) {
      return $resource(
        $scSystem.portalUrl + '/accounts/' + this.accountId + '/users/' + this.id + '/permissions'
      ).get(data, successCallback, errorCallback);
    };

    User.prototype.$transfer = function (data, successCallback, errorCallback) {
      var that = this;
      $resource($scSystem.portalUrl + '/accounts/' + this.accountId + '/users/' + this.id + '/transfer', {}, {
        patch: {
          method: 'PUT'
        }
      }).put(data, function () {
        decorateUser(that);
        successCallback(that);
      }, errorCallback);
    };

    User.prototype.$getAuthorizeUrl = function (integrationCode, successCallback, errorCallback) {
      var that = this;
      $resource(
        $scSystem.portalUrl + '/accounts/' + that.accountId + '/users/' + that.id + '/auth'
      ).save({}, function (authsState) {
        var appsDomain = scDevEnv ? 'https://apps.gunify-'+scVgisEnv+'.vocal-'+(scVgisEnv === 'dev' ? 'dev' : 'qa')+'.com' : 'https://apps.gunify.vonage.com';
        successCallback(appsDomain + '/integrations/' + integrationCode.toLowerCase() + '/authorize?state=' + encodeURIComponent(authsState.state));
      }, errorCallback);
    };
    User.prototype.$revokeAuth = function (integrationCode, successCallback, errorCallback) {
      var that = this;
      $resource(
        $scSystem.portalUrl + '/accounts/' + that.accountId + '/users/' + that.id + '/auth/' + integrationCode.toLowerCase()
      ).delete({}, function (response) {
        successCallback(response);
      }, errorCallback);
    };

    Object.defineProperty(User.prototype, 'account', {
      get: function () {
        if (self.account) {
          return self.account;
        } else {
          var Account = new $injector.get('AccountFactory')({ users: this });
          return new Account({ id: this.accountId });
        }
      }
    });

    Object.defineProperty(User.prototype, 'isDisabled', {
      get: function () {
        return this.status === 'INACTIVE';
      }
    });

    Object.defineProperty(User.prototype, 'isFree', {
      get: function () {
        return parseInt(this.accountId) === -1;
      }
    });

    Object.defineProperty(User.prototype, 'isInternal', {
      get: function () {
        return parseInt(this.accountId) === -2;
      }
    });

    return User;
  };

  return UserResource;
});