angular.module("sc.common.help.content", [
  'sc'
])
.directive('scHelpContent', function ($compile, $templateCache) {
  return {
    restrict: 'A',
    scope: {
      scHelpContent: '=',
    },
    link: function(scope, element) {
      var html = '';
      var type = scope.scHelpContent.match(/\$(icon|link)\{(\S+)\}/);
      if (type && type[1] === "icon") {
        try {
          html = scope.scHelpContent.replace(/\$icon\{(\S+)\}/g, function(m, i) { 
          var iconPath = "icons/ic_"+i+"_24px.svg";
            if ($templateCache.get(iconPath)) return "<md-icon class='help-icon' md-svg-src='icons/ic_"+i+"_24px.svg'></md-icon>";
            else return "";
          });
        } catch (err) {
          console.warn(err);
        }
      }
      else if(type && type[1] === "link") {
        try {
          html = scope.scHelpContent.replace(/\$link\{(\S+)\}/g, function(m, i) { 
            return "<a href='"+i+"' target='_blank'>"+i+"</a>";
          });
        } catch (err) {
          console.warn(err);
        }
      } else html = scope.scHelpContent;
      element.html(html);
      $compile(element.contents())(scope);
    }
  };
});