var formErrorParser = require('../../common/utils/formErrorParser');

module.exports = /*@ngInject*/ function ($scope, $mdDialog, gNotificationService) {
  var self = this;

  self.notify = {
    title: "Title",
    message: "Message"
  };
  
  self.cancel = function () {
    $mdDialog.cancel('cancel');
  };

  self.send = function() {
    delete self.formErrors;
    if (self.notifyForm.$valid) {
      self.busyMask = true;
      gNotificationService.create(self.notify, function (info) {
        $mdDialog.hide(info);
      }, function (errorResponse) {
        self.formErrors = formErrorParser(errorResponse, self.notifyForm, self.niceFields);   
        self.busyMask = false;
      });
    } else {
      self.notifyForm.$setDirty(true);
      var errorNames = [];
      angular.forEach(self.notifyForm.$error, function (type) {
        angular.forEach(type, function (field) {
          field.$touched = true;
          field.$setDirty();
          errorNames.push(field.$name);
        });
      });
      self.formErrors = "Please fill out the required fields: " + errorNames.join(", ") + ".";
    }
  };
};