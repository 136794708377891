var formErrorParser = require('../../common/utils/formErrorParser');

module.exports = /*@ngInject*/ function ($scope, $portalHead, $mdDialog, gUserUciPromises, gUciId, gAccountId, gUserId, UcpFactory, AccountFactory) {
  var self = this;
  self.gUciId = gUciId;
  self.gAccountId = gAccountId;
  self.gUserId = gUserId;
  self.busyMask = true;

  gUserUciPromises.item.then(function (uci) {
    self.gUserUci = uci;
    if (!self.gUserUci.userId) {
      self.gUserUci.userId = gUserId;
    }
    if (!self.gUserUci.accountId) {
      self.gUserUci.accountId = gAccountId;
    }

    if (['INHERIT', 'OWN'].indexOf(self.gUserUci.ucpAuthLevel) === -1) {
      self.gUserUci.ucpAuthLevel = self.gAccountId != -1 ? 'INHERIT' : 'OWN';
    }
    if (self.gAccountId != -1) {
      new AccountFactory().get({ id: self.gAccountId }, function (account) {
        self.gAccountUcis = account.ucis.map(function (uci) {
          return { id: uci.ucpId, label: uci.ucpLabel };
        });
        if (self.gUserUci.ucpAuthLevel === 'INHERIT') {
          self.ucps = self.gAccountUcis;
          if (!self.gUserUci.ucpId && self.gAccountUcis.length) {
            self.gUserUci.ucpId = self.gAccountUcis[0].id;
          }
        }
      });
    }
    self.niceUcps = {};
    new UcpFactory().query(function (ucps) {
      self.allUcps = ucps;
      if (self.gUserUci.ucpAuthLevel !== 'INHERIT' || self.gAccountId == -1) {
        self.ucps = ucps;
      }
      for (var i = 0; i < ucps.length; i++) {
        self.niceUcps[ucps[i].id] = ucps[i];
      }
      if (self.gUserUci.ucpId) {
        self.ucpChanged(self.gUserUci.ucpId);
      }
      self.busyMask = false;
    }, function(errorResponse) {
      self.formErrors = formErrorParser(errorResponse, self.uciForm, self.niceFields);
      self.busyMask = false;
    });
  }, function(errorResponse) {
    self.formErrors = formErrorParser(errorResponse, self.uciForm, self.niceFields);
    self.busyMask = false;
  });

  self.cancel = function () {
    $mdDialog.cancel('cancel');
  };

  self.ucpChanged = function (ucpId) {
    self.isVBS = self.niceUcps[ucpId] && self.niceUcps[ucpId].ucpType === 'VBS';
  };

  self.levelChanged = function () {
    if (self.gUserUci.ucpAuthLevel === 'OWN') {
      self.ucps = self.allUcps;
    } else {
      self.ucps = self.gAccountUcis;
    }
    for (var u = 0; u < self.ucps.length; u++) {
      if (self.ucps[u].id === self.gUserUci.ucpId) {
        break;
      }
    }
    if (!self.gUserUci.ucpId) {
      self.gUserUci.ucpId = self.ucps[0].id;
      self.ucpChanged(self.gUserUci.ucpId);
    }
  };

  self.niceFields = {};
  self.niceFields.ucpId = { label: 'Service Provider' };
  self.niceFields.ucpUserId = { label: 'UCP User ID' };
  self.niceFields.ucpUsername = { label: 'UCP Username' };
  self.niceFields.ucpPassword = { label: 'UCP Password' };

  self.save = function () {
    delete self.formErrors;
    if (self.uciForm.$valid) {
      // delete self.gUserUci.ucpUserId;
      delete self.gUserUci.health;
      delete self.gUserUci.ucpLabel;
      delete self.gUserUci.ucpType;
      delete self.gUserUci.type;
      if (self.gUserUci.credentials) {
        delete self.gUserUci.credentials.endpoints;
        if (!self.gUserUci.credentials.username) {
          delete self.gUserUci.credentials;
        }
      }
      self.gUserUci.$save(function (uci) {
        $mdDialog.hide(uci);
      }, function (errorResponse) {
        self.formErrors = formErrorParser(errorResponse, self.uciForm, self.niceFields);
      });
    } else {
      self.uciForm.$setDirty(true);
      var errorNames = [];
      angular.forEach(self.uciForm.$error, function (type) {
        angular.forEach(type, function (field) {
          field.$touched = true;
          field.$setDirty();
          errorNames.push(field.$name);
        });
      });
      self.formErrors = "Please fill out the required fields: " + errorNames.join(", ") + ".";
    }
  };

  self.delete = function() {
    delete self.formErrors;
    self.gUserUci.$delete(function (uci) {
      $mdDialog.hide(uci);
    }, function (errorResponse) {
      self.formErrors = formErrorParser(errorResponse, self.uciForm, self.niceFields);
    });
  }
};