require('./integrationsDlg.scss');
module.exports = /*@ngInject*/ function ($mdDialog, gAccount, logoForIntegration, IntegrationFactory) {
  var self = this;
  self.gAccount = gAccount;
  self.logoForIntegration = logoForIntegration;
  self.busyMask = true;
 
  var IntegrationModel = new IntegrationFactory({accountId: gAccount.id});

  self.cancel = function () {
    $mdDialog.cancel('cancel');
  };

  IntegrationModel.getIntegrationsList(function (integrations) {
    if (integrations && integrations.length) {
      integrations.sort(function(a , b){ //Integrations Order Filter.
        if(a.order > b.order) return 1;
        if(a.order < b.order) return -1;
        return 0;
      });
      self.integrations = integrations;  
    }
    self.busyMask = false;
  });

  self.save = function() {
    self.busyMask = true;
    for (var i=0; i<self.integrations.length; i++) {
      self.integrations[i].order = i;
    }
    self.gAccount.$setIntegrations(self.integrations, function(account) {
      $mdDialog.cancel(account);
    }, function () {
      self.busyMask = false;
    });
  };
};